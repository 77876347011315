<p-dropdown
  [name]="name || inputId"
  [styleClass]="styleClass"
  [inputId]="inputId || name"
  [filter]="filter || shouldFilter(options)"
  [options]="options"
  [dataKey]="dataKey"
  [appendTo]="appendTo"
  [showClear]="showClear"
  [group]="group"
  [formControl]="formControl"
  [placeholder]="placeholder | translate"
  [resetFilterOnHide]="resetFilterOnHide"
  [filterPlaceholder]="filterPlaceholder | translate"
  [emptyFilterMessage]="emptyFilterMessage | translate"
  (onBlur)="onBlur.emit($event)"
  (onChange)="onChange.emit($event)"
>
  <ng-template let-item pTemplate="selectedItem">
    <ng-container
      *ngTemplateOutlet="
        selectedItemTemplate || itemTranslateTemplate;
        context: { $implicit: item }
      "
    >
    </ng-container>
  </ng-template>
  <ng-template let-item pTemplate="item">
    <ng-container
      *ngTemplateOutlet="itemTemplate || itemTranslateTemplate; context: { $implicit: item }"
    >
    </ng-container>
  </ng-template>
  <ng-template let-group pTemplate="group">
    <ng-container
      *ngTemplateOutlet="groupTemplate || itemTranslateTemplate; context: { $implicit: group }"
    >
    </ng-container>
  </ng-template>
</p-dropdown>
<ng-template #itemTranslateTemplate let-item>{{ item?.label | translate }}</ng-template>
