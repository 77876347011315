import { Injectable } from '@angular/core'
import { AuthApi } from '@core/authentication/auth/api/auth.api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  fetchMCUSer,
  fetchUserProfile,
  saveUserProfile,
  saveUserProfileOK,
  setMCUser,
  setUserProfile
} from '@core/state/actions/profile.actions'
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators'
import { UserProfile } from '@core/authentication/auth/models/auth.models'
import { logOut } from '@core/state/actions/oidc.actions'
import { HttpResponse, HttpStatusCode } from '@angular/common/http'
import { throwError } from 'rxjs'
import { navigateExternal } from '@core/ngrx-router/state/actions/ngrx-router.actions'
import { environment } from '@env'
import { setSelectedTenant } from '@core/state/actions/tenant.actions'
import { mapMCUser } from '@core/authentication/auth/utils/auth.utils'
import { getRoles } from '@core/state/selectors/profile.selectors'
import { RoleType } from '@core/authentication/auth/enums/auth.enums'

@Injectable()
export class ProfileEffects {

  fetchUserProfile$ = createEffect(
    () => this._actions$.pipe(
      ofType(fetchUserProfile),
      switchMap(() => this._api.fetchProfile().pipe(
        map((res: HttpResponse<UserProfile>) => {
          if (res.status === HttpStatusCode.NoContent) {
            return logOut()
          }
          return setUserProfile({profile: res.body as UserProfile})
        }),
        catchError((err) => {
          // TODO: Should be handled by a custom error handler
          if (err.status !== HttpStatusCode.Unauthorized) {
            this._store.dispatch(navigateExternal({path: environment.IDENTITY_SERVER.POST_LOGOUT_REDIRECT_URI}))
          }
          this._store.dispatch(setUserProfile({profile: undefined}))
          return throwError(err)
        })
      )),
    )
  )

  fetchMCUser$ = createEffect(
    () => this._actions$.pipe(
      ofType(fetchMCUSer),
      switchMap(() => this._api.fetchMCUser().pipe(
        map(user => mapMCUser(user[0])))
      ),
      switchMap(user => [setMCUser({user}), setSelectedTenant({selected: user?.customer?.calculatedTenantId})])
    )
  )

  saveUserProfile$ = createEffect(
    () => this._actions$.pipe(
      ofType(saveUserProfile),
      withLatestFrom(this._store.select(getRoles)),
      switchMap(([{profile}, roles]: [any, RoleType[]]) => this._api.updateUserProfile(profile).pipe(
        map((profiles: UserProfile[]) => ({ ...profiles[0], roles }))
      )),
      switchMap((profile: UserProfile) => [
        setUserProfile({profile}),
        saveUserProfileOK()
      ])
    )
  )


  constructor(
    private readonly _actions$: Actions,
    private readonly _api: AuthApi,
    private readonly _store: Store<unknown>
  ) {
  }
}
