import { Component, Input } from '@angular/core'

import { States } from './chip.enums'

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
})
export class ChipComponent {
  @Input() text: string
  @Input() state: States
  State = States

  constructor() {
    // This is intentional
  }
}
