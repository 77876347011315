import { Directive, Input, HostListener, HostBinding } from '@angular/core'

import { environment } from '@env'

const IS_ABSOLUTE_PATH = new RegExp('^(?:[a-z]+:)?//', 'i')
const BROKEN_IMAGE = environment.STATICS_URL_ASSETS + 'assets/images/broken_image.svg'
const GetAbsolutePath = (path: string) =>
  (IS_ABSOLUTE_PATH.test(path) ? '' : environment.STATICS_URL_ASSETS) + path

@Directive({
  selector: '[appDefaultImage]',
  exportAs: 'defaultImage',
})
export class DefaultImageDirective {
  private _srcUrl!: string
  private _appDefaultImage!: string

  isBrokenImg = false

  get srcUrl(): string {
    return this._srcUrl
  }
  @Input() set srcUrl(_srcUrl: string) {
    this._srcUrl = _srcUrl
    this._checkImage()
  }

  get appDefaultImage(): string {
    return this._appDefaultImage
  }
  @Input() set appDefaultImage(_appDefaultImage: string) {
    this._appDefaultImage = _appDefaultImage
    this._checkImage()
  }

  @HostBinding('src') _src!: string

  constructor() {
    // This is intentional
  }

  private _checkImage() {
    if (this.srcUrl || this.appDefaultImage) {
      this._src = this.srcUrl ? this.srcUrl : GetAbsolutePath(this.appDefaultImage)
    }
  }

  @HostListener('error')
  private _setDefaultImage() {
    this.isBrokenImg = true
    this._src = BROKEN_IMAGE
  }
}
