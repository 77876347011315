import { sortBy } from '@core/utils/main'

import { ApplicationType } from './components/categories/categories.enums'
import {
  AppPermissionType,
  PermissionType,
  RoutePermissionKey,
} from './components/categories/category-form/category-form.enums'

export const parseData = (data, checked?, filter?) =>
  (data || []).filter(filter).reduce((obj, { id, key, name, allCheck, permissions }) => {
    const isCheckAll = !(key || name) || allCheck
    return {
      ...obj,
      [id]: {
        checked: !isCheckAll ? checked || false : checked,
        isCheckAll,
        ...(permissions && permissions.length > 0
          ? { children: parseData(permissions, checked, filter) }
          : {}),
      },
    }
  }, {})

export const parsePermissionsToCheckboxGroup = (_permissions, checked?) =>
  _permissions.map(({ label, key, name, id, longId, permissions, allCheck }) => {
    const isCheckAll = !(key || name) || allCheck
    return {
      id,
      label,
      ...(name ? { name } : {}),
      ...(longId ? { longId } : {}),
      isCheckAll,
      checked: isCheckAll ? undefined : checked,
      children: permissions && parsePermissionsToCheckboxGroup(permissions, checked),
    }
  })

export const parseAllPermissionsData = (allPermissions, checked?, filter?) =>
  Object.entries(allPermissions).reduce(
    (obj, [key, permissions]) => ({
      ...obj,
      [key]: parseData(permissions, checked, filter),
    }),
    {},
  )

export const parseAllPermissionsToCheckboxGroup = (allPermissions, checked?) =>
  Object.entries(allPermissions).reduce(
    (obj, [key, permissions]) => ({
      ...obj,
      [key]: parsePermissionsToCheckboxGroup(permissions, checked),
    }),
    {},
  )

export const sortPermissions = (_permissions) =>
  sortBy(
    _permissions.map((permission) => ({
      ...permission,
      permissions: permission.permissions && sortPermissions(permission.permissions),
    })),
  )

export const sortAllPermissions = (allPermissions, checked?) =>
  Object.entries(allPermissions).reduce(
    (obj, [key, permissions]) => ({
      ...obj,
      [key]: sortPermissions(permissions),
    }),
    {},
  )

const contentPermissionByParentRoute = (parentRouteKey: string) =>
  ({
    competition: {
      id: 'competition',
      label: 'MTR_COMMON_COMPETITION',
      key: 'competition',
      allCheck: true,
      permissions: [],
    },
    team: {
      id: 'team',
      label: 'MTR_COMMON_TEAMS',
      key: 'team',
      allCheck: true,
      permissions: [],
    },
    player: {
      id: 'player',
      label: 'MTR_COMMON_PLAYERS',
      key: 'player',
      allCheck: true,
      permissions: [],
    },
  })[parentRouteKey]

export const groupPortalRoutes = (permissions) => {
  const routePermissions = permissions[PermissionType.ApplicationPermissions]
    .find(({ id }) => id === ApplicationType.Portal)
    .permissions.find(({ id }) => id === AppPermissionType.Route) || { permissions: [] }
  const contentPermissions = permissions[PermissionType.ApplicationPermissions]
    .find(({ id }) => id === ApplicationType.Portal)
    .permissions.find(({ id }) => id === AppPermissionType.AppContent) || { permissions: [] }
  routePermissions.permissions = routePermissions.permissions.reduce((arr, route) => {
    let parentRoute
    if (route.key === RoutePermissionKey.TeamDetail) {
      parentRoute = routePermissions.permissions.find(
        ({ key }) => key === RoutePermissionKey.TeamList,
      )
    } else if (route.key === RoutePermissionKey.PlayerDetail) {
      parentRoute = routePermissions.permissions.find(
        ({ key }) => key === RoutePermissionKey.PlayerList,
      )
    } else {
      parentRoute =
        route.key.split('/').length > 1
          ? routePermissions.permissions.find(
              ({ key, id }) => route.id !== id && route.key.indexOf(key + '/') === 0,
            )
          : undefined
    }
    if (parentRoute) {
      parentRoute.permissions = [...(parentRoute.permissions || []), route]
    }
    return parentRoute ? arr : [...arr, route]
  }, [])
  contentPermissions.permissions = contentPermissions.permissions.reduce(
    (arr: any[], route: any) => {
      const parentRoute =
        route.key.split('/').length > 1
          ? routePermissions.permissions.find(
              ({ key, id }) => route.id !== id && route.key.indexOf(key + '/') === 0,
            ) ||
            arr.find(({ key }) => key === route.key.split('/')[0]) ||
            contentPermissionByParentRoute(route.key.split('/')[0])
          : undefined
      if (parentRoute) {
        parentRoute.permissions = [...(parentRoute.permissions || []), route]
        return [...Array.from(new Set([...arr, parentRoute]))]
      }
      return [...arr, route]
    },
    [],
  )
}
