import { Injectable } from '@angular/core'
import { HttpClient, HttpContext } from '@angular/common/http'
import { map, share, tap } from 'rxjs/operators'
import { BehaviorSubject, Observable, of } from 'rxjs'

import { environment } from '@env'
import { LazyParams } from '@shared/components/table/table.models'
import { SortingService } from '@core/services/sorting/sorting.service'
import { FilterOption } from '@shared/components/filters/filters.models'

import { UserBO } from './usersBO.models'
import { RoleTypeKey } from '@core/authentication/auth/constants/auth.constants'
import { logError } from '@shared/opretators/log-error.operator'
import { isBase64 } from '@core/services/file/file.utils'

@Injectable({
  providedIn: 'root',
})
export class UsersBOService {
  roleOptionsSubject$ = new BehaviorSubject<FilterOption[]>(null)
  roleOptions$: Observable<FilterOption[]>

  constructor(
    private http: HttpClient,
    private sortingService: SortingService,
  ) {}

  getUsers$(params = {}, lazyParams: LazyParams = <LazyParams>{}) {
    const paramsBackEnd = {
      filtering: {
        freeText: '',
        filterQuality: 'raw',
        ...params,
      },
      ...lazyParams,
    }
    return this.http.post<any>(environment.API.SEARCH_USERS_BO, paramsBackEnd).pipe(
      map((data) => ({
        ...data,
        sorting: {
          ...data.sorting,
          ...lazyParams.sorting,
        },
        users: this.sortingService.sort(
          data.users.map((user) => ({
            ...user,
            customerName: user.customer.name,
            ...user.roles.reduce((obj, { name }) => ({ ...obj, [name]: true }), {}),
          })),
        ),
      })),
      share(),
      logError(),
    )
  }

  getUsersById(ids: string[], requestContext?: HttpContext) {
    return this.http
      .get<any>(environment.API.USERS_BO, {
        params: { ids },
        context: requestContext,
      })
      .pipe(
        map((data) =>
          data.map((users) => ({
            ...users,
            roleIds: users.roles.map(({ id }) => id),
            customerId: users.customer.id,
            photoPath: users.userAvatar && users.userAvatar.avatarPath,
          })),
        ),
        share(),
        logError(),
      )
  }

  getUserById(id: string, requestContext?: HttpContext) {
    return this.getUsersById([id], requestContext).pipe(map((res) => res[0]))
  }

  updateUser$(user: UserBO) {
    user = {
      ...user,
      userAvatar: {
        ...user.userAvatar,
        encodedImage: isBase64(user.photoPath) ? user.photoPath : null,
      },
      photoPath: undefined,
    }
    return this.http.put<any>(environment.API.USERS_BO, [user])
  }

  getRoles$() {
    return this.http.get<any>(environment.API.USER_BO_ROLES).pipe(share(), logError())
  }

  getRoleOptions$(forceFetch?: boolean) {
    if (!forceFetch && this.roleOptionsSubject$.value) {
      return of(this.roleOptionsSubject$.value)
    } else {
      return !forceFetch && this.roleOptions$
        ? this.roleOptions$
        : (this.roleOptions$ = this.getRoles$().pipe(
            map((data) => data.map(({ id, name }) => ({ label: RoleTypeKey[name], value: id }))),
            tap((data: FilterOption[]) => this.roleOptionsSubject$.next(data)),
          ))
    }
  }
}
