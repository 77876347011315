import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core'
import { DOCUMENT } from '@angular/common'

import { of } from 'rxjs'
import { take } from 'rxjs/operators'

import { OverlaySettings } from './overlay.models'
import { OverlayType } from './overlay.enums'
import { OVERLAY_SETTINGS } from '@shared/components/overlay/overlay.constants'

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
})
export class OverlayComponent implements OnDestroy {
  private _shouldCloseAfterConfirm = false

  OverlayType = OverlayType
  OverlaySettings = OVERLAY_SETTINGS
  isOpen = false

  @Input() title = ''
  @Input() subtitle = ''
  @Input() loading = false
  @Input() extraClasses = ''
  @Input() confirmMessage: string
  @Input() type = OverlayType.SidePanel
  @Input() closeButtonQaId: string
  @Output() onClose = new EventEmitter()
  @Output() onConfirmClose = new EventEmitter<boolean>()

  @ViewChild(OverlayComponent) confirmModal: OverlayComponent

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
  ) {}

  private showOverlay(show = true) {
    if ((OVERLAY_SETTINGS[this.type] || <OverlaySettings>{}).bodyClass) {
      this.renderer[`${show ? 'add' : 'remove'}Class`](
        this.document.body,
        OVERLAY_SETTINGS[this.type].bodyClass,
      )
    }
    this.isOpen = show
  }

  private _close(keepOverflowHidden = false) {
    this.showOverlay(keepOverflowHidden)
    this.onClose.emit()
    this.onConfirmClose.emit(true)
    this.confirmMessage = null
  }

  open() {
    if (!this.confirmMessage) {
      this.showOverlay()
    } else {
      this.confirmModal.showOverlay()
    }
  }

  close(keepOverflowHidden?: boolean) {
    if (!this.confirmMessage) {
      this._close(keepOverflowHidden)
      if (keepOverflowHidden === true) {
        this.isOpen = !keepOverflowHidden
      }
    } else {
      this.confirmModal.showOverlay()
      this._shouldCloseAfterConfirm = true
    }
  }

  toggle() {
    if (this.isOpen) {
      this.close()
    } else {
      this.open()
    }
  }

  discardChanges() {
    if (this._shouldCloseAfterConfirm) {
      this._close()
    }
    this.onConfirmClose.emit(true)
    this.confirmModal.close()
  }

  cancelClose() {
    this.onConfirmClose.emit(false)
    this.confirmModal.close()
  }

  confirmClose$(shouldCloseOnConfirm = false) {
    if (this.confirmMessage) {
      this.confirmModal.showOverlay()
      this._shouldCloseAfterConfirm = shouldCloseOnConfirm
    }
    return this.confirmMessage ? this.onConfirmClose.pipe(take(1)) : of(true)
  }

  ngOnDestroy(): void {
    this.showOverlay(false)
  }
}
