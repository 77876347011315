import { UserManagerSettings } from 'oidc-client-ts'
import { environment } from '@env'
import { InjectionToken } from '@angular/core'

export const FACTORY_TOKEN_OIDC = new InjectionToken<string>('Oidc')
export const DEBUG_TOKEN_OIDC = new InjectionToken<boolean>('OidcDebug')

export const USER_MANAGER_SETTINGS: UserManagerSettings = {
  authority: environment.IDENTITY_SERVER.AUTHORITY_URL,
  client_id: environment.IDENTITY_SERVER.CLIENT_ID,
  redirect_uri: environment.IDENTITY_SERVER.REDIRECT_URI,
  post_logout_redirect_uri: environment.IDENTITY_SERVER.POST_LOGOUT_REDIRECT_URI,
  response_type: environment.IDENTITY_SERVER.RESPONSE_TYPE,
  scope: environment.IDENTITY_SERVER.SCOPE,
  silent_redirect_uri: environment.IDENTITY_SERVER.SILENT_REDIRECT_URI,
  automaticSilentRenew: true,
  loadUserInfo: environment.IDENTITY_SERVER.LOAD_USER_INFO,
}
