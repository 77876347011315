export enum AssetStatus {
  Unavailable = 'Unavailable',
  Available = 'Available',
  InProgress = 'InProgress',
  WaitingForMetrics = 'WaitingForMetrics',
  ReadyToBeGenerated = 'ReadyToBeGenerated',
  Canceled = 'Canceled',
  Created = 'Created',
  Failed = 'Failed',
  EncryptedFailed = 'EncryptedFailed',
  AddedToS3 = 'AddedToS3',
}

export enum AssetType {
  Video = 'Video',
  Report = 'Report',
  Eventing = 'Eventing',
  Tracking = 'Tracking',
  Metadata = 'Metadata',
  PrivateReport = 'PrivateReport',
  Calibration = 'Calibration',
}

export enum ContentType {
  ReportMedia = 'ReportMedia',
}

export enum PrivateAssetLanguage {
  English = 'en-GB',
  Spanish = 'es-ES',
}

export enum PrivateAssetType {
  Home = 'PrivateReportMediaH',
  Away = 'PrivateReportMediaA',
  HomeAdvance = 'PrivateReportAdvanceMediaH',
  AwayAdvance = 'PrivateReportAdvanceMediaA',
  MediacoachReview = 'PrivateReportMediacoachReview',
  AdvanceMediacoachReview = 'PrivateReportAdvanceMediacoachReview',
}
