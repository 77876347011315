import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnInit,
  TemplateRef,
} from '@angular/core'

import { filter, tap } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

import { AutoComplete } from 'primeng/autocomplete'
import { UniqueComponentId } from 'primeng/utils'

const AUTOCOMPLETE_LAST_QUERY_PREFIX_LS = 'mc-autocomplete__last-query--'

@UntilDestroy()
@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'p-element p-inputwrapper',
    '[class.p-inputwrapper-filled]': 'filled',
    '[class.p-inputwrapper-focus]': '((focused && !disabled) || autofocus) || overlayVisible',
    '[class.p-autocomplete-clearable]': 'showClear && !disabled',
  },
})
export class AutocompleteComponent
  extends AutoComplete
  implements OnInit, AfterContentInit, ControlValueAccessor
{
  override listId = UniqueComponentId() + 'list'
  override groupTemplate: TemplateRef<any>

  @Input() lastQueryGroup: any
  @Input() override emptyMessage = 'MTR_COMMON_NO_RESULTS'
  @Input() override dataKey = 'id'
  @Input() override minLength = 2
  @Input() override autoHighlight = true
  @Input() override forceSelection = true
  @Input() override completeOnFocus = true
  @Input() override get suggestions(): any[] {
    return this._suggestions()
  }
  override set suggestions(value: any[]) {
    if (value !== undefined && value !== null) {
      this._suggestions.set(value)
      this.handleSuggestionsChange()
    }
  }

  private _getLastQueryLocalStorageKey() {
    return this.lastQueryGroup && AUTOCOMPLETE_LAST_QUERY_PREFIX_LS + this.lastQueryGroup
  }

  private get _lastQueryLocalStorageKey() {
    const lastQueryLocalStorageKey = this._getLastQueryLocalStorageKey()
    return localStorage.getItem(lastQueryLocalStorageKey)
  }

  private set _lastQueryLocalStorageKey(value) {
    const lastQueryLocalStorageKey = this._getLastQueryLocalStorageKey()
    if (lastQueryLocalStorageKey) {
      localStorage.setItem(lastQueryLocalStorageKey, value)
    }
  }

  private _searchEnable(query: any) {
    return query.length >= this.minLength
  }

  override ngOnInit() {
    this.completeMethod
      .pipe(
        filter(() => this.completeOnFocus),
        tap(({ query }) => {
          this._lastQueryLocalStorageKey = query
        }),
        untilDestroyed(this),
      )
      .subscribe()

    super.ngOnInit()
  }

  cleanLastQueryLocalStorageKey() {
    const lastQueryLocalStorageKey = this._getLastQueryLocalStorageKey()
    if (lastQueryLocalStorageKey) {
      localStorage.removeItem(lastQueryLocalStorageKey)
    }
  }

  override onInputFocus(event) {
    if (this.disabled) {
      // For ScreenReaders
      return
    }

    if (!this.dirty && this.completeOnFocus) {
      const queryValue = this.multiple
        ? this.multiInputEl.nativeElement.value
        : this.inputEL.nativeElement.value
      const lastQueryValue = this._lastQueryLocalStorageKey
      this.multiple
        ? (this.multiInputEl.nativeElement.value = lastQueryValue)
        : (this.inputEL.nativeElement.value = lastQueryValue)
      this.search(
        event,
        this._searchEnable(queryValue) ? queryValue : lastQueryValue || '',
        'focus',
      )
    }
    this.dirty = true
    this.focused = true
    const focusedOptionIndex =
      this.focusedOptionIndex() !== -1
        ? this.focusedOptionIndex()
        : this.overlayVisible && this.autoOptionFocus
          ? this.findFirstFocusedOptionIndex()
          : -1
    this.focusedOptionIndex.set(focusedOptionIndex)
    this.overlayVisible && this.scrollInView(this.focusedOptionIndex())
    this.onFocus.emit(event)
  }
}
