import { NgModule } from '@angular/core'
import { DEBUG_TOKEN_OIDC, FACTORY_TOKEN_OIDC } from './constants/oidc.constants'
import { OidcFactory } from './factories/oidc.factory'
import { OidcService } from './services/oidc.service'
import { environment } from '@env'

@NgModule({
  providers: [
    OidcService,
    { provide: FACTORY_TOKEN_OIDC, useFactory: OidcFactory },
    { provide: DEBUG_TOKEN_OIDC, useValue: !environment.production },
  ],
})
export class OidcModule {}
