import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http'
import { Observable } from 'rxjs'
import { MCUser, UserProfile } from '../models/auth.models'
import { environment } from '@env'
import { ignoreTenant } from '@core/requests/interceptors/tenant.interceptor'

@Injectable()
export class AuthApi {
  constructor(private readonly _http: HttpClient) {}

  fetchMCUser(): Observable<MCUser> {
    return this._http.get<MCUser>(environment.API.USERS, { context: ignoreTenant() })
  }

  fetchProfile(): Observable<HttpResponse<UserProfile>> {
    return this._http.get<UserProfile>(environment.API.USER_PROFILE, {
      observe: 'response',
      context: ignoreTenant(),
    })
  }

  updateUserProfile(userProfile: UserProfile): Observable<UserProfile[]> {
    return this._http.put<UserProfile[]>(`${environment.API.USER_PROFILE}s`, [userProfile], {
      headers: new HttpHeaders(''),
    })
  }
}
