import { NgModule } from '@angular/core'
import { HttpClient, HttpClientModule } from '@angular/common/http'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'

import { AppComponent } from './app.component'
import { CoreModule } from '@core/core.module'
import { SharedModule } from '@shared/shared.module'
import { OverlayModule } from '@angular/cdk/overlay'
import { HttpLoaderFactory } from '@core/i18n/factories/http-loader.factory'
import { AppRoutingModule } from './app-routing.module'
import { Locale } from '@mediacoach-ui-library/global'
import 'moment/locale/es'
import 'moment/locale/en-gb'

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    OverlayModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
      defaultLanguage: Locale.Es,
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
