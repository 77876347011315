import { Injectable } from '@angular/core'
import { Paging, RecordsRange } from './paging.models'

@Injectable({
  providedIn: 'root',
})
export class PagingService {
  constructor() {
    // This is intentional
  }

  getParams(items = 10, start = 0): Paging {
    return {
      recordsRange: { start, end: start + items },
    }
  }

  getTotalRecords(paging: Paging) {
    return (paging || <Paging>{}).totalRecords || 0
  }

  getRange(paging: Paging) {
    const { start, end } = (paging || <Paging>{}).recordsRange || <RecordsRange>{}
    return end != null && start != null ? end - start : 0
  }

  getRangeStart(paging: Paging) {
    const { start } = (paging || <Paging>{}).recordsRange || <RecordsRange>{}
    return start || 0
  }

  resetParams(paging: Paging) {
    return this.getParams(this.getRange(paging))
  }
}
