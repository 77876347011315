<div
  *ngIf="OverlaySettings[type]?.backdrop && isOpen"
  class="bo-overlay__backdrop"
  (click)="close()"
></div>
<div
  class="bo-overlay bo-overlay--{{ type }} {{ extraClasses }}"
  [class.bo-overlay--show]="isOpen"
  [class.bo-overlay--loading]="loading"
>
  <div class="bo-overlay__header">
    <i
      class="bo-overlay__button pi pi-times"
      (click)="close()"
      [attr.data-qa-id]="closeButtonQaId"
      aria-hidden="true"
    ></i>
    <app-heading
      extraClasses="m-heading--lg u-margin-bottom--sm"
      [titleText]="title"
      [subtitleText]="subtitle"
    ></app-heading>
  </div>
  <ng-content select="[bo-template=out-body-top]"></ng-content>
  <div class="bo-overlay__body">
    <ng-content select="[bo-template=body]"></ng-content>
    <div class="bo-overlay__spinner">
      <i class="bo-overlay__spinner-icon pi-spin pi pi-spinner" aria-hidden="true"></i>
    </div>
  </div>
  <div class="bo-overlay__footer">
    <ng-content select="[bo-template=footer]"></ng-content>
  </div>
</div>

<app-overlay
  *ngIf="confirmMessage"
  [type]="OverlayType.Modal"
  [title]="'MTR_COMMON_IMPORTANT_NOTICE'"
  [closeButtonQaId]="'bo-overlay-confirm__button--close'"
>
  <div class="bo-overlay__confirm-modal" bo-template="body">
    <p class="bo-overlay__confirm-modal-text">
      {{ confirmMessage | translate }}
    </p>
  </div>
  <div bo-template="footer">
    <div class="bo-button-group bo-button-group--separated">
      <app-button
        [text]="'MTR_COMMON_CANCEL'"
        class="bo-button--tertiary"
        extraClasses="bo-button--tertiary"
        (onClick)="cancelClose()"
        [qaId]="'bo-overlay-confirm__button--cancel'"
      ></app-button>
      <app-button
        [text]="'MTR_COMMON_ACCEPT'"
        (onClick)="discardChanges()"
        [qaId]="'bo-overlay-confirm__button--accept'"
      ></app-button>
    </div>
  </div>
</app-overlay>
