import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { Tenant } from '../../models/tenant.models'
import { environment } from '@env'

@Injectable({
  providedIn: 'root',
})
export class TenantApi {
  constructor(private readonly _http: HttpClient) {}

  fetchTenants(): Observable<Tenant[]> {
    return this._http.get<Tenant[]>(environment.API.TENANTS)
  }
}
