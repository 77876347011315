import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'

export const logError =
  <T>() =>
  (source: Observable<T>): Observable<T> =>
    source.pipe(
      catchError((err) => {
        console.error(err)
        return throwError(() => err)
      }),
    )
