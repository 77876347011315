import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

import { BehaviorSubject } from 'rxjs'
import { FileUpload } from 'primeng/fileupload'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

import { TranslatorService } from '@mediacoach-ui-library/global'
import { blobTo, bytesToSize } from './file-upload.utils'
import { FileUploadType } from './file-upload.enums'
import { ToastType } from '@core/components/toast/toast.models'
import { ToastService } from '@core/components/toast/toast.service'
import { WindowEventsService } from '@core/services/window-events/window-events.service'

@UntilDestroy()
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    },
  ],
})
export class FileUploadComponent implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor {
  private onChange: Function
  private onTouch: Function

  @ViewChild('fileUpload', { static: true }) public fileUpload: FileUpload

  @Input() accept = 'image/jpeg'
  @Input() acceptTypes = ['jpeg']
  @Input() maxFileSize = 2000 * 1024
  @Input() base64Value: string
  @Input() blobToType: 'readAsDataURL' | 'readAsText' = 'readAsDataURL'
  @Input() type: FileUploadType = FileUploadType.Avatar
  @Input() loading = false

  @Output() onFilesSelected = new EventEmitter()

  disabled = false
  isDragging: BehaviorSubject<boolean>
  invalidFileSizeMessageDetail
  invalidFileTypeMessageDetail
  fileUploadType = FileUploadType

  constructor(
    private translatorService: TranslatorService,
    private toasterService: ToastService,
    private windowEventsService: WindowEventsService,
  ) {
    this.isDragging = windowEventsService.getDraggingEvent()
  }

  ngOnInit() {
    this.translatorService
      .stream(['MTR_COMMON_ERROR_UPLOAD_SIZE', 'MTR_COMMON_ERROR_UPLOAD_TYPE'], {
        types: this.acceptTypes.join(','),
        size: bytesToSize(this.maxFileSize),
      })
      .pipe(untilDestroyed(this))
      .subscribe((translateObj) => {
        this.invalidFileSizeMessageDetail = translateObj.MTR_COMMON_ERROR_UPLOAD_SIZE
        this.invalidFileTypeMessageDetail = translateObj.MTR_COMMON_ERROR_UPLOAD_TYPE
      })
  }

  ngAfterViewInit() {
    this.fileUpload
      .getBlockableElement()
      .querySelector('input[type="file"]')
      .setAttribute('title', '')
  }

  onPreselect() {
    this.fileUpload.msgs
      .map(({ detail }) => detail)
      .forEach((message) => {
        this.toasterService.showToast({
          type: ToastType.Error,
          message: message,
        })
      })
  }

  onSelectFn(e) {
    blobTo(
      e.files[0],
      (fileBase64) => {
        this.base64Value = fileBase64
        this.onChange(fileBase64)
      },
      this.blobToType,
    )
  }

  ngOnDestroy() {
    // This is intentional
  }

  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  writeValue(value: any): void {
    this.base64Value = value
  }
}
