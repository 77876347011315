<form
  class="bo-checkbox-group"
  [class.bo-checkbox-group--inline]="depth > 1"
  [ngClass]="extraClasses"
>
  <ng-container
    *ngTemplateOutlet="inputContainer; context: { formGroup: mainFormGroup, inputInfo: configObj }"
  ></ng-container>
  <ng-template #inputContainer let-formGroup="formGroup" let-inputInfo="inputInfo">
    <ng-container *ngFor="let fgKeyValue of Object.entries(formGroup.controls); let index = index">
      <ng-container *ngIf="(fgKeyValue || {})[0] as key">
        <div
          *ngIf="(fgKeyValue || {})[1] as fg"
          class="bo-checkbox-group__container"
          [formGroup]="fg"
        >
          <app-checkbox
            formControlName="checked"
            [binary]="'true'"
            [label]="inputInfo[key].label"
            [qaId]="'bo-component__checkbox--' + inputInfo[key].label"
            [enableIndeterminate]="fg.controls.isCheckAll.value"
            (onChange)="onInputChange(fg, $event)"
          ></app-checkbox>
          <ng-container *ngIf="fg.controls.children as children">
            <ng-container
              *ngTemplateOutlet="
                inputContainer;
                context: { formGroup: children, inputInfo: inputInfo[key]?.children }
              "
            ></ng-container>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-template>
</form>

<ng-template [ngIf]="formChanges$ | async"></ng-template>
