import { Component } from '@angular/core'

import { environment } from '@env'

import { NAV_ITEMS } from './side-nav.model'
import { UntilDestroy } from '@ngneat/until-destroy'
import { Store } from '@ngrx/store'
import { getPermissions } from '@core/state/selectors/profile.selectors'
import { ProfileState } from '@core/state/models/profile.state'

@UntilDestroy()
@Component({
  selector: 'app-sidenav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent {
  readonly navItems = NAV_ITEMS
  readonly isProduction = environment.production
  readonly permissions$ = this._store.select(getPermissions)

  constructor(private readonly _store: Store<ProfileState>) {}
}
