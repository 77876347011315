<div
  class="p-checkbox p-component"
  [ngClass]="{ 'p-checkbox-focused': focused, 'p-checkbox-disabled': disabled }"
  (click)="onClick($event)"
>
  <div class="p-hidden-accessible">
    <input
      type="checkbox"
      [attr.id]="inputId"
      [attr.name]="name"
      [checked]="checked"
      (focus)="onFocus()"
      (blur)="onBlur()"
      [disabled]="disabled"
      [attr.required]="required"
      [attr.aria-label]="ariaLabel"
      [tabindex]="disabled ? null : '0'"
    />
  </div>
  <div
    #box
    [ngClass]="{
      'p-checkbox-box p-component': true,
      'p-highlight': checked,
      'p-focus': focused,
      'p-disabled': disabled
    }"
  >
    <ng-container *ngIf="!dt.checkboxIconTemplate">
      <CheckIcon [styleClass]="'p-checkbox-icon'" *ngIf="checked" />
    </ng-container>
    <span *ngIf="dt.checkboxIconTemplate">
      <ng-template
        *ngTemplateOutlet="dt.checkboxIconTemplate; context: { $implicit: checked }"
      ></ng-template>
    </span>
  </div>
</div>
