import { Injectable } from '@angular/core'
import { HttpClient, HttpContext } from '@angular/common/http'

import { Observable, Subject } from 'rxjs'
import { map, share, shareReplay, tap } from 'rxjs/operators'

import { environment } from '@env'
import { FormMode } from '@core/enums/form-mode.enums'
import { clearFn, setClearFn } from '@core/utils/rxjs.utils'
import { LazyParams } from '@shared/components/table/table.models'
import { SortingService } from '@core/services/sorting/sorting.service'

import { groupPortalRoutes, sortAllPermissions } from './permission-management.constants'
import { logError } from '@shared/opretators/log-error.operator'

const PERMISSION_CONFIG_PARAM_HASH = 'default'

@Injectable({
  providedIn: 'root',
})
export class PermissionManagementService {
  newCategory$ = new Subject<void>()
  newOrEditVisionEntity$ = new Subject()
  permissionConfig$ = <{ [key: string]: Observable<any> }>{}

  constructor(
    private http: HttpClient,
    private sortingService: SortingService,
  ) {}

  private _getCategoriesWrapper$(params = {}, lazyParams: LazyParams = <LazyParams>{}) {
    const paramsBackEnd = {
      filtering: {
        freeText: '',
        filterQuality: 'raw',
        ...params,
      },
      // ...lazyParams  // FIXME UNCOMMENT WHEN PAGING IS SOLVED IN BACKEND
    }

    return this.http.post<any>(environment.API.SEARCH_CATEGORIES, paramsBackEnd).pipe(logError())
  }

  getPermissionConfig$(clearFnt?) {
    setClearFn(this.permissionConfig$, PERMISSION_CONFIG_PARAM_HASH, clearFnt)
    return (
      this.permissionConfig$[PERMISSION_CONFIG_PARAM_HASH] ||
      (this.permissionConfig$[PERMISSION_CONFIG_PARAM_HASH] = this.http
        .get(environment.API.PERMISSION_CONFIG)
        .pipe(map(sortAllPermissions), tap(groupPortalRoutes), shareReplay(1)))
    )
  }

  getCategoryById$(id, requestContext?: HttpContext) {
    return this.http.get<any>(environment.API.CATEGORY_BY_ID(id), { context: requestContext }).pipe(
      tap(({ categoryPermissions }) => groupPortalRoutes(categoryPermissions)),
      logError(),
    )
  }

  getCategories$(params = {}, lazyParams: LazyParams = <LazyParams>{}) {
    return this._getCategoriesWrapper$(params, lazyParams).pipe(map(({ categories }) => categories))
  }

  getGroupedCategories$(params = {}, lazyParams: LazyParams = <LazyParams>{}) {
    return this._getCategoriesWrapper$({ ...params, grouping: true }, lazyParams).pipe(
      map(({ groupedCategories }) => groupedCategories),
    )
  }

  getCategoryOptions$() {
    return this.getCategories$().pipe(
      map((categories) => (categories || []).map(({ id, name }) => ({ label: name, value: id }))),
    )
  }

  getCategoryGroupOptions$() {
    return this.getGroupedCategories$().pipe(
      map((groupedCategories) =>
        (groupedCategories || []).map(({ group, categories }) => ({
          label: group,
          value: group,
          items: (categories || []).map((category) => ({
            label: category.name,
            value: category.id,
          })),
        })),
      ),
    )
  }

  getCategoryUsers$(params = {}, lazyParams: LazyParams = <LazyParams>{}) {
    const paramsBackEnd = {
      filtering: {
        freeText: '',
        filterQuality: 'withCustomers',
        ...params,
      },
      ...lazyParams,
    }
    return this.http.post<any>(environment.API.SEARCH_USERS, paramsBackEnd).pipe(
      map((data) => ({
        ...data,
        sorting: {
          ...data.sorting,
          ...lazyParams.sorting,
        },
        users: this.sortingService.sort(
          data.users.map((user) => ({ ...user, customer: user.customer.name })),
        ),
      })),
      share(),
      logError(),
    )
  }

  saveCategory$(category) {
    return this.http[category.id ? 'put' : 'post'](environment.API.SAVE_CATEGORY, category)
  }

  deleteCategory$(id, categoryToReplaceId?) {
    return this.http.delete(environment.API.CATEGORY_BY_ID(id), {
      ...(categoryToReplaceId ? { params: { categoryToReplaceId } } : {}),
    })
  }

  saveVisionEntity$(visionEntity, type = FormMode.Edit) {
    const isEdit = [FormMode.Edit].includes(type)
    const { id, name } = visionEntity
    const visionId = isEdit ? `/${id}` : ''
    return this.http[isEdit ? 'put' : 'post'](`${environment.API.CRUD_VISION}${visionId}`, {
      name,
    }).pipe(tap(() => clearFn(this.permissionConfig$, PERMISSION_CONFIG_PARAM_HASH)))
  }
}
