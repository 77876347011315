import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { Observable } from 'rxjs'

import { environment } from '@env'
import { ignoreTenant } from '@core/requests/interceptors/tenant.interceptor'

@Injectable()
export class I18nApi {
  constructor(private readonly _http: HttpClient) {}

  fetchTranslations(language: string): Observable<Record<string, string>> {
    return this._http.get<Record<string, string>>(environment.API.TRANSLATIONS(language), {
      context: ignoreTenant(),
    })
  }
}
