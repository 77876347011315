import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { TreeNode } from 'primeng/api'
import { ContextMenu } from 'primeng/contextmenu'

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
})
export class TreeComponent implements OnInit {
  @Input() value!: TreeNode[]
  @Input() selectionMode!: 'single' | 'multiple' | 'checkbox'
  @Input() selection!: TreeNode | TreeNode[]
  @Input() style!: Partial<CSSStyleDeclaration>
  @Input() styleClass!: string
  @Input() contextMenu!: ContextMenu
  @Input() layout!: 'vertical' | 'horizontal'
  @Input() draggableScope: any
  @Input() droppableScope: any
  @Input() draggableNodes!: boolean
  @Input() droppableNodes!: boolean
  @Input() metaKeySelection!: boolean
  @Input() propagateSelectionUp!: boolean
  @Input() propagateSelectionDown!: boolean
  @Input() loading!: boolean
  @Input() loadingIcon!: string
  @Input() emptyMessage!: string
  @Input() ariaLabel!: string
  @Input() ariaLabelledBy!: string
  @Input() validateDrop!: boolean
  @Input() filter!: boolean
  @Input() filterBy!: string
  @Input() filterMode!: 'lenient' | 'strict'
  @Input() filterPlaceholder!: string
  @Input() expandedOnStartup!: boolean

  @Output() selectionChange: EventEmitter<TreeNode | TreeNode[]> = new EventEmitter<
    TreeNode | TreeNode[]
  >()
  @Output() nodeSelect: EventEmitter<TreeNode> = new EventEmitter<TreeNode>()
  @Output() nodeUnselect: EventEmitter<TreeNode> = new EventEmitter<TreeNode>()
  @Output() nodeExpand: EventEmitter<TreeNode> = new EventEmitter<TreeNode>()
  @Output() nodeCollapse: EventEmitter<TreeNode> = new EventEmitter<TreeNode>()
  @Output() nodeContextMenuSelect: EventEmitter<TreeNode> = new EventEmitter<TreeNode>()
  @Output() nodeDrop: EventEmitter<TreeNode> = new EventEmitter<TreeNode>()

  private _expandRecursive(node: TreeNode, isExpand: boolean): void {
    node.expanded = isExpand
    if (node.children) {
      node.children.forEach((childNode) => {
        this._expandRecursive(childNode, isExpand)
      })
    }
  }

  ngOnInit(): void {
    if (this.expandedOnStartup) {
      this.expandAll()
    }
  }

  onSelectionChange(selection: TreeNode | TreeNode[]): void {
    this.selection = selection
    this.selectionChange.emit(selection)
  }

  collapseAll(): void {
    ;(this.value || []).forEach((node) => this._expandRecursive(node, false))
  }

  expandAll(): void {
    ;(this.value || []).forEach((node) => this._expandRecursive(node, true))
  }
}
