import { Injectable } from '@angular/core'
import { Sorting, SortingDirection } from './sorting.models'

@Injectable({
  providedIn: 'root',
})
export class SortingService {
  constructor() {
    // This is intentional
  }

  getParams(field, direction: SortingDirection): Sorting {
    return {
      column: field,
      direction,
    }
  }

  getParamsByOrder(field, direction: number): Sorting {
    return this.getParams(field, direction > 0 ? SortingDirection.Asc : SortingDirection.Desc)
  }

  getSortingColumns(sorting: Sorting) {
    return (sorting || <Sorting>{}).columns
  }

  getSortedColumnByOrder(sorting: Sorting) {
    let result = {
      column: '',
      direction: 0,
    }
    if (sorting.column && sorting.direction) {
      result = {
        ...sorting,
        direction: sorting.direction === SortingDirection.Desc ? -1 : 1,
      }
    }
    return result
  }

  sort(dataArray, sortingProp = 'order') {
    return dataArray
      .sort((a, b) => a[sortingProp] - b[sortingProp] || 0)
      .map((user) => ({
        ...user,
        ...(sortingProp === 'order' ? { order: undefined } : {}),
      }))
  }
}
