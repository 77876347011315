import { FormMode } from '../enums/form-mode.enums'
import { ToDictionaryOpts } from '@core/models/dictionary.models'

interface DateParams {
  day?: number
  month?: number
  year?: number
  offset?: number
}

export const cloneJSON = (json: any) => JSON.parse(JSON.stringify(json))

export const cloneObject = (obj) => {
  const clone = {}
  Object.keys(obj).forEach(function (key) {
    clone[key] =
      obj[key] != null && typeof obj[key] === 'object' && !Array.isArray(obj[key])
        ? cloneObject(obj[key])
        : obj[key]
  })
  return clone
}

export const getISODate = (date, universal = false, toISOString = false, inverse = false) => {
  date = date && new Date(date)
  if (date && universal) {
    date.setUTCMinutes(date.getTimezoneOffset() * (inverse ? 1 : -1))
  }
  return !date || !toISOString ? date : date.toISOString()
}

export const setEndDate = (date, toISOString = false) => {
  date = date && new Date(date)
  if (date) {
    date.setDate(date.getDate() + 1)
    date.setMilliseconds(date.getMilliseconds() - 1)
  }
  return !date || !toISOString ? date : date.toISOString()
}

export const fromUTC = (dateString) =>
  !dateString || dateString.includes('Z') ? dateString : dateString + 'Z'

export const dateFromString = (dateString, universal) => {
  const universalFormat = universal ? dateString.split('T')[0] + 'T00:00:00' : dateString
  const date =
    dateString && !(dateString instanceof Date) ? new Date(fromUTC(universalFormat)) : dateString
  return getISODate(date, universal, false, true)
}

export const now = new Date()

export const getDate = (dateParams: DateParams = <DateParams>{}) => {
  const { day, year, month, offset } = dateParams
  const date = new Date(year || now.getFullYear(), month || now.getMonth(), day || now.getDate())
  if (offset) {
    date.setDate(date.getDate() + offset)
  }
  return date
}

export const nowDate = getDate()

export const getDateFromCurrent = (days: number) => {
  const date = new Date(nowDate)
  date.setDate(date.getDate() + days)
  return date
}

export const createTranslationKeys = (dictionary, item, keys: string[], fallbackKey?) =>
  keys.reduce(
    (obj, key, index) => ({
      ...obj,
      [key + 'Key']: dictionary[item[key]] || (fallbackKey || [])[index] || dictionary.default,
    }),
    {},
  )

export const firstCapitalLetter = (str) => str.charAt(0).toUpperCase() + str.toLowerCase().slice(1)

export const isNewFormMode = (formMode) => [FormMode.New].includes(formMode)

export const isNotNewFormMode = (formMode) =>
  !isNewFormMode(formMode) && isNotRenewFormMode(formMode)

export const isRenewFormMode = (formMode) =>
  [FormMode.ManualManagement, FormMode.ProcessRequest].includes(formMode)

export const isResolveFormMode = (formMode) => [FormMode.Resolve].includes(formMode)

export const isNotRenewFormMode = (formMode) => !isRenewFormMode(formMode)

export const isIncluded = (value, list = []) => list.includes(value)

let lastId = 0
export const uniqueId = () => `bo-${lastId++}`

export const clearStorageByPrefix = (storage, prefix) =>
  Array(storage.length)
    .fill(1)
    .map((v, i) => storage.key(i))
    .filter((key) => key.indexOf(prefix) === 0)
    .forEach((key) => storage.removeItem(key))

export const flattenDeepObj = (obj) =>
  Object.keys(obj).reduce(
    (_obj, key) => ({
      ..._obj,
      ...(obj[key] && typeof obj[key] === 'object' && !Array.isArray(obj[key])
        ? flattenDeepObj(obj[key])
        : { [key]: obj[key] }),
    }),
    {},
  )

export const inputHasChangedTo = (baseValue, currentValue, sensitiveValue) =>
  baseValue !== currentValue && currentValue === sensitiveValue

export const arrayToStringList = (arr: string[], connector: string) =>
  arr.join(', ').replace(/, ((?:.(?!, ))+)$/, ` ${connector} $1`)

export const DEFAULT_QUERY_PAGING = (items = 10) => ({
  paging: {
    recordsRange: {
      start: 0,
      end: items,
    },
  },
})

export const inRange = (value, max, min = 0) => Math.max(min, Math.min(value, max))

export const parseHHMMSSToSeconds = (fullTime: string) =>
  fullTime
    .split(':')
    .map((timePart) => parseFloat(timePart))
    .reduce((acc, time) => acc * 60 + time)

export const getMaxArrayDepth = (arr, nestedKey = 'children') =>
  (arr || []).length > 0 ? 1 + Math.max(...arr.map((item) => getMaxArrayDepth(item[nestedKey]))) : 0

export const getMaxObjDepth = (obj, nestedKey = 'children') =>
  Object.keys(obj || {}).length > 0
    ? 1 + Math.max(...Object.values(obj).map((item) => getMaxObjDepth(item[nestedKey])))
    : 0

export const deepEqual = (x, y) => {
  if (x === y) {
    return true
  } else if (
    (x instanceof RegExp && y instanceof RegExp) ||
    (x instanceof Function && y instanceof Function)
  ) {
    return x.toString() === y.toString()
  } else if (typeof x === 'object' && x != null && typeof y === 'object' && y != null) {
    const [xKeys, yKeys] = [Object.keys(x), Object.keys(y)]

    if (xKeys.length !== yKeys.length) {
      return false
    }

    return Array.from(new Set([...xKeys, ...yKeys])).every((key) => deepEqual(x[key], y[key]))
  } else {
    return false
  }
}

export const toNestedDictionary = (arr, opts?: ToDictionaryOpts) => {
  const { key, nestedKey, isArrayMode } = {
    key: 'id',
    isArrayMode: false,
    ...(opts || <ToDictionaryOpts>{}),
  }
  return (
    arr &&
    arr.reduce((obj, item) => {
      item = {
        ...item,
        ...(nestedKey && (item[nestedKey] || []).length > 0
          ? { children: toNestedDictionary(item[nestedKey], { key, nestedKey, isArrayMode }) }
          : {}),
      }
      return { ...obj, [item[key]]: !isArrayMode ? item : [...(obj[item[key]] || []), item] }
    }, {})
  )
}

export const sortBy = (items, key = 'order') =>
  items.sort((a, b) => a[key] - b[key] || 0).map((data) => ({ ...data, [key]: undefined }))
