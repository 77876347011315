import { Inject, Injectable } from '@angular/core'
import { DEBUG_TOKEN_OIDC, FACTORY_TOKEN_OIDC } from '../constants/oidc.constants'
import { Log, SigninRedirectArgs, SigninSilentArgs, User, UserManager } from 'oidc-client-ts'
import { from, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '@env'

@Injectable()
export class OidcService {
  constructor(
    @Inject(FACTORY_TOKEN_OIDC) public readonly manager: UserManager,
    @Inject(DEBUG_TOKEN_OIDC) public readonly debug: boolean,
  ) {
    if (!environment.production) {
      Log.setLogger(console)
      Log.setLevel(Log.DEBUG)
    }
  }

  removeUser(): Observable<boolean> {
    return from(this.manager.removeUser()).pipe(map(() => true))
  }

  getUser(): Observable<User> {
    return from(this.manager.getUser())
  }

  signInRedirectCallback(url?: string): Observable<User> {
    return from(this.manager.signinRedirectCallback(url))
  }

  signInSilentCallback(url?: string): Observable<void> {
    return from(this.manager.signinSilentCallback(url))
  }

  signInRedirect(args?: SigninRedirectArgs): Observable<void> {
    return from(this.manager.signinRedirect(args))
  }

  signInSilent(args?: SigninSilentArgs): Observable<User> {
    return from(this.manager.signinSilent(args))
  }
}
