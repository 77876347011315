import { TokenInterceptor } from '@core/requests/interceptors/token.interceptor'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { AlertsInterceptor } from '@core/requests/interceptors/alerts.interceptor'
import { TenantInterceptor } from '@core/requests/interceptors/tenant.interceptor'
import { CacheInterceptor } from '@core/requests/interceptors/cache.interceptor'

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: TenantInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AlertsInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
]
