<ng-container
  *ngIf="{
    isLoggedIn: isLoggedIn$ | async,
    profile: profile$ | async,
    apps: applications$ | async,
    tenants: tenants$ | async,
    selectedTenant: selectedTenant$ | async
  } as data"
>
  <div class="bo-header">
    <app-main-logo></app-main-logo>
    <ng-container *ngIf="data?.isLoggedIn; else notLogged">
      <div class="bo-header__tenants" *ngIf="!!tenantCtrl?.value">
        <label class="bo-header__tenants-label">{{ 'MTR_COMMON_TENANT' | translate }} </label>
        <ng-container *ngIf="data?.tenants">
          <mc-select
            [filter]="data?.tenants?.length > 7"
            [formControl]="tenantCtrl"
            [options]="data?.tenants"
          ></mc-select>
        </ng-container>
      </div>
      <div class="bo-header__not-search-content"></div>
      <!--<app-search></app-search> // FIXME uncomment when feature is implemented-->
      <!--<app-notifications></app-notifications>-->
      <ng-container *ngIf="data?.profile">
        <app-dialog-application class="app-dialog-application" #appDialog></app-dialog-application>
        <mcui-application-menu
          [config]="appConfig"
          label="APPS"
          [applicationUserProfileTypes]="data?.apps || []"
          (deactivatedChange)="
            appDialog.openModal(applicationDialogType.Contact, $event, data?.profile)
          "
          (deepLinkChange)="
            appDialog.openModal(applicationDialogType.Download, $event, data?.profile)
          "
        />
      </ng-container>
      <app-quick-view [userProfile]="data?.profile"></app-quick-view>
    </ng-container>
    <ng-template #notLogged>
      <button class="bo-header__logout-bt" routerLink="login">
        {{ 'MTR_COMMON_LOGIN' | translate }}
      </button>
    </ng-template>
  </div>
</ng-container>
