import { NgModule } from '@angular/core'
import { AuthApi } from '@core/authentication/auth/api/auth.api'
import { CallbackComponent } from '@core/authentication/auth/components/callback.component'
import { SharedModule } from '@shared/shared.module'
import { LoginComponent } from '@core/authentication/auth/components/login.component'
import { LogoutComponent } from '@core/authentication/auth/components/logout.component'

const COMPONENTS = [LoginComponent, CallbackComponent, LogoutComponent]

@NgModule({
  imports: [SharedModule],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  providers: [AuthApi],
})
export class AuthModule {}
