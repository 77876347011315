import { InjectionToken, NgModule } from '@angular/core'
import { extModules } from 'app/ngrx-devtools'
import { ActionReducerMap, MetaReducer, StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { StoreToken } from './enums/store-token.enum'
import { profileReducer } from '@core/state/reducers/profile.reducer'
import { OidcEffects } from '@core/state/effects/oidc.effects'
import { StartupEffects } from '@core/state/effects/startup.effects'
import { ProfileEffects } from '@core/state/effects/profile.effects'
import { tenantReducer } from '@core/state/reducers/tenant.reducer'
import { TenantEffects } from '@core/state/effects/tenant.effects'

export const getReducers = (): ActionReducerMap<any> => ({
  [StoreToken.auth]: profileReducer,
  [StoreToken.tenants]: tenantReducer,
})

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>('global')
export const metaReducers: MetaReducer<any>[] = []

const EFFECTS = [OidcEffects, StartupEffects, ProfileEffects, TenantEffects]

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forRoot(REDUCER_TOKEN, { metaReducers }),
    extModules,
    EffectsModule.forRoot([...EFFECTS]),
  ],
  providers: [{ provide: REDUCER_TOKEN, useFactory: getReducers }],
})
export class StateModule {}
