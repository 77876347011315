import { UntypedFormGroup } from '@angular/forms'
import { FlattenDeep } from '@mediacoach-ui-library/global'
import { GroupObjItem } from './checkbox-group.models'

const innerGetLeafFormGroupNodes = (formGroup: UntypedFormGroup) =>
  Object.values(formGroup.controls).reduce(
    (arr, _formGroup) =>
      arr.find((fg) => fg.parent === _formGroup.parent)
        ? arr
        : [
            ...arr,
            _formGroup && _formGroup.get('children')
              ? innerGetLeafFormGroupNodes(<UntypedFormGroup>_formGroup.get('children'))
              : _formGroup,
          ],
    [],
  )

export const getLeafFormGroupNodes = (formGroup: UntypedFormGroup) =>
  FlattenDeep(innerGetLeafFormGroupNodes(formGroup))

export const getFlatFormValue = (formObj: GroupObjItem) =>
  Object.entries(formObj).reduce(
    (arr, [key, value]) => [
      ...arr,
      ...(value.isCheckAll || !value.checked ? [] : [key]),
      ...(value.children ? getFlatFormValue(value.children) : []),
    ],
    [],
  )

export const changeAllCheckedTo = (obj: GroupObjItem, checked?) =>
  Object.entries(obj).reduce(
    (_obj, [key, { isCheckAll, children }]) => ({
      ..._obj,
      [key]: {
        ...(children ? { children: changeAllCheckedTo(children, checked) } : {}),
        checked,
        isCheckAll,
      },
    }),
    {},
  )
