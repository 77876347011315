import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core'
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms'

import { Observable } from 'rxjs'
import { debounceTime, tap } from 'rxjs/operators'

import { DebounceTimeType } from '@mediacoach-ui-library/global'

import { cloneJSON, getMaxObjDepth, toNestedDictionary } from '@core/utils/main'

import { GroupItem, GroupObj } from './checkbox-group.models'
import { changeAllCheckedTo, getFlatFormValue, getLeafFormGroupNodes } from './checkbox-group.utils'

const CHECKBOX_GROUP_COMPONENTS = {}
@Component({
  selector: 'app-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxGroupComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxGroupComponent implements ControlValueAccessor {
  private _formAlias: string

  depth = 0
  configObj: GroupObj
  resetFormObj: GroupObj
  mainFormGroup: UntypedFormGroup
  formChanges$: Observable<JSON>

  Object = Object

  @Input() flat = true
  @Input() extraClasses: string | { [key: string]: boolean } | string[]

  @Input() set config(_config: GroupItem[] | HTMLCollection) {
    this.configObj = toNestedDictionary(_config, { nestedKey: 'children' })
    this._buildForm()
  }

  get formAlias() {
    return this._formAlias
  }
  @Input() set formAlias(_formAlias) {
    this._unregisterComponent()
    this._formAlias = _formAlias
    this._registerComponent()
    this._buildForm()
  }

  @Input() set disabled(_disabled) {
    const emitEvent = false
    if (_disabled) {
      this.mainFormGroup.disable({ emitEvent })
    } else {
      this.mainFormGroup.enable({ emitEvent })
    }
  }

  @Output() onChange = new EventEmitter()

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _ref: ChangeDetectorRef,
  ) {}

  private _unregisterComponent() {
    if (this._formAlias) {
      CHECKBOX_GROUP_COMPONENTS[this._formAlias] = (
        CHECKBOX_GROUP_COMPONENTS[this._formAlias] || []
      ).filter((component) => component !== this)
    }
  }

  private _registerComponent() {
    if (this._formAlias) {
      CHECKBOX_GROUP_COMPONENTS[this._formAlias] = [
        ...(CHECKBOX_GROUP_COMPONENTS[this._formAlias] || []),
        this,
      ]
    }
  }

  private _broadcastChanges() {
    if (this._formAlias) {
      CHECKBOX_GROUP_COMPONENTS[this._formAlias]
        .filter((component) => component !== this)
        .forEach((component) => component.emitChange())
    }
  }

  private _checkLeafInputs() {
    getLeafFormGroupNodes(this.mainFormGroup).forEach((formGroup) => this._checkParent(formGroup))
  }

  private _buildForm() {
    const formGroupObj = (formObj: GroupObj) =>
      this._formBuilder.group(
        Object.entries(formObj || {}).reduce(
          (obj, [id, { checked, isCheckAll, validators, children }]) => ({
            ...obj,
            [id]: this._formBuilder.group({
              checked: [!isCheckAll ? checked || false : checked, validators || []],
              isCheckAll: [{ value: isCheckAll, disabled: true }, validators || []],
              ...(Object.keys(children || []).length > 0
                ? { children: formGroupObj(children) }
                : {}),
            }),
          }),
          {},
        ),
      )

    this.mainFormGroup = formGroupObj(this.configObj)
    this.depth = getMaxObjDepth(this.configObj)

    this._checkLeafInputs()

    this.resetFormObj = changeAllCheckedTo(this.mainFormGroup.getRawValue(), false)

    this.formChanges$ = this.mainFormGroup.valueChanges.pipe(
      debounceTime(DebounceTimeType.ForCrashes),
      tap(() => {
        this.emitChange()
        this._broadcastChanges()
      }),
    )
  }

  private emitChange() {
    const isFlat = this.flat
      ? getFlatFormValue(this.mainFormGroup.getRawValue())
      : cloneJSON(this.mainFormGroup.value)
    const formObj = this.mainFormGroup.valid ? isFlat : null
    this.onChange.emit(formObj)
    this.onModelTouch()
    this.onModelChange(formObj)
  }

  private _checkChildren(formGroup, checked) {
    ;(Object.values(formGroup.get('children')?.controls || {}) as UntypedFormGroup[]).forEach(
      (childFormGroup) => {
        childFormGroup.patchValue({ checked })
        this._checkChildren(childFormGroup, checked)
      },
    )
  }

  private _checkChecked = (obj, fg: UntypedFormGroup, key: string, valueToCompare?) => {
    if (key === 'nullValues') {
      return (obj.nullValues || 0) + (fg.get('checked').value == null ? 1 : 0)
    }
    return (obj[key] || 0) + (fg.get('checked').value === valueToCompare ? 1 : 0)
  }

  private _checkParent(formGroup: UntypedFormGroup) {
    const siblings =
      formGroup.parent && <UntypedFormGroup[]>Object.values(formGroup.parent.controls)
    const parentFormGroup = (siblings || []).length > 0 && <UntypedFormGroup>formGroup.parent.parent
    const isCheckAll = parentFormGroup && parentFormGroup.get('isCheckAll').value
    if (isCheckAll) {
      const siblingLength = siblings.length
      const { trueValues, falseValues, nullValues } = Object.values(siblings).reduce(
        (obj, fg) => {
          return {
            trueValues: this._checkChecked(obj, fg, 'trueValues', true),
            falseValues: this._checkChecked(obj, fg, 'falseValues', false),
            nullValues: this._checkChecked(obj, fg, 'nullValues'),
          }
        },
        <{ trueValues: number; falseValues: number; nullValues: number }>{},
      )
      const checkFalseOrNullSiblingLength = falseValues === siblingLength ? false : null
      const checkSiblingValues = trueValues === siblingLength ? true : checkFalseOrNullSiblingLength
      const checked = nullValues ? null : checkSiblingValues
      parentFormGroup.patchValue({ checked })
      this._checkParent(parentFormGroup)
    }
  }

  onModelChange = (_: any) => {
    // This is intentional
  }
  onModelTouch = () => {
    // This is intentional
  }

  writeValue(value) {
    this.resetForm()
    this.mainFormGroup.patchValue(value || {}, { emitEvent: false })
    this._checkLeafInputs()
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onModelChange = fn
  }

  setDisabledState(val: boolean) {
    const emitEvent = false
    if (val) {
      this.mainFormGroup.disable({ emitEvent })
    } else {
      this.mainFormGroup.enable({ emitEvent })
    }
  }

  onInputChange(formGroup: UntypedFormGroup, { checked }) {
    this._checkChildren(formGroup, checked)
    this._checkParent(formGroup)
  }

  resetForm() {
    this.mainFormGroup.patchValue(this.resetFormObj, { emitEvent: false })
    this._ref.detectChanges()
  }
}
