export enum IconType {
  Success = 'check',
  Failure = 'times',
  Undefined = 'minus',
}

export enum ColumnType {
  Icon = 'icon',
  Date = 'date',
  Toggle = 'toggle',
  Custom = 'custom',
  TextIcon = 'textIcon',
  ContextMenu = 'contextMenu',
}

export enum SelectionMode {
  None = '',
  Single = 'single',
  Multiple = 'multiple',
}

export enum DateType {
  Date,
  DateTime,
}
