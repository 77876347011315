import { APP_INITIALIZER, NgModule } from '@angular/core'
import { I18nEffects } from '@core/i18n/state/effects/i18n.effects'
import { I18nApi } from '@core/i18n/api/i18n.api'
import { Store } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { initTranslateModule } from '@core/i18n/factories/i18n.factory'

@NgModule({
  imports: [EffectsModule.forFeature([I18nEffects])],
  providers: [
    I18nApi,
    {
      provide: APP_INITIALIZER,
      useFactory: initTranslateModule,
      deps: [Store],
      multi: true,
    },
  ],
})
export class I18nModule {}
