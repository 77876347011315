import { Injectable } from '@angular/core'
import {
  HttpContext,
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Observable } from 'rxjs'
import { filter, switchMap, take } from 'rxjs/operators'
import { TenantState } from '@core/state/models/tenant.state'
import { Store } from '@ngrx/store'
import { getSelectedTenant } from '@core/state/selectors/tenant.selector'
import { TENANT_HEADER_NAME } from '@core/requests/constants/requests.constants'

const IGNORE_TENANT = new HttpContextToken<boolean>(() => false)
export const ignoreTenant = () => new HttpContext().set(IGNORE_TENANT, true)

@Injectable()
export class TenantInterceptor implements HttpInterceptor {
  private _tenant$ = this._store.select(getSelectedTenant)

  constructor(private readonly _store: Store<TenantState>) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this._tenant$.pipe(
      filter((tenant) => !!tenant || request.context.get(IGNORE_TENANT)),
      take(1),
      switchMap((tenant) => next.handle(this._buildRequest(request, tenant))),
    )
  }

  private _buildRequest(request: HttpRequest<any>, tenant: string) {
    return tenant ? this._cloneWithHeaders(request, tenant) : request
  }

  private _cloneWithHeaders(request: HttpRequest<any>, tenantId: string): HttpRequest<any> {
    return request.clone({ headers: request.headers.set(TENANT_HEADER_NAME, tenantId) })
  }
}
