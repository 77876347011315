import { MatchState } from '@mediacoach-ui-library/global'

import { AssetStatus } from '@core/enums/asset.enums'

export const PROVIDER_DEFAULT_NAME = 'Opta'

export const MATCH_STATE_TRANSLATION = {
  [MatchState.Pending]: 'MTR_MATCHES_PENDING',
  [MatchState.Postponed]: 'MTR_MATCHES_POSTPONED',
  [MatchState.FullTime]: 'MTR_MATCHES_FULLTIME',
  [MatchState.Delayed]: 'MTR_MATCHES_DELAYED',
  [MatchState.Abandoned]: 'MTR_MATCHES_ABANDONED',
  [MatchState.Finished]: 'MTR_MATCHES_FULLTIME',
  [MatchState.HalfTime]: 'MTR_MATCHES_HALFTIME',
  [MatchState.FirstHalf]: 'MTR_MATCHES_LIVE',
  [MatchState.SecondHalf]: 'MTR_MATCHES_LIVE',
  [MatchState.LineupReady]: 'MTR_MATCHES_LINEUPREADY',
  default: 'MTR_MATCHES_UNDEFINED',
}

export const MATCH_STATUS_WITH_ICONS = [AssetStatus.Available, AssetStatus.Failed]
