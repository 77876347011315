import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
  @Input() text: string
  @Input() icon: string
  @Input() isDisabled: boolean
  @Input() tooltipText: string
  @Input() extraClasses: string | { [key: string]: boolean } | string[]
  @Input() isNativeIcon: boolean

  constructor() {
    // This is intentional
  }
}
