export const getClearFnKey = (paramHash?) => `${paramHash || ''}__mcm-clear-fn--key`

export const setClearFn = (obs, paramHash, clearFnt) => {
  obs[getClearFnKey(paramHash)] = clearFnt
}
export const clearFn = (obs, paramHash, data?) => {
  if (obs[paramHash]) {
    obs[paramHash] = null
    const clearFnObs = obs[getClearFnKey(paramHash)]
    if (clearFnObs) {
      clearFnObs(data)
    }
  }
}
