import { AbstractControl, UntypedFormGroup, ValidatorFn } from '@angular/forms'
import { parseHHMMSSToSeconds } from '@core/utils/main'

export function atLeastValidator(min: number, max?: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (
      control.value != null &&
      (control.value.length < min || (max && control.value.length > max))
    ) {
      return { atLeast: true }
    }
    return null
  }
}

export function compareValues(
  fields: { fieldName: any; shouldDisplayError?: boolean }[],
  comparison: (fieldValues: string[]) => boolean,
): ValidatorFn {
  return (group: UntypedFormGroup): { [key: string]: boolean } | null => {
    const valueArr = fields.map((field) => group.get(field.fieldName).value)
    const fieldWithErrorDisplay = group.get(
      fields.find((field) => field.shouldDisplayError).fieldName,
    )

    if (fieldWithErrorDisplay.errors && !fieldWithErrorDisplay.errors['comparisonFailed']) {
      return null
    }

    if (comparison(valueArr)) {
      fieldWithErrorDisplay.setErrors({ comparisonFailed: true })
      return { comparisonFailed: true }
    } else {
      fieldWithErrorDisplay.setErrors(null)
      return null
    }
  }
}

export const MaskOnRegex = (regex) => (maxLength) => (event) => {
  const { key, keyCode, target } = event
  if (keyCode > 47 && (!key.match(regex) || target.value.length >= maxLength)) {
    event.preventDefault()
  }
}
