import { createAction, props } from '@ngrx/store'
import { Tenant } from '@core/models/tenant.models'

export const fetchTenants = createAction('[Tenants] fetch tenant list')
export const setTenants = createAction('[Tenants] set tenants', props<{ list: Tenant[] }>())
export const setSelectedTenant = createAction(
  '[Tenants] set selected tenant',
  props<{ selected: string }>(),
)
export const saveSelectedTenant = createAction(
  '[Tenants] save selected tenant to store',
  props<{ selected: string }>(),
)
