import { Action, createReducer, on } from '@ngrx/store'
import { TenantState } from '@core/state/models/tenant.state'
import { saveSelectedTenant, setTenants } from '@core/state/actions/tenant.actions'

const initialState: TenantState = {
  list: null,
  selected: null,
}

const reducer = createReducer(
  initialState,
  on(setTenants, (state, { list }) => ({ ...state, list })),
  on(saveSelectedTenant, (state, { selected }) => ({ ...state, selected })),
)

export function tenantReducer(state: TenantState | undefined, action: Action) {
  return reducer(state, action)
}
