import { Injectable } from '@angular/core'
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Router } from '@angular/router'

import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'

import { environment } from '@env'

import { ToastType } from '../../components/toast/toast.models'
import { ToastService } from '../../components/toast/toast.service'

@Injectable()
export class AlertsInterceptor implements HttpInterceptor {
  constructor(
    private toasterService: ToastService,
    private router: Router,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(null, (error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            sessionStorage.clear()
            console.log('ERROR')
            this.router.navigate([environment.REDIRECT_LOGIN_URL])
          } else if (error.status) {
            const errorResponse = error.error || {}
            const errorResponseString = JSON.stringify(errorResponse)
            const minus = '-'
            const errorCode = errorResponse.code ? `${minus} ${errorResponse.code}` : ''
            this.toasterService.showToast({
              type: ToastType.Error,
              message:
                errorResponse.message ||
                (errorResponseString !== '{}' ? errorResponseString : null),
              devInfo: `${error.status} ${errorCode}`,
            })
          }
        }
      }),
    )
  }
}
