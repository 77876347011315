import { AuthFeature, AuthPage, RoleType } from '../enums/auth.enums'
import { ROLE_MAPPING } from '../constants/auth.constants'
import { MCUser, UserProfile } from '@core/authentication/auth/models/auth.models'
import { HttpErrorResponse, HttpResponse } from '@angular/common/http'
import { of, throwError } from 'rxjs'

export const mapRoleFeatures = (roles: RoleType[]): AuthFeature[] => {
  return Array.from(
    new Set(
      roles
        .map((role) => (ROLE_MAPPING[role] || <{ features: AuthFeature[] }>{}).features)
        .reduce((arr, features) => [...arr, ...(features || [])], []),
    ),
  )
}

export const mapRolePermissions = (roles: RoleType[]): AuthPage[] => {
  return Array.from(
    new Set(
      roles
        .map((role) => (ROLE_MAPPING[role] || <{ permissions: AuthPage[] }>{}).permissions)
        .reduce((arr, permissions) => [...arr, ...(permissions || [])], []),
    ),
  )
}

export const mapMCUser = (
  user: MCUser,
): MCUser & { photoPath: string; customerId: string; tenantId: string; profileIds: string[] } => ({
  ...user,
  photoPath: user.userAvatar && user.userAvatar.avatarPath,
  customerId: user.customer.id,
  tenantId: user.customer.calculatedTenantId,
  profileIds: user.profiles.map(({ id }) => id),
})

export const handleAuthorizationError = (
  error: HttpResponse<HttpErrorResponse>,
  params = { throwError: false },
) => (params.throwError ? throwError(() => error) : of(<UserProfile>{}))
