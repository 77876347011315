import { Component, Input } from '@angular/core'

import { interval } from 'rxjs'
import { map, take } from 'rxjs/operators'

import { COUNTDOWN_STEP } from './countdown.constants'
import { CountDownUnits } from './countdown.enums'

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
})
export class CountdownComponent {
  @Input() displayMessage: string
  @Input() displayUnits: CountDownUnits
  @Input() initialTimer: number

  currentValue: number

  constructor() {
    // This is intentional
  }

  initCountDown$(initialTimer = this.initialTimer) {
    return interval(COUNTDOWN_STEP).pipe(
      take(initialTimer),
      map(() => (this.currentValue = --initialTimer)),
    )
  }
}
