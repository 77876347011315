import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormBuilder } from '@angular/forms'

import { TranslatorService } from '@mediacoach-ui-library/global'
import { MarketableService } from '@features/marketable/marketable.service'
import { GenericFormComponent } from '@shared/components/generic-form/generic-form.component'
import { UserCustomerViewService } from '@features/user-customers/user-customer-view.service'
import { MARKETABLE_DEFAULT_AUTOCOMPLETE_LAZY_PARAMS } from '@features/marketable/marketable.constants'

import { FieldName } from './sales-form.enums'
import { ColumnSetting } from '../table/table.models'
import { VALIDATORS } from '../general-form/general-form.constants'
import { COLUMN_SETTINGS, FORM_INPUTS } from './sales-form.constants'
import { filter, skip } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Store } from '@ngrx/store'
import { getSelectedTenant } from '@core/state/selectors/tenant.selector'

@UntilDestroy()
@Component({
  selector: 'app-sales-form',
  templateUrl: './sales-form.component.html',
  styleUrls: ['./sales-form.component.scss'],
})
export class SalesFormComponent extends GenericFormComponent implements OnInit, OnDestroy {
  private _marketablePackageParams = {}

  override get data() {
    return this._data
  }

  @Input() override set data(_data) {
    this._data = _data
    this.updateFormValues({ emitEvent: false })
    this._marketablePackageParams = {}
  }

  VALIDATORS = VALIDATORS

  columnSettings: ColumnSetting[] = COLUMN_SETTINGS

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly translatorService: TranslatorService,
    private readonly marketableService: MarketableService,
    private readonly userCustomerViewService: UserCustomerViewService,
    private readonly _store: Store,
  ) {
    super(fb, translatorService, FORM_INPUTS)
    this._store
      .select(getSelectedTenant)
      .pipe(
        filter((t) => !!t),
        skip(1),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this._getInputOptions()
        this.form.get(FieldName.CustomerId).reset()
        this.form.get(FieldName.MarketablePackageId).reset()
      })
  }

  private _getInputOptions() {
    const { customerId, marketablePackageId, expirationTime } = this.formInputs
    expirationTime.minDate = new Date()
    customerId.options = this.userCustomerViewService.getCustomerOptions$(true)
    customerId.onChange = (formData) => {
      this._marketablePackageParams = {
        ...this._marketablePackageParams,
        customerIdToExcludePackages: formData[FieldName.CustomerId],
      }
    }
    marketablePackageId.getOptionsOnInput = (freeText) => {
      this._marketablePackageParams = { ...this._marketablePackageParams, freeText }
      marketablePackageId.options$ = this.marketableService.getMarketablePackagesGroupOptions$(
        this._marketablePackageParams,
        freeText ? {} : MARKETABLE_DEFAULT_AUTOCOMPLETE_LAZY_PARAMS,
      )
    }
  }

  override ngOnInit() {
    this._getInputOptions()
    super.ngOnInit()
  }

  override ngOnDestroy() {
    super.ngOnDestroy()
  }
}
