import { Injectable } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'

import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'

import { toNestedDictionary } from '@core/utils/main'

import { IAsset, IAssetDownload } from './download.models'
import { ASSETS_TO_SANITIZER, DOWNLOAD_ASSETS } from './download.constants'

@Injectable()
export class DownloadService {
  private assetsDownload$!: Observable<IAssetDownload>

  constructor(public sanitizer: DomSanitizer) {}

  private _getUrlSanitizedUri(asset: IAsset) {
    return ASSETS_TO_SANITIZER.includes(asset.friendlyName)
      ? this.sanitizer.bypassSecurityTrustUrl(asset.SUR)
      : asset.SUR
  }

  private _getAssetsDownload(dictionaryAssets: any) {
    return Object.keys(dictionaryAssets).reduce(
      (prev, key) => ({
        ...prev,
        [key]: dictionaryAssets[key].reduce((prevAsset: any, current: any) => {
          const asset = { ...current, SUR: this._getUrlSanitizedUri(current) }
          const languages = asset.lang.split('_')
          return {
            ...prevAsset,
            ...languages.reduce(
              (obj: any, lang: any) => ({
                ...obj,
                [lang]: [...(prevAsset[lang] || []), asset],
              }),
              {},
            ),
          }
        }, {}),
      }),
      {},
    )
  }

  getAssetsDownload$(): Observable<IAssetDownload> {
    return (
      this.assetsDownload$ ||
      (this.assetsDownload$ = of(DOWNLOAD_ASSETS).pipe(
        map(
          (data) => <IAssetDownload>this._getAssetsDownload(
              toNestedDictionary(data.Assets, {
                key: 'groupType',
                nestedKey: 'children',
                isArrayMode: true,
              }) || {},
            ),
        ),
      ))
    )
  }
}
