import { Component, Input } from '@angular/core'

import { environment } from '@env'

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  @Input() extraClasses: string | { [key: string]: boolean } | string[]
  urlLoaderImg = environment.STATICS_URL_ASSETS + 'images/loader_ball.gif'

  constructor() {
    // This is intentional
  }
}
