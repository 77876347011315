import { Directive, HostBinding } from '@angular/core'

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'a[externalLink]',
})
export class ExternalLinkDirective {
  @HostBinding('attr.rel') rel = 'noopener'
  @HostBinding('attr.target') target = '_blank'
}
