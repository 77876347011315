import { ProfileState } from '@core/state/models/profile.state'
import { select, Store } from '@ngrx/store'
import { getProfileLang } from '@core/state/selectors/profile.selectors'
import { filter } from 'rxjs/operators'
import { fetchTranslations } from '@core/i18n/state/actions/i18n.actions'

export const initTranslateModule = (store: Store<ProfileState>) => () => {
  store
    .pipe(
      select(getProfileLang),
      filter((lang) => !!lang),
    )
    .subscribe((lang) => store.dispatch(fetchTranslations({ lang })))
}
