export enum FieldName {
  Id = 'id',
  Name = 'name',
  Group = 'group',
}

export enum PermissionType {
  CrossContentPermissions = 'contentPermissions',
  ApplicationPermissions = 'applicationPermissions',
}

export enum AppPermissionType {
  Route = 1,
  AppContent = 2,
}

export enum RoutePermissionKey {
  TeamList = 'equipos',
  TeamDetail = 'seasons/.+/competitions/.+/teams/.+',
  PlayerList = 'jugadores',
  PlayerDetail = 'seasons/.+/competitions/.+/teams/.+/players/.+',
}
