import { NgModule } from '@angular/core'

import { TableModule } from 'primeng/table'
import { MenuModule } from 'primeng/menu'
import { ButtonModule } from 'primeng/button'
import { TooltipModule } from 'primeng/tooltip'
import { DropdownModule } from 'primeng/dropdown'
import { CalendarModule } from 'primeng/calendar'
import { InputTextModule } from 'primeng/inputtext'
import { FileUploadModule } from 'primeng/fileupload'
import { MultiSelectModule } from 'primeng/multiselect'
import { SelectButtonModule } from 'primeng/selectbutton'
import { AutoCompleteModule } from 'primeng/autocomplete'
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox'
import { RadioButtonModule } from 'primeng/radiobutton'
import { CheckboxModule } from 'primeng/checkbox'
import { MessageModule } from 'primeng/message'
import { TreeModule } from 'primeng/tree'

const MODULES = [
  MenuModule,
  TableModule,
  ButtonModule,
  TooltipModule,
  DropdownModule,
  CalendarModule,
  InputTextModule,
  FileUploadModule,
  MultiSelectModule,
  SelectButtonModule,
  AutoCompleteModule,
  TriStateCheckboxModule,
  RadioButtonModule,
  CheckboxModule,
  MessageModule,
  TreeModule,
]

@NgModule({
  exports: [...MODULES],
})
export class PrimengModule {}
