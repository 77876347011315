import { AuthPage } from '@core/authentication/auth/enums/auth.enums'
import { EnvType } from '@mediacoach-ui-library/global'

export const MAIN_APP_NAME = 'backoffice'
export const MAIN_APP_API = 'backoffice-api'

export const PORTS = {
  APP: 4200,
  IDENTITY_SERVER: 5050,
}

export const VARIABLES_BY_ENVIRONMENT: { [key in EnvType]?: any } = {
  [EnvType.Dv]: {},
  [EnvType.Qa]: {
    production: true,

    CURRENT_DEACTIVATED_PAGES: {
      [AuthPage.Alerts]: true,
      [AuthPage.Dashboard]: true,
      [AuthPage.Monitoring]: true,
      [AuthPage.GrafanaWidgets]: true,
      [AuthPage.MetricsManager]: true,
    },
  },
  [EnvType.Ts]: {
    production: true,

    CURRENT_DEACTIVATED_PAGES: {
      [AuthPage.Alerts]: true,
      [AuthPage.Dashboard]: true,
      [AuthPage.Monitoring]: true,
      [AuthPage.GrafanaWidgets]: true,
      [AuthPage.MetricsManager]: true,
    },
  },
  [EnvType.Pr]: {
    production: true,

    CURRENT_DEACTIVATED_PAGES: {
      [AuthPage.Alerts]: true,
      [AuthPage.Dashboard]: true,
      [AuthPage.Monitoring]: true,
      [AuthPage.GrafanaWidgets]: true,
      [AuthPage.MetricsManager]: true,
    },
  },
}
