import { Component } from '@angular/core'
import { MenuItem } from 'primeng/api'

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent {
  notificationItems: MenuItem[] = [
    {
      label: 'Notification 1',
    },
    {
      label: 'Notification 2',
    },
    {
      label: 'Notification 3',
    },
    {
      label: 'Notification 4',
    },
  ]

  constructor() {
    // This is intentional
  }
}
