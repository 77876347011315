import { purgeUserProfile, setMCUser, setUserProfile } from '@core/state/actions/profile.actions'
import { ProfileState } from '@core/state/models/profile.state'
import { setOidcUser } from '@core/state/actions/oidc.actions'
import { Action, createReducer, on } from '@ngrx/store'

const initialState: ProfileState = {
  oidc: null,
  profile: null,
  user: null,
}

const reducer = createReducer(
  initialState,
  on(setOidcUser, (state, { oidc }) => ({ ...state, oidc })),
  on(setUserProfile, (state, { profile }) => ({ ...state, profile })),
  on(setMCUser, (state, { user }) => ({ ...state, user })),
  on(purgeUserProfile, () => ({ ...initialState })),
)

export function profileReducer(state: ProfileState | undefined, action: Action) {
  return reducer(state, action)
}
