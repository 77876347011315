import { createAction, props } from '@ngrx/store'
import { ActivatedRoute } from '@angular/router'

export const navigate = createAction(
  '[Router] Navigate',
  props<{ path: string; relativeTo?: ActivatedRoute }>(),
)
export const navigateExternal = createAction(
  '[Router] Navigate External',
  props<{ path: string }>(),
)
export const navigateByUrl = createAction('[Router] Navigate by url', props<{ path: string }>())

export const changeLocation = createAction(
  '[Router] Change location without navigation',
  props<{ path: string }>(),
)

export const navigateToNewTab = createAction(
  '[Router] Navigate (open) to new tab',
  props<{ path: string }>(),
)
