import { EventEmitter, Input, OnDestroy, OnInit, Output, Directive } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'

import { FormMode } from '@core/enums/form-mode.enums'
import { InputType } from '@shared/components/general-form/general-form.enums'
import { FormInputSetting } from '@shared/components/general-form/general-form.models'

import { SelectionMode } from '../table/table.enums'
import { FormMetaInputs } from './generic-form.models'

import { BehaviorSubject } from 'rxjs'
import { TranslatorService } from '@mediacoach-ui-library/global'
import { debounceTime, share } from 'rxjs/operators'
import { environment } from '@env'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { cloneJSON, cloneObject } from '@core/utils/main'

@UntilDestroy()
@Directive()
export abstract class GenericFormComponent implements OnInit, OnDestroy {
  protected _mode = FormMode.Read
  protected _data
  protected _inputs: FormMetaInputs
  protected _defaultData

  get data() {
    return this._data
  }

  @Input() set data(_data) {
    this._data = _data
    this.updateFormValues({ emitEvent: true })
  }

  get mode(): FormMode {
    return this._mode
  }

  @Input() set mode(_mode: FormMode) {
    this._mode = _mode || FormMode.Read
    if (this.formInputs) {
      this.updateForm()
    }
  }

  protected constructor(
    protected readonly _fb: UntypedFormBuilder,
    private readonly _translatorService: TranslatorService,
    private readonly _formInputsSetting: FormInputSetting,
  ) {
    this.formInputs = <FormInputSetting>Object.entries(cloneObject(_formInputsSetting)).reduce(
      (obj, [key, inputSetting]) => ({
        ...obj,
        ...{ [key]: inputSetting },
      }),
      {},
    )
    this.language$ = this._translatorService.onLangChange
    this.buildForm()
  }

  form: UntypedFormGroup
  formInputs: FormInputSetting
  language$: BehaviorSubject<string>

  InputType = InputType
  SelectionMode = SelectionMode

  @Input() isEdit = false
  @Output() onChange = new EventEmitter()

  protected updateFormValues(params?) {
    if (JSON.stringify(this.data) === '{}') {
      this._data = this._defaultData
    }
    this.form.reset(this.data, params)
  }

  protected updateForm() {
    this._inputs = Object.entries(this.formInputs).reduce(
      (obj: FormMetaInputs, [key, inputInfo]) => ({
        disabledFormControls: [
          ...(obj.disabledFormControls || []),
          ...(inputInfo.disabledFn && inputInfo.disabledFn(this.mode) ? [this.form.get(key)] : []),
        ],
        defaultKeyValueIds: [
          ...(obj.defaultKeyValueIds || []),
          ...(inputInfo.valueId ? [{ key, valueId: inputInfo.valueId }] : []),
        ],
      }),
      {} as FormMetaInputs,
    )
    this._checkFormEnableness()
  }

  protected bindOnChangeInControls(controls = this.form.controls) {
    Object.keys(controls).forEach((key) => {
      if (this.formInputs[key] && this.formInputs[key].onChange) {
        controls[key].valueChanges
          .pipe(debounceTime(environment.DEBOUNCE_TIME.LONG), untilDestroyed(this), share())
          .subscribe(() => {
            this.formInputs[key].onChange(this.form.value)
          })
      }
    })
  }

  protected formSubscription(omitOnChange = false) {
    this.bindOnChangeInControls()

    this.form.valueChanges
      .pipe(debounceTime(environment.DEBOUNCE_TIME.LONG), untilDestroyed(this))
      .subscribe((genericForm) => {
        Object.entries(this.formInputs).forEach(([inputKey, inputValue]) => {
          const { selectedField } = inputValue
          if (selectedField) {
            genericForm[inputKey] = (genericForm[inputKey] || {})[selectedField]
          }
        })
        const id = (this.form.get('id') || ({} as any)).value
        genericForm = {
          ...genericForm,
          ...(id ? { id } : {}),
        }

        if (!omitOnChange) {
          this.onChange.emit(this.form.dirty && this.form.valid ? cloneJSON(genericForm) : null)
        }
      })
  }

  protected buildForm() {
    const formGroupObj = Object.entries(this.formInputs || {}).reduce(
      (obj, [key, inputInfo]) => ({
        ...obj,
        [key]: [inputInfo.value, inputInfo.validators || []],
      }),
      {},
    )
    this.form = this._fb.group(formGroupObj)
    this._defaultData = this.form.value
  }

  private _checkFormEnableness() {
    if (this.form) {
      this.form[this.mode !== FormMode.Read ? 'enable' : 'disable']({ emitEvent: false })
      this._inputs.disabledFormControls.forEach((input) => input.disable({ emitEvent: false }))
    }
  }

  ngOnInit() {
    this.formSubscription()
  }

  ngOnDestroy() {
    // This is intentional
  }

  resetForm() {
    this.updateFormValues({ emitEvent: false })
  }
}
