<ng-container *ngIf="{ isAdmin: isAdmin$ | async } as data">
  <form
    class="bo-general-form"
    [class.bo-general-form--edit]="isEdit"
    [formGroup]="userForm"
    *ngIf="userForm && formInputs"
  >
    <div class="p-grid p-justify-center p-align-center u-margin-bottom--lg">
      <div class="bo-general-form__avatar-wrapper p-col-fixed">
        <app-file-upload formControlName="photoPath"></app-file-upload>
      </div>

      <div class="p-col">
        <app-heading
          extraClasses="m-heading--md m-uppercase u-margin-bottom--lg"
          titleText="MTR_SUBTITLE_DETAILS"
        ></app-heading>
        <div class="p-grid">
          <ng-template
            ngFor
            let-inputKey
            [ngForOf]="[
              'name',
              'surname',
              'active',
              'email',
              'customerId',
              'isPrincipalCustomerContact',
              'positionId',
              'language',
              'twoFactorEnabled',
              'phoneNumber'
            ]"
          >
            <div
              *ngIf="formInputs[inputKey]"
              class="p-col-12 bo-form__input-group"
              [ngClass]="'p-md-' + (type === TemplateType.UserProfile ? 6 : 4)"
            >
              <ng-container
                *ngTemplateOutlet="formInput; context: { inputKey: inputKey }"
              ></ng-container>
            </div>
          </ng-template>
        </div>
      </div>
    </div>

    <ng-template [ngIf]="isIncluded(type, [TemplateType.User])">
      <app-heading
        extraClasses="m-heading--md m-uppercase u-margin-bottom--lg"
        titleText="MTR_SUBTITLE_CATEGORY"
      ></app-heading>
      <div class="p-grid u-margin-bottom--lg">
        <div class="p-col-12 p-md-6 bo-form__input-group">
          <ng-container
            *ngTemplateOutlet="formInput; context: { inputKey: 'categoryId' }"
          ></ng-container>
        </div>
      </div>
    </ng-template>

    <ng-template [ngIf]="isIncluded(type, [TemplateType.User, TemplateType.UserBO])">
      <app-heading
        extraClasses="m-heading--md m-uppercase u-margin-bottom--lg"
        titleText="MTR_SUBTITLE_LICENSE"
      ></app-heading>
      <div class="p-grid u-margin-bottom--lg">
        <div class="p-col-12 p-md-6 bo-form__input-group">
          <ng-container
            *ngTemplateOutlet="formInput; context: { inputKey: 'passwordExpiration' }"
          ></ng-container>
        </div>
        <div
          class="p-col-12 p-md-6 bo-form__input-group"
          *ngIf="isIncluded(type, [TemplateType.User])"
        >
          <app-button
            [text]="'MTR_COMMON_EXPIRE_PASSWORD'"
            (onClick)="onRevokePassword()"
            [qaId]="'bo-user-revoke-password'"
          ></app-button>
        </div>
      </div>
      <div class="p-grid u-margin-bottom--lg">
        <div class="p-col-12 p-md-6 bo-form__input-group">
          <ng-container
            *ngTemplateOutlet="formInput; context: { inputKey: 'activePeriodEnd' }"
          ></ng-container>
        </div>
        <div
          class="p-col-12 p-md-6 bo-form__input-group"
          *ngIf="isIncluded(type, [TemplateType.User])"
        >
          <app-button
            [text]="'MTR_COMMON_RESEND_WELCOME_EMAIL'"
            (onClick)="onResendWelcomeEmail()"
            [qaId]="'bo-user-resend-welcome-mail'"
          ></app-button>
        </div>
      </div>
    </ng-template>

    <ng-template [ngIf]="isIncluded(type, [TemplateType.User])">
      <app-heading
        extraClasses="m-heading--md m-uppercase u-margin-bottom--lg"
        titleText="MTR_SUBTITLE_APPLICATION_ACCESS"
      ></app-heading>
      <div class="p-grid u-margin-bottom--lg">
        <div class="p-col-12 bo-form__input-group">
          <ng-container
            *ngTemplateOutlet="formInput; context: { inputKey: 'profileIds' }"
          ></ng-container>
        </div>
      </div>
    </ng-template>

    <ng-template [ngIf]="isIncluded(type, [TemplateType.UserBO])">
      <app-heading
        extraClasses="m-heading--md m-uppercase u-margin-bottom--lg"
        titleText="MTR_SUBTITLE_BO_ROLES"
      ></app-heading>
      <div class="p-grid u-margin-bottom--lg">
        <div class="p-col-12 bo-form__input-group">
          <ng-container
            *ngTemplateOutlet="formInput; context: { inputKey: 'roleIds' }"
          ></ng-container>
        </div>
      </div>
    </ng-template>

    <ng-template #formInput let-inputKey="inputKey">
      <ng-container [ngSwitch]="formInputs[inputKey].type" *ngIf="formInputs[inputKey]">
        <ng-container *ngIf="inputKey + uniqueIdPrefix as inputId">
          <label
            class="bo-form__input-label u-margin-bottom--sm"
            [class.bo-form__input--required]="
              isEdit &&
              formInputs[inputKey].validators?.includes(VALIDATORS.required) &&
              !formInputs[inputKey].validators?.includes(VALIDATORS.email)
            "
            [translate]="formInputs[inputKey].label"
            [for]="inputId"
          ></label>
          <input
            *ngSwitchCase="InputType.Text"
            pInputText
            class="bo-form__input"
            [id]="inputId"
            [formControlName]="inputKey"
            (keydown)="formInputs[inputKey].keyMask && formInputs[inputKey].keyMask($event)"
          />

          <ng-container *ngSwitchCase="InputType.Dropdown">
            <ng-container *ngIf="(formInputs[inputKey].options | async) || [] as options">
              <app-dropdown
                *ngIf="formInputs[inputKey].isGroup"
                class="bo-form__input"
                [appendTo]="
                  type === TemplateType.UserProfile && inputKey === 'language' ? 'body' : null
                "
                [inputId]="inputId"
                [showClear]="false"
                [group]="formInputs[inputKey].isGroup"
                [options]="formInputs[inputKey].options | async"
                [placeholder]="formInputs[inputKey].placeholder"
                [formControlName]="inputKey"
              ></app-dropdown>
              <mc-select
                *ngIf="!formInputs[inputKey].isGroup"
                class="bo-form__input"
                filterPlaceholder="MTR_COMMON_SEARCH"
                [filter]="true"
                [options]="options"
                [placeholder]="formInputs[inputKey].placeholder"
                [formControlName]="inputKey"
              ></mc-select>
            </ng-container>
          </ng-container>
          <mc-input-switch
            *ngSwitchCase="InputType.Switch"
            [inputId]="inputId"
            [label]="formInputs[inputKey].switchLabels"
            [formControlName]="inputKey"
          ></mc-input-switch>
          <app-calendar
            *ngSwitchCase="InputType.Calendar"
            class="bo-form__input"
            [inputId]="inputId"
            [showClear]="false"
            [yearRange]="formInputs[inputKey].yearRange"
            [placeholder]="formInputs[inputKey].placeholder"
            [formControlName]="inputKey"
            [language]="language$ | async"
          ></app-calendar>
          <p-selectButton
            *ngSwitchCase="InputType.SwitchMultiple"
            class="bo-form__input"
            multiple="true"
            [id]="inputKey"
            [formControlName]="inputKey"
            (onOptionClick)="$event.option.checked = !$event.option.checked"
            [options]="formInputs[inputKey].options | async"
            #selectButtons
          >
            <ng-template let-item pTemplate>
              <mc-input-switch
                [label]="item.label"
                [(ngModel)]="item.checked"
                [ngModelOptions]="{ standalone: true }"
                [disabled]="!!(selectButtons.disabled || item.disabled)"
                [pTooltip]="
                  shouldDisableOption(item.value, data?.isAdmin)
                    ? ('MTR_COMMON_ONLY_ADMIN_EDIT' | translate)
                    : false
                "
              ></mc-input-switch>
            </ng-template>
          </p-selectButton>
          <span
            class="bo-form__input-error"
            *ngIf="
              userForm.get(inputKey).hasError('required') &&
              formInputs[inputKey].validators.includes(VALIDATORS.required)
            "
            translate="MTR_COMMON_ERROR_FIELD_REQUIRED"
          ></span>
          <span
            class="bo-form__input-error"
            *ngIf="
              userForm.get(inputKey).hasError('email') &&
              formInputs[inputKey].validators.includes(VALIDATORS.email)
            "
            translate="MTR_COMMON_ERROR_EMAIL_INVALID"
          ></span>
          <span
            class="bo-form__input-error"
            *ngIf="
              userForm.get(inputKey).hasError('atLeast') &&
              formInputs[inputKey].validators.includes(VALIDATORS.atLeastOne)
            "
            translate="MTR_COMMON_ERROR_AT_LEAST_ONE"
          ></span>
        </ng-container>
      </ng-container>
    </ng-template>
  </form>
</ng-container>
