import { Directive, HostListener, Inject, Input } from '@angular/core'
import { DOCUMENT } from '@angular/common'

import { ToastType } from '@core/components/toast/toast.models'
import { ToastService } from '@core/components/toast/toast.service'

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[copyToClipboard]',
})
export class CopyToClipboardDirective {
  @Input() copyToClipboard!: string

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private toastService: ToastService,
  ) {}

  @HostListener('click')
  private _copyOnClick(textToCopy = this.copyToClipboard) {
    if (textToCopy) {
      const selectionElement = this.document.createElement('input')
      selectionElement.classList.add('u-visually-hidden')
      selectionElement.value = textToCopy
      this.document.body.appendChild(selectionElement).select()
      const isCopySuccessful = this.document.execCommand('copy')
      this.toastService.showToast({
        type: isCopySuccessful ? ToastType.Success : ToastType.Error,
        message: `MTR_${isCopySuccessful ? 'SUCCESS' : 'ERROR'}_COPY_TO_CLIPBOARD`,
      })
      this.document.body.removeChild(selectionElement)
    }
  }

  copy(textToCopy: string) {
    this._copyOnClick(textToCopy)
  }
}
