import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { tap } from 'rxjs/operators'
import { Router } from '@angular/router'
import {
  changeLocation,
  navigate,
  navigateByUrl,
  navigateExternal,
  navigateToNewTab
} from '@core/ngrx-router/state/actions/ngrx-router.actions'
import { Location } from '@angular/common'
import { Store } from '@ngrx/store'


@Injectable()
export class NgrxRouterEffects {


  navigate$ = createEffect(() => this._actions$.pipe(
    ofType(navigate),
    tap(({path, relativeTo}) => this._router.navigate([path], { relativeTo }))
  ), { dispatch: false })


  navigateExternal$ = createEffect(() => this._actions$.pipe(
    ofType(navigateExternal),
    tap(({path}) => this._router.navigate(['/externalRedirect', { externalUrl: path, target: '_self' }]))
  ), { dispatch: false })

  navigateByUrl$ = createEffect(() => this._actions$.pipe(
    ofType(navigateByUrl),
    tap(({path}) => this._router.navigateByUrl(path))
  ), {dispatch: false})

  changeLocation$ = createEffect(() => this._actions$.pipe(
    ofType(changeLocation),
    tap(({path}) => this._location.go(path))
  ), {dispatch: false})

  navigateToNewTab$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(navigateToNewTab),
        tap(({path}) => window.open(path, '_blank')),
      ),
    { dispatch: false },
  )

  constructor(
    private readonly _actions$: Actions,
    private readonly _router: Router,
    private readonly _location: Location,
    private readonly _store: Store<unknown>
  ) {}
}
