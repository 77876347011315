import {
  IncidenceCameraTypology,
  IncidenceResolutionType,
  IncidenceType,
} from '@core/enums/incidence.enums'

export const INCIDENCE_TYPE_TRANSLATION: { [key in IncidenceType]: string } = {
  [IncidenceType.NotifyOk]: 'MTR_INCIDENCE_TYPE_NOTIFY_OK',
  [IncidenceType.NotifyTrackingUnavailable]: 'MTR_INCIDENCE_TYPE_NOTIFY_TRACKING_UNAVAILABLE',
  [IncidenceType.NotifyNoTrackingTech]: 'MTR_INCIDENCE_TYPE_NOTIFY_NO_TRACKING_TECH',
  [IncidenceType.NotifyNoTrackingNoPanaTech]: 'MTR_INCIDENCE_TYPE_NOTIFY_NO_TRACKING_NO_PANA_TECH',
  [IncidenceType.NotifyNoTrackingWeather]: 'MTR_INCIDENCE_TYPE_NOTIFY_NO_TRACKING_WEATHER',
  [IncidenceType.NotifyNoTrackingNoPanaWeather]:
    'MTR_INCIDENCE_TYPE_NOTIFY_NO_TRACKING_NO_PANA_WEATHER',
  [IncidenceType.NotifyNoTv]: 'MTR_INCIDENCE_TYPE_NOTIFY_NO_TV',
  [IncidenceType.NotifyNoTac]: 'MTR_INCIDENCE_TYPE_NOTIFY_NO_TAC',
  [IncidenceType.NotifyNoTacTracking]: 'MTR_INCIDENCE_TYPE_NOTIFY_NO_TAC_TRACKING',
  [IncidenceType.NotifyNoPan]: 'MTR_INCIDENCE_TYPE_NOTIFY_NO_PAN',
  [IncidenceType.NotifyNoWarmup]: 'MTR_INCIDENCE_TYPE_NOTIFY_NO_WARMUP',
  [IncidenceType.NotifyCustomMinutes]: 'MTR_INCIDENCE_TYPE_NOTIFY_CUSTOM_MINUTES',
  [IncidenceType.NotifyCustomText]: 'MTR_INCIDENCE_TYPE_NOTIFY_CUSTOM_TEXT',
  [IncidenceType.NotifyCalibration]: 'MTR_INCIDENCE_TYPE_NOTIFY_CALIBRATION',
}

export const INCIDENCE_RESOLUTION_TYPE_TRANSLATION: { [key in IncidenceResolutionType]: string } = {
  [IncidenceResolutionType.NotifyPanaRecovery]:
    'MTR_INCIDENCE_RESOLUTION_TYPE_NOTIFY_PANA_RECOVERY',
  [IncidenceResolutionType.NotifyTvRecovery]: 'MTR_INCIDENCE_RESOLUTION_TYPE_NOTIFY_TV_RECOVERY',
  [IncidenceResolutionType.NotifyTrackingRecovery]:
    'MTR_INCIDENCE_RESOLUTION_TYPE_NOTIFY_TRACKING_RECOVERY',
  [IncidenceResolutionType.NotifyAllRecovery]: 'MTR_INCIDENCE_RESOLUTION_TYPE_NOTIFY_ALL_RECOVERY',
}

export const INCIDENCE_CAMERA_TYPOLOGY_TRANSLATION: { [key in IncidenceCameraTypology]: string } = {
  [IncidenceCameraTypology.Tracking]: 'MTR_INCIDENCE_CAMERA_TYPOLOGY_TRACKING',
  [IncidenceCameraTypology.TV]: 'MTR_INCIDENCE_CAMERA_TYPOLOGY_TV',
  [IncidenceCameraTypology.All]: 'MTR_INCIDENCE_CAMERA_TYPOLOGY_ALL',
  [IncidenceCameraTypology.Pana]: 'MTR_INCIDENCE_CAMERA_TYPOLOGY_PANA',
  [IncidenceCameraTypology.Tac]: 'MTR_INCIDENCE_CAMERA_TYPOLOGY_TAC',
  [IncidenceCameraTypology.WarmUp]: 'MTR_INCIDENCE_CAMERA_TYPOLOGY_WARM_UP',
}

export const INCIDENCE_TYPES_ALLOWED_TO_EDIT: IncidenceType[] = [
  IncidenceType.NotifyCustomMinutes,
  IncidenceType.NotifyCustomText,
]
