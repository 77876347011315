import { MatchQualifier, Quality } from '@core/enums/matches.enums'

export const QUALITY_ICON_MAPPING = {
  [Quality.Completed]: 'check',
  [Quality.Reviewed]: 'double-check',
}

export const QUALITY_TYPE_TRANSLATION = {
  [Quality.Completed]: 'MTR_MATCHES_COMPLETED',
  [Quality.Reviewed]: 'MTR_MATCHES_REVIEWED',
  [MatchQualifier.Tracking]: 'MTR_MATCHES_NO_TRACKING',
  [MatchQualifier.Eventing]: 'MTR_MATCHES_NO_EVENTING',
}
