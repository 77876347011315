import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { fetchTenants, saveSelectedTenant, setSelectedTenant, setTenants } from '@core/state/actions/tenant.actions'
import { map, switchMap } from 'rxjs/operators'
import { TenantApi } from '@core/requests/api/tenant.api'

@Injectable()
export class TenantEffects{


  fetchTenants$ = createEffect(
    () => this._actions$.pipe(
      ofType(fetchTenants),
      switchMap(() => this._api.fetchTenants().pipe(
        map(list => setTenants({list}))
      ))
    )
  )

  setSelectedTenant$ = createEffect(
    () => this._actions$.pipe(
      ofType(setSelectedTenant),
      map(({selected}) => saveSelectedTenant({selected}))
    )
  )

  constructor(private readonly _actions$: Actions, private readonly _api: TenantApi) {

  }
}
