<form class="bo-sales-form" [formGroup]="form" *ngIf="form && formInputs">
  <div class="u-margin-bottom--md p-grid p-align-center">
    <ng-container
      *ngTemplateOutlet="
        formInput;
        context: { inputKey: 'customerId', col: { label: 3, input: 5 } }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="formInput; context: { inputKey: 'isActive', col: { label: 2, input: 2 } }"
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        formInput;
        context: { inputKey: 'marketablePackageId', col: { label: 3, input: 9 } }
      "
    ></ng-container>

    <div class="u-separator"></div>

    <ng-container
      *ngTemplateOutlet="formInput; context: { inputKey: 'id', col: { label: 3, input: 8 } }"
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="formInput; context: { inputKey: 'saleDate', col: { label: 3, input: 8 } }"
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        formInput;
        context: { inputKey: 'expirationTime', col: { label: 3, input: 8 } }
      "
    ></ng-container>
  </div>
  <div class="u-margin-bottom--ml p-grid">
    <ng-container
      *ngTemplateOutlet="formInput; context: { inputKey: 'comment', col: { label: 3, input: 8 } }"
    ></ng-container>

    <div class="u-separator"></div>
  </div>

  <ng-template #formInput let-inputKey="inputKey" let-col="col">
    <ng-container
      [ngSwitch]="formInputs[inputKey].type"
      *ngIf="
        formInputs[inputKey] &&
        (!formInputs[inputKey].hideFn ||
          (formInputs[inputKey].hideFn && !formInputs[inputKey].hideFn(mode)))
      "
    >
      <div class="p-col-6 m-body__accent" [ngClass]="'p-lg-' + col.label">
        <span
          class="m-body__accent"
          [class.bo-form__input--required]="
            isEdit && formInputs[inputKey].validators?.includes(VALIDATORS.required)
          "
          >{{ formInputs[inputKey].label | translate }}</span
        >
      </div>
      <div class="p-col-6 m-field__container" [ngClass]="'p-lg-' + col.input">
        <input
          pInputText
          class="bo-form__input"
          [formControlName]="inputKey"
          *ngSwitchCase="InputType.Text"
        />
        <textarea
          pInputText
          class="bo-form__input bo-form__textarea"
          [formControlName]="inputKey"
          *ngSwitchCase="InputType.Textarea"
        ></textarea>
        <mc-select
          *ngSwitchCase="InputType.Dropdown"
          class="bo-form__input"
          filterPlaceholder="MTR_COMMON_SEARCH"
          [filter]="true"
          [options]="formInputs[inputKey].options | async"
          [placeholder]="formInputs[inputKey].placeholder"
          [formControlName]="inputKey"
        ></mc-select>
        <mc-input-switch
          *ngSwitchCase="InputType.Switch"
          class="bo-form__input"
          [name]="inputKey"
          [label]="formInputs[inputKey].switchLabels"
          [formControlName]="inputKey"
        ></mc-input-switch>
        <app-calendar
          *ngSwitchCase="InputType.Calendar"
          class="bo-form__input bo-form__input--inline"
          [name]="inputKey"
          [showClear]="false"
          [placeholder]="formInputs[inputKey].placeholder"
          [formControlName]="inputKey"
          [language]="language$ | async"
          [minDate]="formInputs[inputKey].minDate"
        ></app-calendar>
        <app-autocomplete
          *ngSwitchCase="InputType.Autocomplete"
          class="bo-form__input"
          [name]="inputKey"
          [placeholder]="formInputs[inputKey].placeholder | translate"
          [field]="formInputs[inputKey].shownField"
          [group]="formInputs[inputKey].isGroup"
          [lastQueryGroup]="formInputs[inputKey].lastQueryGroup"
          [formControlName]="inputKey"
          [suggestions]="formInputs[inputKey].options$ | async"
          (completeMethod)="formInputs[inputKey].getOptionsOnInput($event.query)"
        ></app-autocomplete>
        <span
          class="bo-form__input-error"
          *ngIf="
            form.get(inputKey).hasError('required') &&
            formInputs[inputKey].validators.includes(VALIDATORS.required)
          "
        >
          {{ 'MTR_COMMON_ERROR_FIELD_REQUIRED' | translate }}
        </span>
        <span
          class="bo-form__input-error"
          *ngIf="
            form.get(inputKey).hasError('email') &&
            formInputs[inputKey].validators.includes(VALIDATORS.email)
          "
        >
          {{ 'MTR_COMMON_ERROR_EMAIL_INVALID' | translate }}
        </span>
        <span
          class="bo-form__input-error"
          *ngIf="
            form.get(inputKey).hasError('atLeast') &&
            formInputs[inputKey].validators.includes(VALIDATORS.atLeastOne)
          "
        >
          {{ 'MTR_COMMON_ERROR_AT_LEAST_ONE' | translate }}
        </span>
      </div>
    </ng-container>
  </ng-template>
</form>
<ng-template [ngIf]="data.package">
  <app-heading
    extraClasses="m-heading--md u-margin-bottom--lg"
    titleText="MTR_COMMON_MARKETABLE_PACKAGE_SUMMARY"
  ></app-heading>
  <app-table [tableData]="[data.package]" [showResultCount]="false" [columns]="columnSettings">
  </app-table>
</ng-template>
