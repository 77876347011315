import { Injectable } from '@angular/core'
import { HttpClient, HttpContext } from '@angular/common/http'
import { catchError, map, share, shareReplay, tap } from 'rxjs/operators'
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs'

import { environment } from '@env'
import { FormMode } from '@core/enums/form-mode.enums'
import { LazyParams } from '@shared/components/table/table.models'
import { SortingService } from '@core/services/sorting/sorting.service'
import { FilterOption } from '@shared/components/filters/filters.models'
import { SortingDirection } from '@core/services/sorting/sorting.models'

import { User } from './components/users/users.models'
import { Customer } from './components/customers/customers.models'
import { getProfileTypeTranslation } from './components/users/users.utils'
import { PermissionManagementService } from '../permission-management/permission-management.service'
import { logError } from '@shared/opretators/log-error.operator'
import { isBase64 } from '@core/services/file/file.utils'

@Injectable({
  providedIn: 'root',
})
export class UserCustomerViewService {
  profileTypeOptionsSubject$ = new BehaviorSubject<FilterOption[]>(null)
  profileTypeOptions$: Observable<FilterOption[]>
  customerOptionsSubject$ = new BehaviorSubject<FilterOption[]>(null)
  customerOptions$: Observable<FilterOption[]>
  positionOptions$: Observable<FilterOption[]>

  newCustomer$ = new Subject<void>()

  constructor(
    private http: HttpClient,
    private sortingService: SortingService,
    private permissionManagementService: PermissionManagementService,
  ) {}

  getUsers$(params = {}, lazyParams: LazyParams = <LazyParams>{}) {
    const paramsBackEnd = {
      filtering: {
        freeText: '',
        filterQuality: 'WithProfiles',
        ...params,
      },
      ...lazyParams,
    }
    return this.http.post<any>(environment.API.SEARCH_USERS, paramsBackEnd).pipe(
      map((data) => ({
        ...data,
        sorting: {
          ...data.sorting,
          ...lazyParams.sorting,
        },
        users: this.sortingService.sort(
          data.users.map((user) => ({
            ...user,
            ...(paramsBackEnd.filtering.filterQuality === 'WithProfiles'
              ? {
                  customerName: user.customer.name,
                  ...user.profiles.reduce((obj, { id }) => ({ ...obj, [id]: true }), {}),
                }
              : {}),
          })),
        ),
      })),
      share(),
      logError(),
    )
  }

  getUsersById(ids: string[], requestContext?: HttpContext) {
    return this.http
      .get<any>(environment.API.USERS, {
        params: { ids },
        context: requestContext,
      })
      .pipe(
        map((users) =>
          users.map((user) => ({
            ...user,
            photoPath: user.userAvatar && user.userAvatar.avatarPath,
            customerId: user.customer.id,
            tenantId: user.customer.calculatedTenantId,
            profileIds: user.profiles.map(({ id }) => id),
          })),
        ),
        share(),
        logError(),
      )
  }

  getUserById(id?: string, requestContext?: HttpContext) {
    return this.getUsersById(id ? [id] : [], requestContext).pipe(map((res) => res[0]))
  }

  updateUser$(user: User) {
    user = {
      ...user,
      userAvatar: {
        ...user.userAvatar,
        encodedImage: isBase64(user.photoPath) ? user.photoPath : null,
      },
      photoPath: undefined,
    }
    return this.http.put<any>(environment.API.USERS, [user])
  }

  getUserProfileTypes$() {
    return this.http.get<any>(environment.API.USER_PROFILE_TYPES).pipe(share(), logError())
  }

  getUserProfileTypeOptions$(forceFetch?: boolean) {
    if (this.profileTypeOptionsSubject$.value && !forceFetch) {
      return of(this.profileTypeOptionsSubject$.value)
    } else {
      return !forceFetch && this.profileTypeOptions$
        ? this.profileTypeOptions$
        : (this.profileTypeOptions$ = this.getUserProfileTypes$().pipe(
            map((data) =>
              data.map(({ id, name }) => ({ label: getProfileTypeTranslation(name), value: id })),
            ),
            tap((data: FilterOption[]) => this.profileTypeOptionsSubject$.next(data)),
          ))
    }
  }

  getUsersByCustomer$(customerId: string, lazyParams: LazyParams = <LazyParams>{}) {
    const filterData = {
      filterQuality: 'raw',
      isActive: true,
      customerIds: [customerId],
    }
    return this.getUsers$(filterData, {
      sorting: {
        column: 'activePeriodEnd',
        direction: SortingDirection.Asc,
      },
      ...lazyParams,
    })
  }

  deactivateUsersById(ids: string[]) {
    const paramsBackEnd = {
      userIds: ids,
      active: false,
    }
    return this.http.post<any>(environment.API.USERS_ACTIVATIONS, paramsBackEnd)
  }

  renewUsersById(id: string[], renewalDate) {
    const paramsBackEnd = {
      userIds: id,
      activePeriodEnd: renewalDate,
    }
    return this.http.post<any>(environment.API.USERS_LICENSES, paramsBackEnd)
  }

  getCustomers$(params = {}, lazyParams: LazyParams = <LazyParams>{}) {
    const paramsBackEnd = {
      filtering: {
        filterQuality: 'withPrincipalCustomer',
        ...params,
      },
      ...lazyParams,
    }

    return this.http.post<any>(environment.API.SEARCH_CUSTOMERS, paramsBackEnd).pipe(
      map((data) => ({
        ...data,
        sorting: {
          ...data.sorting,
          ...lazyParams.sorting,
        },
        customers: this.sortingService.sort(
          data.customers.map((customer) => ({
            ...customer,
            principalId: (customer.principal || {}).id,
            principalIdReadOnly: (customer.principal || {}).id,
            mainCustomer: (customer.principal || {}).name,
          })),
        ),
      })),
      share(),
      logError(),
    )
  }

  getCustomerOptions$(forceFetch?: boolean) {
    if (this.customerOptionsSubject$.value && !forceFetch) {
      return of(this.customerOptionsSubject$.value)
    } else {
      return !forceFetch && this.customerOptions$
        ? this.customerOptions$
        : (this.customerOptions$ = this.getCustomers$({ filterQuality: 'raw' }).pipe(
            map((data) =>
              data.customers
                .map(({ id, name }) => ({ label: name, value: id }))
                .sort((a, b) => {
                  const labels = [a.label.toUpperCase(), b.label.toUpperCase()]
                  const checkLabels = labels[0] > labels[1] ? 1 : 0
                  return labels[0] < labels[1] ? -1 : checkLabels
                }),
            ),
            tap((data: FilterOption[]) => this.customerOptionsSubject$.next(data)),
          ))
    }
  }

  getLanguageOptions$() {
    return of(
      Object.values(environment.I18N_LANGS).map(({ locale, i18nLabel }) => ({
        label: i18nLabel,
        value: locale,
      })),
    )
  }

  getPositionOptions$(forceFetch?: boolean) {
    return this.positionOptions$ && !forceFetch
      ? this.positionOptions$
      : (this.positionOptions$ = this.http
          .get<{ id: string; name: string }[]>(environment.API.USER_POSITIONS)
          .pipe(
            map((positions) => positions.map(({ id, name }) => ({ label: name, value: id }))),
            shareReplay(1),
          ))
  }

  newUpdateCustomer(customer, type = FormMode.Edit) {
    const isEdit = type === FormMode.Edit
    return this.http[isEdit ? 'put' : 'post'](environment.API.CRUD_CUSTOMER, customer).pipe(
      map((customerItem: Customer) => ({
        ...customerItem,
        principalIdReadOnly: customerItem.principalId,
      })),
    )
  }

  getRequesterUserOptions$(params = {}, lazyParams: LazyParams = <LazyParams>{}) {
    return this.getUsers$(params, lazyParams).pipe(
      map(({ users }) => {
        return users.map((user) => ({
          ...user,
          requesterUser: { requesterName: user.fullName, requesterEmail: user.email },
          shownValue: `${user.fullName} (${user.email}) | ${user.customerName}`,
        }))
      }),
      catchError(() => of([])),
    )
  }

  getCategoryGroupOptions$() {
    return this.permissionManagementService.getCategoryGroupOptions$()
  }

  revokePassword(userIds: string[]): Observable<any> {
    return this.http.post(environment.API.USER_PASSWORD_EXPIRATION, { userIds }).pipe(
      catchError((err: any) => {
        console.log(err)
        return throwError(() => new Error(err))
      }),
    )
  }

  resendWelcomeEmail(userIds: string[]): Observable<any> {
    return this.http.post(environment.API.USER_RESEND_WELCOME_EMAIL, { userIds }).pipe(
      catchError((err: any) => {
        console.log(err)
        return throwError(() => new Error(err))
      }),
    )
  }

  deleteUser(userId: string): Observable<any> {
    return this.http.delete(`${environment.API.USERS}/${userId}`)
  }
}
