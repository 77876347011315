import {
  HttpContext,
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { tap } from 'rxjs/operators'
import * as hash from 'object-hash'
import {
  HASH_OPTIONS,
  MAX_CACHE_AGE,
  TENANT_HEADER_NAME,
} from '@core/requests/constants/requests.constants'

const CACHE_IT = new HttpContextToken<boolean>(() => true)
export const unCacheIt = () => new HttpContext().set(CACHE_IT, false)

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  private cache: Map<string, { response: HttpResponse<any>; entryTime: number }> = new Map()

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.method !== 'GET' || !req.context.get(CACHE_IT)) {
      return next.handle(req)
    }

    const key = this._httpToKey(req)
    const cachedItem = this.cache.get(key)
    const isExpired = cachedItem ? Date.now() - cachedItem.entryTime > MAX_CACHE_AGE : true
    if (cachedItem && !isExpired) {
      return of(cachedItem.response.clone())
    } else if (cachedItem && isExpired) {
      this.cache.delete(key)
    }
    return next.handle(req).pipe(
      tap((stateEvent) => {
        if (stateEvent instanceof HttpResponse) {
          this.cache.set(key, {
            response: stateEvent.clone(),
            entryTime: Date.now(),
          })
        }
      }),
    )
  }

  private _httpToKey(request: HttpRequest<any>): string {
    return [
      request.method, // Just caching GET requests for now, but who knows...
      request.urlWithParams,
      request.headers.get(TENANT_HEADER_NAME),
      hash(request.params, HASH_OPTIONS),
      hash(request.body, HASH_OPTIONS),
    ].join('@')
  }
}
