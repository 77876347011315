import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core'

import { BehaviorSubject } from 'rxjs'
import { debounceTime, filter, tap } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

import { MultiSelect } from 'primeng/multiselect'
import { TranslatorService } from '@mediacoach-ui-library/global'

import { environment } from '@env'
import { cloneJSON, uniqueId } from '@core/utils/main'

import { FilterType } from './filters.enums'
import { FilterSetting } from './filters.models'
import { FILTER_TYPE_WITH_OPTIONS } from './filters.constants'
import { FILTER_SEARCH_MINIMUM } from '../dropdown/dropdown.component'
import { AutocompleteComponent } from '@shared/components/autocomplete/autocomplete.component'

@UntilDestroy()
@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersComponent implements OnInit, OnDestroy {
  private _isFirstLoad = true
  private _innerFilterData = {}
  private _submitFilters$ = new BehaviorSubject(null)
  private _filterSettings: FilterSetting[]
  private _filterSettingObj: { [key: string]: FilterSetting }

  FilterType = FilterType

  language$: BehaviorSubject<string>
  filterData = {}
  innerFilterDataLength = {}
  uniqueIdPrefix = uniqueId()

  get filterSettings(): FilterSetting[] {
    return this._filterSettings
  }
  @Input() set filterSettings(_filterSettings: FilterSetting[]) {
    this._filterSettings = _filterSettings || []
    this._filterSettingObj = this._getFilterSettingObj()
    this._checkAsyncFilters()
    this.resetFilters()
  }

  @Input() showSearch = true
  @Input() showClear = true
  @Input() cleanButtonQaId: string
  @Input() searchButtonQaId: string
  @Input() styleClass: string
  @Output() sendFilters = new EventEmitter()

  @ViewChildren(AutocompleteComponent) autocompleteComponents: QueryList<AutocompleteComponent>

  constructor(private translatorService: TranslatorService) {
    this.language$ = this.translatorService.onLangChange
  }

  // Used for _filterSettingObj to have a dictionary to the filterSetting reference
  private _addFilterSettingProps(filterSetting: any, obj: any) {
    Object.entries(obj).forEach(([key, value]) => (filterSetting[key] = value))
    return filterSetting
  }

  private _getFilterSettingObj(filterSettings = this.filterSettings, parentId?: string) {
    return (filterSettings || []).reduce(
      (obj, filterSetting) => ({
        ...obj,
        ...{
          [filterSetting.id]: this._addFilterSettingProps(
            filterSetting,
            parentId ? { parentId } : {},
          ),
        },
      }),
      {},
    )
  }

  private _checkAsyncFilters() {
    Object.values(this._filterSettingObj) // We use filterSettingObj because it has the unwrapped multi-filter filters
      .filter(
        (filterSetting) =>
          FILTER_TYPE_WITH_OPTIONS.includes(filterSetting.type) && !filterSetting.options,
      )
      .forEach((filterSetting) => (filterSetting.shouldResolve = true))
  }

  private _setAsyncDefaultValueAndOptions = (filterKey: string, options: any[]) => {
    this._filterSettingObj[filterKey].options = options

    const { options$, type } = this._filterSettingObj[filterKey]

    const defaultValues = (options || [])
      .filter(({ isDefaultValue }) => isDefaultValue)
      .map((option) => option.value)
    if (defaultValues.length) {
      this._filterSettingObj[filterKey].defaultValue =
        type === FilterType.MultiSelect ? defaultValues : defaultValues[0]
    }

    let selectedValues = this.filterData[filterKey]

    if (!options$) {
      this._setFilter(filterKey)
    } else if (options) {
      const isArrayFilter = Array.isArray(selectedValues)
      selectedValues = isArrayFilter ? selectedValues : [selectedValues]
      const possibleSelectedValues = selectedValues.filter((selectedValue: any) =>
        options.find((option: any) => option.value === selectedValue),
      )

      if (possibleSelectedValues.length > 0) {
        this._setFilter(
          filterKey,
          isArrayFilter ? possibleSelectedValues : possibleSelectedValues[0],
        )
      } else {
        this._setFilter(filterKey)
      }
    }
  }

  private _setFilter(filterKey: string, selectedValue?: any) {
    this.filterData[filterKey] =
      selectedValue != null ? selectedValue : this._filterSettingObj[filterKey]?.defaultValue
  }

  ngOnInit() {
    this._submitFilters$
      .pipe(
        debounceTime(environment.DEBOUNCE_TIME.INPUT_CHANGES),
        filter(() =>
          this.filterSettings.every(
            (filterSetting) =>
              !filterSetting.shouldResolve ||
              (filterSetting.shouldResolve && !!filterSetting.options),
          ),
        ),
        tap(() => {
          const filterData = cloneJSON(this.filterData)
          Object.entries(this.filterData).forEach(([filterKey, filterValue]) => {
            const { isArray, selectedField } = this._filterSettingObj[filterKey] || {}
            if (selectedField) {
              filterData[filterKey] = (filterData[filterKey] || {})[selectedField]
            }
            if (isArray && !Array.isArray(filterValue)) {
              filterData[filterKey] = filterData[filterKey] != null ? [filterData[filterKey]] : []
            }
          })
          this._isFirstLoad = false
          this.sendFilters.emit(filterData)
        }),
        untilDestroyed(this),
      )
      .subscribe()
  }

  ngOnDestroy() {
    // This is intentional
  }

  setAsyncOptions(filterKey: string, _options: any[]) {
    const { shouldResolve, options, onChange } = (this._filterSettingObj || {})[filterKey] || {}
    if (shouldResolve && options !== _options) {
      this._setAsyncDefaultValueAndOptions(filterKey, _options)
      if (!onChange && this._isFirstLoad) {
        this.submitFilters()
      }
    }
    return _options
  }

  onChange(id: string) {
    const filterSetting = (this._filterSettingObj || {})[id]
    if (filterSetting) {
      if (filterSetting.onChange) {
        filterSetting.onChange(this.filterData)
      }
      const parentId = filterSetting.parentId
      if (parentId) {
        this._innerFilterData[parentId] = {
          ...(this._innerFilterData[parentId] || {}),
          [id]: this.filterData[id],
        }
        this.innerFilterDataLength[parentId] = Object.values(
          this._innerFilterData[parentId],
        ).reduce((acc: number, value) => acc + (value != null ? 1 : 0), 0)
      }
      if (this._isFirstLoad || filterSetting.autoSubmit) {
        this.submitFilters()
      }
    }
  }

  shouldFilter(options: any[]) {
    return (options || []).length >= FILTER_SEARCH_MINIMUM
  }

  resetFilters(shouldSubmit = false) {
    this.filterSettings.forEach((filterSetting) => {
      this._setFilter(filterSetting.id)
      this.onChange(filterSetting.id)
    })
    if (shouldSubmit) {
      this.submitFilters()
    }
    this.autocompleteComponents?.forEach((c) => c.cleanLastQueryLocalStorageKey())
  }

  submitFilters() {
    this._submitFilters$.next(null)
  }

  // FIXME this goes into multiselect
  uncheckAll(event: any, multiselect: MultiSelect) {
    event.stopPropagation()
    if (!multiselect.allSelected()) {
      multiselect.onToggleAll(event)
    }
    multiselect.onToggleAll(event)
  }
}
