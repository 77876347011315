export enum UserProfileType {
  DesktopUserProfile = 'DesktopUserProfile',
  LiveUserProfile = 'LiveUserProfile',
  PortalUserProfile = 'PortalUserProfile',
  BackOfficeUserProfile = 'BackOfficeUserProfile',
  WimuUserProfile = 'WimuUserProfile',
  FootovisionUserProfile = 'FootovisionUserProfile',
  LicenserUserProfile = 'LicenserUserProfile',
  MobileUserProfile = 'MobileUserProfile',
}

export enum ActiveAlertType {
  Expired = 'Expired',
  LessThan30 = 'LessThan30DaysToExpired',
  None = 'None',
}
