import { Observable } from 'rxjs'
import { Component } from '@angular/core'
import { tap } from 'rxjs/operators'
import { DialogType } from '@shared/components/dialog-application/dialog-application.enums'
import { UntypedFormControl } from '@angular/forms'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { DropdownItem } from '@shared/components/dropdown/dropdown.models'
import { UserProfile } from '@core/authentication/auth/models/auth.models'
import { Store } from '@ngrx/store'
import {
  getFormattedProfile,
  getUserProfiles,
  isLoggedIn,
} from '@core/state/selectors/profile.selectors'
import { getSelectedTenant, getTenantsAsDropdownItems } from '@core/state/selectors/tenant.selector'
import { setSelectedTenant } from '@core/state/actions/tenant.actions'
import { APPLICATION_CONFIG } from '@core/components/header/constants/header.constants'
import { TranslateService } from '@ngx-translate/core'
import { ApplicationUserProfileType } from '@mediacoach/ui'

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  private _firstRun: boolean = true

  readonly appConfig = APPLICATION_CONFIG
  readonly applicationDialogType = DialogType

  readonly isLoggedIn$: Observable<boolean> = this._store.select(isLoggedIn)
  readonly profile$: Observable<UserProfile> = this._store.select(getFormattedProfile)
  readonly applications$: Observable<ApplicationUserProfileType[]> =
    this._store.select(getUserProfiles)
  readonly tenants$: Observable<DropdownItem[]> = this._store.select(getTenantsAsDropdownItems)
  readonly selectedTenant$: Observable<string> = this._store.select(getSelectedTenant).pipe(
    tap((tenant) => {
      if (this._firstRun && !!tenant) {
        this._firstRun = false
        this.tenantCtrl.setValue(tenant, { emitEvent: false, onlySelf: true })
      }
    }),
  )

  tenantCtrl: UntypedFormControl = new UntypedFormControl()

  constructor(
    private readonly _store: Store<unknown>,
    private readonly _translate: TranslateService,
  ) {
    this.tenantCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((selected) => this._store.dispatch(setSelectedTenant({ selected })))
  }
}
