export const bytesToSize = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) {
    return '0 Byte'
  }
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i]
}

export const blobTo = (blob, callback, type) => {
  const reader = new FileReader()
  reader.onload = () => {
    callback(reader.result)
  }
  reader[type](blob)
}
