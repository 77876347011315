import { AuthPage } from '@core/authentication/auth/enums/auth.enums'

export enum ERROR_CODES {
  Forbidden = 403,
  NotFound = 404,
}

export const PAGE_MAPPING = {
  [AuthPage.NotPermitted]: {
    title: ERROR_CODES.Forbidden.toString(),
    subTitle: 'MTR_SUBTITLE_FORBIDDEN',
  },
  default: {
    title: ERROR_CODES.NotFound.toString(),
    subTitle: 'MTR_SUBTITLE_NOT_FOUND',
  },
}
