import { ApplicationName, DeviceOs } from '@mediacoach-ui-library/global'

import { environment } from '@env'

export const IMAGE_BASE_PATH = environment.STATICS_URL_ASSETS + 'img/applications/'
export const IMAGE_BASE_CLASS = 'c-dialog-application__img-'

const PORTABLE_DEVICE_INSTALLER_CONFIG = [
  {
    os: DeviceOs.Ios,
    type: 'primary',
    extension: 'IOS',
  },
  {
    os: DeviceOs.Android,
    type: 'primary',
    extension: 'APK',
  },
]

export const INSTALLER_CONFIG_BY_APPLICATION = {
  [ApplicationName.Desktop]: [
    {
      os: DeviceOs.Windows,
      type: 'primary',
      extension: 'EXE',
    },
    {
      os: DeviceOs.Macos,
      type: 'primary',
      extension: 'DMG',
    },
  ],
  [ApplicationName.Mobile]: PORTABLE_DEVICE_INSTALLER_CONFIG,
  [ApplicationName.LivePro]: PORTABLE_DEVICE_INSTALLER_CONFIG,
}

export const SHOW_OS_ICON_APPLICATIONS = [
  ApplicationName.Mobile,
  ApplicationName.Desktop,
  ApplicationName.LivePro,
]
