interface INavigator extends Navigator {
  msSaveOrOpenBlob: (blob: Blob, name: string) => void
}

export function IESaveOrOpenBlob(blob: Blob, name: string): boolean {
  const navigator = window.navigator as INavigator
  if (navigator && navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blob, name)
    return true
  } else {
    return false
  }
}

export function downloadFile(fileURL: string, name: string) {
  const link = document.createElement('a')
  link.href = fileURL
  link.download = name
  window.document.body.appendChild(link)
  link.click()
  window.document.body.removeChild(link)
  const hundredth = 100
  // For Firefox it is necessary to delay revoking the ObjectURL
  setTimeout(() => window.URL.revokeObjectURL(fileURL), hundredth)
}

export function openFile(fileURL: string, name: string) {
  window.open(fileURL, name)
}

export function b64toBlob(b64Data: string, contentType: string, sliceSize: number): Blob {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, { type: contentType })
}

export function isBase64(str: string): boolean {
  return str?.includes('base64');
}
