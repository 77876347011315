<div class="bo-notifications">
  <div class="bo-notifications__wrapper" (click)="dropdown.toggle($event)">
    <app-label-counter
      *ngIf="(notificationItems?.length || 0) !== 0"
      [countNum]="notificationItems?.length"
    ></app-label-counter>
    <i class="u-icon u-icon--notifications" aria-hidden="true"></i>
  </div>
  <p-menu [popup]="true" [model]="notificationItems" appendTo="body" #dropdown></p-menu>
</div>
