import { DateType } from './table.enums'
import { Locale } from '@mediacoach-ui-library/global'

export const DATE_TYPE_LOCALE = {
  [Locale.Es]: {
    [DateType.Date]: 'dd/MM/yyyy',
    [DateType.DateTime]: 'dd/MM/yyyy (HH:mm)',
  },
  [Locale.En]: {
    [DateType.Date]: 'dd/MM/yyyy',
    [DateType.DateTime]: 'dd/MM/yyyy (HH:mm)',
  },
}
