import { FormMode } from '../enums/form-mode.enums'
import { ToastType } from '../components/toast/toast.models'
import { filter, tap } from 'rxjs/operators'

export const formModeFn = (formComponent, _formMode) => {
  formComponent.formChangesObj = null
  formComponent.isFormEditable = _formMode !== FormMode.Read
  formComponent._formMode = _formMode
}

export const newItem = (formComponent) => {
  formComponent.selectionChange()
  formComponent.formMode = FormMode.New
  formComponent.selectedItem = {}
  formComponent.overlay.open()
}

export const selectionChange$ = (formComponent, item) => {
  return formComponent.overlay.confirmClose$(!item).pipe(
    filter((isCloseConfirmed) => !!isCloseConfirmed),
    tap(() => {
      formComponent.overlay[item ? 'open' : 'close']()
      formComponent.selectedItem = item || {}
      formComponent.toggleEdit(item ? FormMode.Read : FormMode.New)
    }),
  )
}
export const onConfirmClose = (formComponent, isCloseConfirmed) => {
  if (!isCloseConfirmed) {
    if (formComponent.table) {
      formComponent.table.selection = formComponent.selectedItem
    }
  } else {
    formComponent.toggleEdit(FormMode.Read)
  }
  return isCloseConfirmed
}

export const onLazyLoad = (formComponent, lazyParams) => {
  formComponent._getItems(lazyParams.filtering, {
    paging: lazyParams.paging,
    sorting: lazyParams.sorting,
  })
}

export const onOverlayClose = (formComponent) => {
  formComponent.selectedItem = {}
}

export const toggleEdit = (formComponent, formMode: FormMode, formChangesObj = {}) => {
  if (formMode) {
    formComponent.formMode = formMode
  } else if (formComponent.formMode === FormMode.New) {
    formComponent.overlay.close()
  } else if (formComponent.formMode === FormMode.Edit) {
    formComponent.formMode = FormMode.Read
  }
  formComponent.selectedItem = { ...formComponent.selectedItem, ...formChangesObj }
}

export const onCloseCancelClick = (formComponent) => {
  if (formComponent.formMode !== FormMode.Edit) {
    formComponent.overlay.close()
  } else {
    formComponent.overlay.confirmMessage = null
    formComponent.selectionChange(formComponent.selectedItem)
  }
}

export const onSaveEditClick = (formComponent) => {
  if (formComponent.formMode === FormMode.Read) {
    formComponent.formMode = FormMode.Edit
  } else {
    if (formComponent.confirmModal) {
      formComponent.confirmModal.open()
    } else {
      formComponent.save()
    }
  }
  return formComponent.formMode
}

export const save = (formComponent, save$, successMessage) => {
  if (formComponent.confirmModal) {
    formComponent.confirmModal.close()
  }
  if (formComponent.formChangesObj) {
    save$.subscribe(() => {
      formComponent.toastService.showToast({
        type: ToastType.Success,
        message: successMessage,
      })
      if (formComponent.table) {
        formComponent.table.reload()
      }
      formComponent.toggleEdit(FormMode.Read, formComponent.formChangesObj)
      formComponent.ref.detectChanges()
      formComponent.overlay.close()
    })
  }
}
