import { getDate } from '@core/utils/main'
import { ColumnType } from '@shared/components/table/table.enums'
import { ColumnSetting } from '@shared/components/table/table.models'
import { FilterType } from '@shared/components/filters/filters.enums'
import { FilterSetting } from '@shared/components/filters/filters.models'
import { SelectionMode } from '@shared/components/calendar/calendar.enums'

export const COLUMN_SETTINGS: ColumnSetting[] = [
  { field: 'fullName', translateHeader: 'MTR_COMMON_NAME' },
  { field: 'email', translateHeader: 'MTR_COMMON_EMAIL' },
  { field: 'customerName', translateHeader: 'MTR_COMMON_CUSTOMER', sortField: 'customer' },
  {
    field: 'active',
    translateHeader: 'MTR_COMMON_STATUS',
    sortField: 'status',
    type: ColumnType.Custom,
  },
  { field: 'activePeriodEnd', translateHeader: 'MTR_COMMON_LICENSE_EXP', type: ColumnType.Date },
]
export const FILTER_SETTINGS: FilterSetting[] = [
  {
    id: 'freeText',
    type: FilterType.Text,
    label: 'MTR_COMMON_SEARCH',
    placeholder: 'MTR_SEARCH_FREETEXT',
  },
  {
    id: 'customerIds',
    type: FilterType.Dropdown,
    isArray: true,
    label: 'MTR_COMMON_CUSTOMER',
    placeholder: 'MTR_COMMON_CUSTOMER_SELECT',
  },
  {
    id: 'userProfileIds',
    type: FilterType.MultiSelect,
    label: 'MTR_COMMON_PRODUCTS',
    placeholder: 'MTR_COMMON_PRODUCTS_SELECT',
    maxLabelsAllowed: 3,
  },
  {
    id: 'isActive',
    type: FilterType.Dropdown,
    label: 'MTR_COMMON_STATUS',
    placeholder: 'MTR_COMMON_STATUS_SELECT',
    options: [
      { label: 'MTR_COMMON_ALL', value: null },
      { label: 'MTR_COMMON_ACTIVE', value: true },
      { label: 'MTR_COMMON_DEACTIVATED', value: false },
    ],
  },
  {
    id: 'activePeriod',
    type: FilterType.Date,
    selectionMode: SelectionMode.Range,
    label: 'MTR_COMMON_LICENSE_EXP',
    placeholder: 'MTR_COMMON_LICENSE_EXP_SELECT',
  },
  {
    id: 'isPrincipalCustomerContact',
    type: FilterType.Dropdown,
    label: 'MTR_COMMON_MAIN_CUSTOMER',
    placeholder: 'MTR_COMMON_MAIN_CUSTOMER_SELECT',
    options: [
      { label: 'MTR_COMMON_ALL', value: null },
      { label: 'MTR_COMMON_YES', value: true },
      { label: 'MTR_COMMON_NO', value: false },
    ],
  },
  {
    id: 'positionId',
    type: FilterType.Dropdown,
    label: 'MTR_COMMON_POSITION',
    placeholder: 'MTR_COMMON_POSITION_SELECT',
  },
]

export const RENEWAL_DEADLINE_OFFSET = 30
export const RENEWAL_DEADLINE = getDate({ day: 1, month: 6 })
export const RENEAWAL_DURATION_YEAR = 1

export const PROFILE_TYPE_TRANSLATION = {
  Footovision: 'Vision',
}
