import { Store } from '@ngrx/store'
import { logOut, signInRedirect } from '@core/state/actions/oidc.actions'
import { RouterState } from '@ngrx/router-store'
import { purgeUserProfile } from '@core/state/actions/profile.actions'
import { OidcService } from '@core/authentication/oidc/services/oidc.service'
import { ProfileState } from '@core/state/models/profile.state'
import { startupApplication } from '@core/state/actions/startup.actions'
import { navigateExternal } from '@core/ngrx-router/state/actions/ngrx-router.actions'
import { environment } from '@env'

const log = (canLog: boolean, message: string, color = '#ff0097') => {
  if (canLog) {
    console.log(`%c${message}`, `color: ${color}`)
  }
}

export const idsFactory = (_oidc: OidcService, _store: Store<ProfileState | RouterState>) => () => {
  const canLog = _oidc.debug
  _oidc.manager.events.addUserSignedOut(() => {
    log(canLog, '[OIDC] Event: addUserSignedOut >> Dispatches: logOut()')
    _store.dispatch(logOut())
  })
  _oidc.manager.events.addUserLoaded((oidc) => {
    log(canLog, '[OIDC] Event: addUserLoaded >> Dispatches: startupApplication({oidc})')
    _store.dispatch(startupApplication({ oidc }))
  })
  _oidc.manager.events.addUserUnloaded(() => {
    log(canLog, '[OIDC] Event: addUserUnloaded >> Dispatches: purgeUserProfile()')
    _store.dispatch(purgeUserProfile())
  })
  _oidc.manager.events.addAccessTokenExpiring(() => {
    log(canLog, '[OIDC] Event: addAccessTokenExpiring >> Dispatches: signInRedirect()')
    _store.dispatch(signInRedirect())
  })
  _oidc.manager.events.addSilentRenewError(() => {
    log(canLog, '[OIDC] Event: addSilentRenewError >> Dispatches: signInRedirect()')
    _store.dispatch(signInRedirect())
  })

  _oidc.manager.events.removeUserLoaded(() => {
    log(canLog, '[OIDC] Event: removeUserLoaded >> Dispatches: NOTHING')
  })

  _oidc.manager.events.removeUserUnloaded(() => {
    log(canLog, '[OIDC] Event: removeUserUnloaded >> Dispatches: NOTHING')
  })

  _oidc.manager.events.removeSilentRenewError(() => {
    log(canLog, '[OIDC] Event: removeSilentRenewError >> Dispatches: NOTHING')
  })

  _oidc.manager.events.removeUserSignedIn(() => {
    log(canLog, '[OIDC] Event: removeUserSignedIn >> Dispatches: NOTHING')
  })

  _oidc.manager.events.addUserSignedIn(() => {
    log(canLog, '[OIDC] Event: addUserSignedIn >> Dispatches: NOTHING')
  })

  _oidc.manager.events.addUserSessionChanged(() => {
    log(canLog, '[OIDC] Event: addUserSessionChanged >> Dispatches: NOTHING')
  })
  _oidc.manager.events.addSilentRenewError(() => {
    log(
      canLog,
      '[OIDC] Event: addSilentRenewError >> Dispatches: navigateExternal({path: environment.IDENTITY_SERVER.POST_LOGOUT_REDIRECT_URI})',
    )
    _store.dispatch(
      navigateExternal({ path: environment.IDENTITY_SERVER.POST_LOGOUT_REDIRECT_URI }),
    )
  })
}
