import { Inject, Injectable, OnDestroy } from '@angular/core'
import { DOCUMENT } from '@angular/common'

import { environment } from '@env'

import { BehaviorSubject, fromEvent } from 'rxjs'
import { debounceTime, filter } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class WindowEventsService implements OnDestroy {
  private isDragging = new BehaviorSubject<boolean>(false)

  constructor(@Inject(DOCUMENT) private document: Document) {
    fromEvent(window, 'drop')
      .pipe(untilDestroyed(this))
      .subscribe(() => this.isDragging.next(false))
    fromEvent(window, 'dragenter')
      .pipe(untilDestroyed(this))
      .subscribe(() => this.isDragging.next(true))
    fromEvent(window, 'dragleave')
      .pipe(
        untilDestroyed(this),
        filter<MouseEvent>(({ screenX, screenY }) => screenX + screenY === 0),
      )
      .subscribe(() => this.isDragging.next(false))

    fromEvent(window, 'resize')
      .pipe(debounceTime(environment.DEBOUNCE_TIME.INPUT_CHANGES), untilDestroyed(this))
      .subscribe(() => this._setViewportHeightVar())
    this._setViewportHeightVar()
  }

  private _setViewportHeightVar() {
    this.document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
  }

  getDraggingEvent() {
    return this.isDragging
  }

  ngOnDestroy() {
    // This is intentional
  }
}
