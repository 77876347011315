import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core'

import { TranslatorService } from '@mediacoach-ui-library/global'

import { MenuItem } from 'primeng/api'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { FormMode } from '@core/enums/form-mode.enums'
import { ToastService } from '@core/components/toast/toast.service'
import {
  formModeFn,
  onCloseCancelClick,
  onConfirmClose,
  onOverlayClose,
  save,
  toggleEdit,
} from '@core/utils/form.utils'

import { OverlayType } from '../overlay/overlay.enums'
import { OverlayComponent } from '../overlay/overlay.component'
import { GeneralFormComponent } from '../general-form/general-form.component'
import { UserProfile } from '@core/authentication/auth/models/auth.models'
import { AuthPage, RoleType } from '@core/authentication/auth/enums/auth.enums'
import { APPLICATION_DATA } from '@shared/components/quick-view/constants/quick-view.constants'
import { Store } from '@ngrx/store'
import { saveUserProfile, saveUserProfileOK } from '@core/state/actions/profile.actions'
import { Actions, ofType } from '@ngrx/effects'
import { take } from 'rxjs/operators'

interface TranslateMenuItem extends MenuItem {
  translateLabel?: string
  activate?: () => boolean
}

@UntilDestroy()
@Component({
  selector: 'app-quick-view',
  templateUrl: './quick-view.component.html',
  styleUrls: ['./quick-view.component.scss'],
})
export class QuickViewComponent implements OnChanges, OnDestroy {
  private _formMode = FormMode.Read
  private userMenuItemsConfig: TranslateMenuItem[] = [
    {
      translateLabel: 'MTR_TITLE_ADMIN',
      routerLink: AuthPage.Administration,
      icon: 'u-icon u-icon--lg u-icon--profile',
      activate: () => ((this.userProfile || <UserProfile>{}).roles || []).includes(RoleType.Admin),
    },
    {
      translateLabel: 'MTR_COMMON_PROFILE',
      icon: 'u-icon u-icon--lg u-icon--profile',
      command: () => {
        this.overlay.open()
      },
      activate: () => ((this.userProfile || <UserProfile>{}).roles || []).length > 0,
    },
    {
      translateLabel: 'MTR_COMMON_LOGOUT',
      routerLink: 'logout',
      icon: 'u-icon u-icon--lg u-icon--logout',
    },
  ]

  @ViewChild('overlay') overlay!: OverlayComponent
  @ViewChild('profile') profile!: GeneralFormComponent
  @Input() userProfile!: UserProfile

  formChangesObj!: UserProfile
  OverlayType = OverlayType
  defaultAvatar = '../../../../../assets/images/user-default-avatar.svg'
  userMenuItems!: MenuItem[]

  readonly appData = APPLICATION_DATA

  get formMode() {
    return this._formMode
  }
  set formMode(_formMode) {
    formModeFn(this, _formMode)
  }

  constructor(
    private ref: ChangeDetectorRef,
    private readonly _store: Store<unknown>,
    private readonly _actions$: Actions,
    private toastService: ToastService,
    private translatorService: TranslatorService,
  ) {
    this.translatorService
      .stream(this.userMenuItemsConfig.map(({ translateLabel }) => translateLabel))
      .pipe(untilDestroyed(this))
      .subscribe((translatedLabels) =>
        this.userMenuItemsConfig.forEach(
          (menuItem) => (menuItem.label = translatedLabels[menuItem.translateLabel]),
        ),
      )
  }

  ngOnChanges(changes: SimpleChanges) {
    const userProfileChanges = (changes['userProfile'] || <SimpleChanges>{}).currentValue
    if (userProfileChanges) {
      this.userMenuItems = this.userMenuItemsConfig.filter(
        (userMenuItem) => !userMenuItem.activate || userMenuItem.activate(),
      )
    }
  }

  onConfirmClose(isCloseConfirmed) {
    onConfirmClose(this, isCloseConfirmed)
  }

  onOverlayClose() {
    onOverlayClose(this)
    this.profile.resetForm()
  }

  onUserProfileChange(userProfile: UserProfile) {
    this.formChangesObj = userProfile
  }

  toggleEdit(formMode?: FormMode, formChangesObj = {}) {
    toggleEdit(this, formMode, formChangesObj)
  }

  onCloseCancelClick() {
    onCloseCancelClick(this)
  }

  onSaveEditClick() {
    this.save()
  }

  save() {
    save(
      this,
      this._actions$.pipe(ofType(saveUserProfileOK), take(1)),
      'MTR_SUCCESS_EDIT_USER_PROFILE',
    )
    this._store.dispatch(
      saveUserProfile({ profile: { ...this.profile.userForm.getRawValue(), roles: [] } }),
    )
  }

  ngOnDestroy() {
    // This is intentional
  }
}
