import { environment } from '@env'

export const ASSETS_TO_SANITIZER = ['manifest.plist', 'mobile-manifest.plist']

export const DOWNLOAD_ASSETS = {
  Assets: [
    {
      id: null,
      type: 'Download',
      contentType: 'installer_desktop_win',
      quality: null,
      creationDate: '2017-02-25T16:07:13.02368+00:00',
      lastModificationDate: '2020-05-25T16:07:13.023679+00:00',
      qualifiers: null,
      // tslint:disable-next-line:max-line-length
      SUR: environment.STATICS_FULL_URL(
        'Desktop/Win/Installer/MediacoachDesktopInstaller.exe?st=Z4SxKOP0o9_Dg5XKUDH2CQ&e=1616060073&u=e8e6d42e-78f5-41a6-887c-408868aea8b3&n=MediacoachDesktopInstaller.exe',
      ),
      statusAvailable: 0,
      isAvailable: true,
      extension: 'EXE',
      order: 0,
      groupType: 'DesktopInstaller',
      lang: 'es_en',
      path: environment.STATICS_FULL_URL('Desktop/Win/Installer/MediacoachDesktopInstaller.exe'),
      friendlyName: 'MediacoachDesktopInstaller.exe',
    },
    {
      id: null,
      type: 'Download',
      contentType: 'installer_desktop_mac',
      quality: null,
      creationDate: '2021-02-25T16:14:13.02368+00:00',
      lastModificationDate: '2021-02-25T16:14:13.023679+00:00',
      qualifiers: null,
      // tslint:disable-next-line:max-line-length
      SUR: environment.STATICS_FULL_URL(
        'Desktop/MacOS/Installer/MediacoachDesktopInstaller.dmg?st=esCnQG4WQp5tGfalSSroJw&e=1616060073&u=e8e6d42e-78f5-41a6-887c-408868aea8b3&n=MediacoachDesktopInstaller.dmg',
      ),
      statusAvailable: 0,
      isAvailable: true,
      extension: 'DMG',
      order: 1,
      groupType: 'DesktopInstaller',
      lang: 'es_en',
      path: environment.STATICS_FULL_URL('Desktop/MacOS/Installer/MediacoachDesktopInstaller.dmg'),
      friendlyName: 'MediacoachDesktopInstaller.dmg',
    },
    {
      id: null,
      type: 'Download',
      contentType: 'installer_ios',
      quality: null,
      creationDate: '2017-02-25T16:07:13.023682+00:00',
      lastModificationDate: '2020-05-25T16:07:13.023679+00:00',
      qualifiers: null,
      // tslint:disable-next-line:max-line-length
      SUR: `itms-services:///?action=download-manifest&url=${environment.STATICS_FULL_URL(
        'Live/iOS/manifest.plist?st=fcpb66dng7Ac9uBbeUSjmg&e=1616060073&u=e8e6d42e-78f5-41a6-887c-408868aea8b3&n=manifest.plist',
      )}`,
      statusAvailable: 0,
      isAvailable: true,
      extension: 'IOS',
      order: 0,
      groupType: 'LiveInstaller',
      lang: 'es_en',
      // tslint:disable-next-line:max-line-length
      path: `itms-services://?action=download-manifest&url=${environment.STATICS_FULL_URL(
        'Live/iOS/manifest.plist',
      )}`,
      friendlyName: 'manifest.plist',
    },
    {
      id: null,
      type: 'Download',
      contentType: 'installer_android',
      quality: null,
      creationDate: '2017-02-25T16:07:13.023662+00:00',
      lastModificationDate: '2020-05-25T16:07:13.023679+00:00',
      qualifiers: null,
      // tslint:disable-next-line:max-line-length
      SUR: environment.STATICS_FULL_URL(
        'Live/Android/MCLive.apk?st=QrTkGJWzslCRChrO4LggXg&e=1616060073&u=e8e6d42e-78f5-41a6-887c-408868aea8b3&n=MCLive.apk',
      ),
      statusAvailable: 0,
      isAvailable: true,
      extension: 'APK',
      order: 1,
      groupType: 'LiveInstaller',
      lang: 'es_en',
      path: environment.STATICS_FULL_URL('Live/Android/MCLive.apk'),
      friendlyName: 'MCLive.apk',
    },
    {
      id: null,
      type: 'Download',
      contentType: 'mobile_installer_ios',
      quality: null,
      creationDate: '2020-10-23T13:00:00.023682+00:00',
      lastModificationDate: '2020-10-23T13:00:00.023682+00:00',
      qualifiers: null,
      // tslint:disable-next-line:max-line-length
      SUR: `itms-services:///?action=download-manifest&url=${environment.STATICS_FULL_URL(
        'Mobile/iOS/manifest.plist?st=jthJNKTjXOyprgFFTcJgHw&e=1616060073&u=e8e6d42e-78f5-41a6-887c-408868aea8b3&n=mobile-manifest.plist',
      )}`,
      statusAvailable: 0,
      isAvailable: true,
      extension: 'IOS',
      order: 0,
      groupType: 'MobileInstaller',
      lang: 'es_en',
      // tslint:disable-next-line:max-line-length
      path: `itms-services://?action=download-manifest&url=${environment.STATICS_FULL_URL(
        'Mobile/iOS/manifest.plist',
      )}`,
      friendlyName: 'mobile-manifest.plist',
    },
    {
      id: null,
      type: 'Download',
      contentType: 'mobile_installer_android',
      quality: null,
      creationDate: '2020-10-23T13:00:00.023682+00:00',
      lastModificationDate: '2020-10-23T13:00:00.023682+00:00',
      qualifiers: null,
      // tslint:disable-next-line:max-line-length
      SUR: environment.STATICS_FULL_URL(
        'Mobile/Android/MCMobile.apk?st=Zj7HjUGzOTeC8VXg7_CfAQ&e=1616060073&u=e8e6d42e-78f5-41a6-887c-408868aea8b3&n=MCMobile.apk',
      ),
      statusAvailable: 0,
      isAvailable: true,
      extension: 'APK',
      order: 1,
      groupType: 'MobileInstaller',
      lang: 'es_en',
      path: environment.STATICS_FULL_URL('Mobile/Android/MCMobile.apk'),
      friendlyName: 'MCMobile.apk',
    },
  ],
}
