import { Component } from '@angular/core'

import { ToastIconMapping } from './toast.constants'

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent {
  toastIconMapping = ToastIconMapping

  constructor() {
    // This is intentional
  }
}
