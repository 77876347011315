import { AuthFeature, AuthPage, RoleType } from '../enums/auth.enums'

// eslint-disable-next-line @typescript-eslint/naming-convention
export const RoleTypeKey = {
  [RoleType.Admin]: 'MTR_ROLE_ADMIN',
  [RoleType.Support]: 'MTR_ROLE_SUPPORT',
  [RoleType.Production]: 'MTR_ROLE_PRODUCTION',
  [RoleType.Technician]: 'MTR_ROLE_TECHNICIAN',
  [RoleType.Sales]: 'MTR_ROLE_SALES',
}

export const ROLE_MAPPING = {
  [RoleType.Admin]: {
    permissions: [
      AuthPage.Administration,
      AuthPage.Alerts,
      AuthPage.UserCustomerView,
      AuthPage.Marketable,
      AuthPage.PermissionManagement,
      AuthPage.Dashboard,
      AuthPage.DataIncoming,
      AuthPage.DataPublishing,
      AuthPage.Monitoring,
      AuthPage.Stadium,
      AuthPage.License,
      AuthPage.MediaReports,
      AuthPage.GrafanaWidgets,
      AuthPage.Playground,
      AuthPage.MetricsManager,
      AuthPage.PlayersManager,
    ],
    features: [AuthFeature.ReportIssue],
  },
  [RoleType.Support]: {
    permissions: [
      AuthPage.Alerts,
      AuthPage.UserCustomerView,
      AuthPage.Dashboard,
      AuthPage.DataIncoming,
      AuthPage.DataPublishing,
      AuthPage.Monitoring,
      AuthPage.License,
      AuthPage.MediaReports,
      AuthPage.GrafanaWidgets,
      AuthPage.Playground,
      AuthPage.MetricsManager,
    ],
    features: [AuthFeature.ReportIssue],
  },
  [RoleType.Production]: {
    permissions: [
      AuthPage.Alerts,
      AuthPage.Dashboard,
      AuthPage.DataIncoming,
      AuthPage.DataPublishing,
      AuthPage.Stadium,
      AuthPage.Playground,
      AuthPage.GrafanaWidgets,
    ],
    features: [AuthFeature.ReportIssue],
  },
  [RoleType.Technician]: {
    permissions: [
      AuthPage.Alerts,
      AuthPage.Dashboard,
      AuthPage.DataIncoming,
      AuthPage.DataPublishing,
      AuthPage.Monitoring,
      AuthPage.MediaReports,
      AuthPage.Playground,
      AuthPage.GrafanaWidgets,
      AuthPage.PlayersManager,
    ],
    features: [],
  },
  [RoleType.Sales]: {
    permissions: [
      AuthPage.Dashboard,
      AuthPage.UserCustomerView,
      AuthPage.Playground,
      AuthPage.Marketable,
    ],
    features: [],
  },
}
