import { AssetStatus, AssetType, ContentType } from '@core/enums/asset.enums'

export const ASSET_TYPE_TRANSLATION = {
  [AssetType.Video]: 'MTR_COMMON_VIDEO',
  [AssetType.Report]: 'MTR_COMMON_REPORT',
  [AssetType.Eventing]: 'MTR_COMMON_EVENTING',
  [AssetType.Tracking]: 'MTR_COMMON_TRACKING',
  [AssetType.Metadata]: 'MTR_COMMON_METADATA',
  [AssetType.PrivateReport]: 'MTR_COMMON_PRIVATE_REPORT',
  [AssetType.Calibration]: 'MTR_COMMON_CALIBRATION',
  default: 'MTR_COMMON_OTHER',
}

export const CONTENT_TYPE_TRANSLATION = {
  [ContentType.ReportMedia]: 'MTR_COMMON_REPORT_MEDIA',
  default: undefined,
}

export const ASSET_STATUS_TRANSLATION = {
  [AssetStatus.Unavailable]: 'MTR_MATCHES_UNAVAILABLE',
  [AssetStatus.Available]: 'MTR_MATCHES_AVAILABLE',
  [AssetStatus.InProgress]: 'MTR_MATCHES_IN_PROGRESS',
  [AssetStatus.WaitingForMetrics]: 'MTR_MATCHES_WAITING_FOR_METRICS',
  [AssetStatus.ReadyToBeGenerated]: 'MTR_MATCHES_READY_TO_BE_GENERATED',
  [AssetStatus.Canceled]: 'MTR_MATCHES_CANCELED',
  [AssetStatus.Created]: 'MTR_MATCHES_CREATED',
  [AssetStatus.Failed]: 'MTR_MATCHES_FAILED',
  [AssetStatus.EncryptedFailed]: 'MTR_MATCHES_ENCRYPTION_FAILED',
  [AssetStatus.AddedToS3]: 'MTR_MATCHES_ADDED_TO_S3',
  default: 'MTR_MATCHES_UNAVAILABLE',
}

export const ASSET_TYPE_ICON_MAPPING = {
  [AssetType.Video]: 'video-outlined',
  [AssetType.Report]: 'file-text-outlined',
  [AssetType.Eventing]: 'flag-outlined',
  [AssetType.Tracking]: 'tracking',
  [AssetType.Metadata]: 'metadata',
  [AssetType.PrivateReport]: 'file-lock-outlined',
  [AssetType.Calibration]: 'calibration',
  default: 'file-outlined',
}

export const CONTENT_TYPE_ICON_MAPPING = {
  [ContentType.ReportMedia]: 'file-report-media',
}
