import { TranslateLoader } from '@ngx-translate/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { ignoreTenant } from '@core/requests/interceptors/tenant.interceptor'

export class CustomTranslateHttpLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    public prefix: string = 'assets/i18n/',
    public suffix: string = '.json',
  ) {}

  getTranslation(lang: string): Observable<Object> {
    return this.http.get(`${this.prefix}${lang}${this.suffix}`, { context: ignoreTenant() })
  }
}
