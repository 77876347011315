import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'

import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { OidcService } from '@core/authentication/oidc/services/oidc.service'
import { Store } from '@ngrx/store'
import { signInRedirect } from '@core/state/actions/oidc.actions'
import { startupApplication } from '@core/state/actions/startup.actions'

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private readonly _manager: OidcService,
    private readonly _store: Store<any>,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._manager.getUser().pipe(
      map((oidc) => {
        if (!oidc) {
          // If route is not authorized, sign in starts with route requested
          this._store.dispatch(signInRedirect(state.url))
        } else {
          this._store.dispatch(startupApplication({ oidc }))
        }
        return !!oidc
      }),
    )
  }
}
