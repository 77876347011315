<div
  #container
  [ngClass]="containerClass"
  [ngStyle]="style"
  [class]="styleClass"
  (click)="onContainerClick($event)"
>
  <input
    *ngIf="!multiple"
    #focusInput
    pAutoFocus
    [autofocus]="autofocus"
    [ngClass]="inputClass"
    [ngStyle]="inputStyle"
    [class]="inputStyleClass"
    [type]="type"
    [attr.value]="inputValue()"
    [attr.id]="inputId"
    [autocomplete]="autocomplete"
    [required]="required"
    [name]="name"
    aria-autocomplete="list"
    role="combobox"
    [attr.placeholder]="placeholder"
    [attr.size]="size"
    [maxlength]="maxlength"
    [tabindex]="!disabled ? tabindex : -1"
    [readonly]="readonly"
    [disabled]="disabled"
    [attr.aria-label]="ariaLabel"
    [attr.aria-labelledby]="ariaLabelledBy"
    [attr.aria-required]="required"
    [attr.aria-expanded]="overlayVisible"
    [attr.aria-controls]="id + '_list'"
    [attr.aria-aria-activedescendant]="focused ? focusedOptionId : undefined"
    (input)="onInput($event)"
    (keydown)="onKeyDown($event)"
    (change)="onInputChange($event)"
    (focus)="onInputFocus($event)"
    (blur)="onInputBlur($event)"
    (paste)="onInputPaste($event)"
    (keyup)="onInputKeyUp($event)"
  />
  <ng-container *ngIf="filled && !disabled && showClear && !loading">
    <TimesIcon
      *ngIf="!clearIconTemplate"
      [styleClass]="'p-autocomplete-clear-icon'"
      (click)="clear()"
      [attr.aria-hidden]="true"
    />
    <span
      *ngIf="clearIconTemplate"
      class="p-autocomplete-clear-icon"
      (click)="clear()"
      [attr.aria-hidden]="true"
    >
      <ng-template *ngTemplateOutlet="clearIconTemplate"></ng-template>
    </span>
  </ng-container>

  <ul
    *ngIf="multiple"
    #multiContainer
    [class]="multiContainerClass"
    [tabindex]="-1"
    role="listbox"
    [attr.aria-orientation]="'horizontal'"
    [attr.aria-activedescendant]="focused ? focusedMultipleOptionId : undefined"
    (focus)="onMultipleContainerFocus($event)"
    (blur)="onMultipleContainerBlur($event)"
    (keydown)="onMultipleContainerKeyDown($event)"
  >
    <li
      #token
      *ngFor="let option of modelValue(); let i = index"
      [ngClass]="{ 'p-autocomplete-token': true, 'p-focus': focusedMultipleOptionIndex() === i }"
      [attr.id]="id + '_multiple_option_' + i"
      role="option"
      [attr.aria-label]="getOptionLabel(option)"
      [attr.aria-setsize]="modelValue().length"
      [attr.aria-posinset]="i + 1"
      [attr.aria-selected]="true"
    >
      <ng-container
        *ngTemplateOutlet="selectedItemTemplate; context: { $implicit: option }"
      ></ng-container>
      <span *ngIf="!selectedItemTemplate" class="p-autocomplete-token-label">{{
        getOptionLabel(option)
      }}</span>
      <span class="p-autocomplete-token-icon" (click)="removeOption($event, i)">
        <TimesCircleIcon
          [styleClass]="'p-autocomplete-token-icon'"
          *ngIf="!removeIconTemplate"
          [attr.aria-hidden]="true"
        />
        <span
          *ngIf="removeIconTemplate"
          class="p-autocomplete-token-icon"
          [attr.aria-hidden]="true"
        >
          <ng-template *ngTemplateOutlet="removeIconTemplate"></ng-template>
        </span>
      </span>
    </li>
    <li class="p-autocomplete-input-token" role="option">
      <input
        #focusInput
        pAutoFocus
        [autofocus]="autofocus"
        [ngClass]="inputClass"
        [ngStyle]="inputStyle"
        [class]="inputStyleClass"
        [attr.type]="type"
        [attr.id]="inputId"
        [autocomplete]="autocomplete"
        [required]="required"
        [attr.name]="name"
        role="combobox"
        [attr.placeholder]="placeholder"
        [attr.size]="size"
        aria-autocomplete="list"
        [maxlength]="maxlength"
        [tabindex]="!disabled ? tabindex : -1"
        [readonly]="readonly"
        [disabled]="disabled"
        [attr.aria-label]="ariaLabel"
        [attr.aria-labelledby]="ariaLabelledBy"
        [attr.aria-required]="required"
        [attr.aria-expanded]="overlayVisible"
        [attr.aria-controls]="id + '_list'"
        [attr.aria-aria-activedescendant]="focused ? focusedOptionId : undefined"
        (input)="onInput($event)"
        (keydown)="onKeyDown($event)"
        (change)="onInputChange($event)"
        (focus)="onInputFocus($event)"
        (blur)="onInputBlur($event)"
        (paste)="onInputPaste($event)"
        (keyup)="onInputKeyUp($event)"
      />
    </li>
  </ul>
  <ng-container *ngIf="loading">
    <SpinnerIcon
      *ngIf="!loadingIconTemplate"
      [styleClass]="'p-autocomplete-loader'"
      [spin]="true"
      [attr.aria-hidden]="true"
    />
    <span
      *ngIf="loadingIconTemplate"
      class="p-autocomplete-loader pi-spin"
      [attr.aria-hidden]="true"
    >
      <ng-template *ngTemplateOutlet="loadingIconTemplate"></ng-template>
    </span>
  </ng-container>
  <button
    #ddBtn
    type="button"
    pButton
    [attr.aria-label]="dropdownAriaLabel"
    class="p-autocomplete-dropdown p-button-icon-only"
    [disabled]="disabled"
    pRipple
    (click)="handleDropdownClick($event)"
    *ngIf="dropdown"
    [attr.tabindex]="tabindex"
  >
    <span *ngIf="dropdownIcon" [ngClass]="dropdownIcon" [attr.aria-hidden]="true"></span>
    <ng-container *ngIf="!dropdownIcon">
      <ChevronDownIcon *ngIf="!dropdownIconTemplate" />
      <ng-template *ngTemplateOutlet="dropdownIconTemplate"></ng-template>
    </ng-container>
  </button>
  <p-overlay
    #overlay
    [(visible)]="overlayVisible"
    [options]="overlayOptions"
    [target]="'@parent'"
    [appendTo]="appendTo"
    [showTransitionOptions]="showTransitionOptions"
    [hideTransitionOptions]="hideTransitionOptions"
    (onAnimationStart)="onOverlayAnimationStart($event)"
    (onHide)="hide()"
  >
    <div
      [ngClass]="panelClass"
      [style.max-height]="virtualScroll ? 'auto' : scrollHeight"
      [ngStyle]="panelStyle"
      [class]="panelStyleClass"
    >
      <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
      <p-scroller
        *ngIf="virtualScroll"
        #scroller
        [items]="visibleOptions()"
        [style]="{ height: scrollHeight }"
        [itemSize]="virtualScrollItemSize || _itemSize"
        [autoSize]="true"
        [lazy]="lazy"
        (onLazyLoad)="onLazyLoad.emit($event)"
        [options]="virtualScrollOptions"
      >
        <ng-template pTemplate="content" let-items let-scrollerOptions="options">
          <ng-container
            *ngTemplateOutlet="
              buildInItems;
              context: { $implicit: items, options: scrollerOptions }
            "
          ></ng-container>
        </ng-template>
        <ng-container *ngIf="loaderTemplate">
          <ng-template pTemplate="loader" let-scrollerOptions="options">
            <ng-container
              *ngTemplateOutlet="loaderTemplate; context: { options: scrollerOptions }"
            ></ng-container>
          </ng-template>
        </ng-container>
      </p-scroller>
      <ng-container *ngIf="!virtualScroll">
        <ng-container
          *ngTemplateOutlet="buildInItems; context: { $implicit: visibleOptions(), options: {} }"
        ></ng-container>
      </ng-container>

      <ng-template #buildInItems let-items let-scrollerOptions="options">
        <ul
          #items
          class="p-autocomplete-items"
          [ngClass]="scrollerOptions.contentStyleClass"
          [style]="scrollerOptions.contentStyle"
          role="listbox"
          [attr.id]="id + '_list'"
        >
          <ng-template ngFor let-option [ngForOf]="items" let-i="index">
            <ng-container *ngIf="isOptionGroup(option)">
              <li
                [attr.id]="id + '_' + getOptionIndex(i, scrollerOptions)"
                class="p-autocomplete-item-group"
                [ngStyle]="{ height: scrollerOptions.itemSize + 'px' }"
                role="option"
              >
                <span *ngIf="!groupTemplate">{{
                  getOptionGroupLabel(option.optionGroup) | translate
                }}</span>
                <ng-container
                  *ngTemplateOutlet="groupTemplate; context: { $implicit: option.optionGroup }"
                ></ng-container>
              </li>
            </ng-container>
            <ng-container *ngIf="!isOptionGroup(option)">
              <li
                class="p-autocomplete-item"
                pRipple
                [ngStyle]="{ height: scrollerOptions.itemSize + 'px' }"
                [ngClass]="{
                  'p-highlight': isSelected(option),
                  'p-focus': focusedOptionIndex() === getOptionIndex(i, scrollerOptions),
                  'p-disabled': isOptionDisabled(option)
                }"
                [attr.id]="id + '_' + getOptionIndex(i, scrollerOptions)"
                role="option"
                [attr.aria-label]="getOptionLabel(option)"
                [attr.aria-selected]="isSelected(option)"
                [attr.aria-disabled]="isOptionDisabled(option)"
                [attr.data-p-focused]="focusedOptionIndex() === getOptionIndex(i, scrollerOptions)"
                [attr.aria-setsize]="ariaSetSize"
                [attr.aria-posinset]="getAriaPosInset(getOptionIndex(i, scrollerOptions))"
                (click)="onOptionSelect($event, option)"
                (mouseenter)="onOptionMouseEnter($event, getOptionIndex(i, scrollerOptions))"
              >
                <span *ngIf="!itemTemplate">{{ getOptionLabel(option) }}</span>
                <ng-container
                  *ngTemplateOutlet="
                    itemTemplate;
                    context: {
                      $implicit: option,
                      index: scrollerOptions.getOptions ? scrollerOptions.getOptions(i) : i
                    }
                  "
                ></ng-container>
              </li>
            </ng-container>
          </ng-template>
          <li
            *ngIf="!items || (items && items.length === 0 && showEmptyMessage)"
            class="p-autocomplete-empty-message"
            [ngStyle]="{ height: scrollerOptions.itemSize + 'px' }"
            role="option"
          >
            <ng-container *ngIf="!emptyTemplate; else empty">
              {{ searchResultMessageText }}
            </ng-container>
            <ng-container #empty *ngTemplateOutlet="emptyTemplate"></ng-container>
          </li>
        </ul>
        <ng-container
          *ngTemplateOutlet="footerTemplate; context: { $implicit: items }"
        ></ng-container>
      </ng-template>
    </div>
    <span role="status" aria-live="polite" class="p-hidden-accessible">
      {{ selectedMessageText }}
    </span>
  </p-overlay>
</div>
