import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'

import { TableCheckbox } from 'primeng/table'

@Component({
  selector: 'app-table-checkbox',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TableCheckboxComponent),
      multi: true,
    },
  ],
  templateUrl: 'table-checkbox.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableCheckboxComponent extends TableCheckbox {
  override onClick(event: Event) {
    event.preventDefault()
    event.stopPropagation()
    super.onClick(event)
  }
}
