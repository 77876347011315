import { ApplicationName } from '@mediacoach-ui-library/global'

import { DialogType } from './dialog-application.enums'
import { IMAGE_BASE_CLASS, IMAGE_BASE_PATH } from './dialog-application.constants'

export const getBackgroundImage = (type: DialogType, app: ApplicationName | string) =>
  `${IMAGE_BASE_PATH}header__${type}--${app}.jpg`
export const getHeader = (type: DialogType, app: ApplicationName | string) => [
  {
    image: `${IMAGE_BASE_PATH}logo--${app}.svg`,
    class: `${IMAGE_BASE_CLASS}logo`,
  },
  {
    image: `${IMAGE_BASE_PATH}platform--${app}.png`,
    class: `${IMAGE_BASE_CLASS}platform`,
  },
]
