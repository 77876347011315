import { APP_INITIALIZER, NgModule } from '@angular/core'
import { HttpClientModule } from '@angular/common/http'

import { ToastModule } from 'primeng/toast'

import { TranslatorService } from '@mediacoach-ui-library/global'

import { SharedModule } from '@shared/shared.module'
import { HeaderComponent, SideNavComponent, ToastComponent } from './components'
import { httpInterceptorProviders } from '@core/requests/interceptors'
import { StateModule } from '@core/state/state.module'
import { NgrxRouterStoreModule } from '@core/ngrx-router/ngrx-router.module'
import { OidcModule } from '@core/authentication/oidc/oidc.module'
import { idsFactory } from '@core/authentication/oidc/factories/ids.factory'
import { OidcService } from '@core/authentication/oidc/services/oidc.service'
import { Store } from '@ngrx/store'
import { AuthModule } from '@core/authentication/auth/auth.module'
import { ErrorPageComponent } from '@core/error/components/error-page/error-page.component'
import { I18nModule } from '@core/i18n/i18n.module'
import { TranslateModule } from '@ngx-translate/core'
import { MessageService } from 'primeng/api'

const COMMON_MODULES = [StateModule, NgrxRouterStoreModule]

@NgModule({
  declarations: [ToastComponent, HeaderComponent, SideNavComponent, ErrorPageComponent],
  imports: [
    SharedModule,
    TranslateModule,
    HttpClientModule,
    ToastModule,
    OidcModule,
    AuthModule,
    I18nModule,
    ...COMMON_MODULES,
  ],
  providers: [
    httpInterceptorProviders,
    MessageService,
    TranslatorService,
    {
      provide: APP_INITIALIZER,
      useFactory: idsFactory,
      deps: [OidcService, Store],
      multi: true,
    },
  ],
  exports: [
    ToastComponent,
    HeaderComponent,
    SideNavComponent,
    ErrorPageComponent,
    ...COMMON_MODULES,
  ],
})
export class CoreModule {}
