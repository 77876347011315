import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-label-counter',
  templateUrl: './label-counter.component.html',
  styleUrls: ['./label-counter.component.scss'],
})
export class LabelCounterComponent {
  @Input() public countNum: number

  constructor() {
    // This is intentional
  }
}
