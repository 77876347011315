import { Directive, ElementRef, HostListener, Input } from '@angular/core'

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[onClickOutside]',
})
export class OnClickOutsideDirective {
  @Input() execute!: { context: any; fn: string; params: any }

  constructor(private _elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  private onClick(targetElement: any) {
    const clickedInside = this._elementRef.nativeElement.contains(targetElement)
    if (!clickedInside) {
      const { context, fn, params } = this.execute
      context[fn](...params)
    }
  }
}
