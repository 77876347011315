import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms'

import { Subject } from 'rxjs'
import { debounceTime, tap } from 'rxjs/operators'
import { Calendar } from 'primeng/calendar'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

import { environment } from '@env'
import { dateFromString, getDateFromCurrent, getISODate, setEndDate } from '@core/utils/main'

import { SelectionMode } from './calendar.enums'
import { DateRange, DateType } from './calendar.models'
import { CALENDAR_DAYS_THRESHOLD, CURRENT_YEAR, LOCALES } from './calendar.constants'

@UntilDestroy()
@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarComponent),
      multi: true,
    },
  ],
})
export class CalendarComponent extends Calendar implements OnInit, OnDestroy, ControlValueAccessor {
  private _onChangeDebounce$ = new Subject()
  private _language: string
  formControl = new UntypedFormControl()
  defaultSelectionMode = SelectionMode.Single
  defaultYearRange = CURRENT_YEAR - 10 + ':' + (CURRENT_YEAR + 10)
  rangeFromCurrentSelectionModes = [SelectionMode.Multiple, SelectionMode.Range]

  @Input() override showIcon = true
  @Input() override dateFormat = null
  @Input() universal = false
  @Input() override showClear = true
  @Input() override readonlyInput = true

  @Input() set isDisabled(_disabled: boolean) {
    this.setDisabledState(_disabled)
  }

  get language(): string {
    return this._language
  }
  @Input() set language(_language: string) {
    this._language = _language
    // We update the date format according to language changes
    setTimeout(() => this.calendar.updateInputfield(), 0)
  }

  @ViewChild('calendar', { static: true }) calendar: Calendar
  @ViewChild('clearButton') set clearButton(_clearButton: ElementRef) {
    if (_clearButton) {
      const inputElement = this.el.nativeElement.querySelector('input')
      inputElement.after(_clearButton.nativeElement)
    }
  }

  @Output() override onSelect

  get rangeEnabled() {
    return this.rangeFromCurrentSelectionModes.includes(this.selectionMode as any)
  }

  private _setUTCDate(dates, toISOString = false, inverse = false): DateType {
    const getISODateFn = (_dates) =>
      getISODate(_dates, this.universal || !toISOString, toISOString, inverse)
    return Array.isArray(dates) ? dates.map((date) => getISODateFn(date)) : getISODateFn(dates)
  }

  private _encodeDates(dates: DateType) {
    const { start, end } = <DateRange>dates || <DateRange>{}
    const encodedDates =
      dates && (this.selectionMode === SelectionMode.Range ? [start, end] : dates)
    return Array.isArray(encodedDates)
      ? encodedDates.map((date) => dateFromString(date, this.universal))
      : dateFromString(encodedDates, this.universal)
  }

  private _formatDates(dates: Date | Date[], onlyFormat = false): DateType {
    const _dates = this._setUTCDate(dates, true, onlyFormat)
    return (
      _dates &&
      (this.selectionMode === SelectionMode.Range
        ? { start: _dates[0], end: onlyFormat ? _dates[1] : setEndDate(_dates[1], true) }
        : _dates)
    )
  }

  override ngOnInit() {
    this._onChangeDebounce$
      .pipe(
        debounceTime(environment.DEBOUNCE_TIME.FOR_CRASHES),
        tap((event) => this.onSelect.emit(event)),
        untilDestroyed(this),
      )
      .subscribe()

    this.onBlur
      .pipe(
        tap(() => this.onModelTouched()),
        untilDestroyed(this),
      )
      .subscribe()

    this.formControl.valueChanges
      .pipe(
        tap((dates) => this.onModelChange(this._formatDates(dates))),
        untilDestroyed(this),
      )
      .subscribe()
  }

  override ngOnDestroy() {
    // This is intentional
  }

  override writeValue(value, emitEvent = false) {
    this.formControl.setValue(this._encodeDates(value), { emitEvent })
    this._onChangeDebounce$.next(value)
  }

  override setDisabledState(val: boolean) {
    const emitEvent = false
    this.disabled = val
    if (val) {
      this.formControl.disable({ emitEvent })
    } else {
      this.formControl.enable({ emitEvent })
    }
  }

  selectRangeFromCurrentDate(threshold = CALENDAR_DAYS_THRESHOLD) {
    const numberOfDays = threshold * 2 + 1
    const dates = Array(numberOfDays)
      .fill(1)
      .map((v, i) => i - threshold)
      .map((date) => getISODate(getDateFromCurrent(date), this.universal, true, !this.universal))
    this.writeValue(
      this.selectionMode === SelectionMode.Range
        ? { start: dates[0], end: dates.slice(-1)[0] }
        : dates,
      true,
    )
  }

  clearCalendar(event) {
    this.writeValue(null)
    // Calling onClearButtonClick to emulate its trigger and complete PrimeNG's Calendar flow
    this.onClearButtonClick(event)
  }
}
