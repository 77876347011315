import { Validators } from '@angular/forms'

import { CURRENT_YEAR } from '../calendar/calendar.constants'
import { FormInputSetting } from './general-form.models'
import { InputType, TemplateType } from './general-form.enums'
import { atLeastValidator, compareValues, MaskOnRegex } from './general-form.utils'
import { Switch } from '@mediacoach-ui-library/global'

export const MASKS = {
  onlyNumbersAndMax: MaskOnRegex(/^(\d)$/),
  phoneNumberAndMax: MaskOnRegex(/^(\d|\+)$/),
}

export const VALIDATORS = {
  required: Validators.required,
  email: Validators.email,
  atLeastOne: atLeastValidator(1),
  maxLength: (maxLength) => Validators.maxLength(maxLength),
  onlyNumbers: Validators.pattern(/^-?(0|[1-9]\d*)?$/),
  hourMinute: Validators.pattern(/^([0-1][0-9]|[0-9][0-9]|[1-9][0-9][0-9]):([0-5][0-9])$/),
  compareValues: (fields, comparison) => compareValues(fields, comparison),
}

export const PASSWORD_EXPIRATION_OPTIONS = [
  { value: 0, label: 'MTR_COMMON_NO_EXPIRATION' },
  { value: 30, label: 'MTR_COMMON_ONE_MONTH' },
  { value: 60, label: 'MTR_COMMON_TWO_MONTHS' },
  { value: 90, label: 'MTR_COMMON_THREE_MONTHS' },
  { value: 180, label: 'MTR_COMMON_SIX_MONTHS' },
  { value: 365, label: 'MTR_COMMON_ONE_YEAR' },

]

export const FORM_INPUTS: FormInputSetting = {
  id: {
    validators: [VALIDATORS.required],
    templates: [TemplateType.UserProfile, TemplateType.User, TemplateType.UserBO],
  },
  photoPath: {
    templates: [TemplateType.UserProfile, TemplateType.User, TemplateType.UserBO],
  },
  name: {
    label: 'MTR_COMMON_NAME',
    type: InputType.Text,
    validators: [VALIDATORS.required],
    templates: [TemplateType.UserProfile, TemplateType.User, TemplateType.UserBO],
  },
  surname: {
    label: 'MTR_COMMON_SURNAME',
    type: InputType.Text,
    validators: [VALIDATORS.required],
    templates: [TemplateType.UserProfile, TemplateType.User, TemplateType.UserBO],
  },
  email: {
    label: 'MTR_COMMON_EMAIL',
    type: InputType.Text,
    validators: [VALIDATORS.required, VALIDATORS.email],
    disabled: true,
    templates: [TemplateType.UserProfile, TemplateType.User, TemplateType.UserBO],
  },
  language: {
    label: 'MTR_COMMON_LANGUAGE',
    type: InputType.Dropdown,
    placeholder: 'MTR_COMMON_LANGUAGE',
    validators: [VALIDATORS.required],
    templates: [TemplateType.UserProfile, TemplateType.User, TemplateType.UserBO],
  },
  customerId: {
    label: 'MTR_COMMON_CUSTOMER',
    type: InputType.Dropdown,
    placeholder: 'MTR_COMMON_CUSTOMER_SELECT',
    validators: [VALIDATORS.required],
    templates: [TemplateType.User, TemplateType.UserBO],
  },
  active: {
    label: 'MTR_COMMON_STATUS',
    type: InputType.Switch,
    switchLabels: [
      { id: Switch.Off, text: 'MTR_COMMON_DEACTIVATED' },
      { id: Switch.On, text: 'MTR_COMMON_ACTIVE' },
    ],
    validators: [VALIDATORS.required],
    templates: [TemplateType.User, TemplateType.UserBO],
  },
  isPrincipalCustomerContact: {
    label: 'MTR_COMMON_MAIN_CUSTOMER',
    type: InputType.Switch,
    validators: [VALIDATORS.required],
    templates: [TemplateType.User],
  },
  positionId: {
    label: 'MTR_COMMON_POSITION',
    type: InputType.Dropdown,
    placeholder: 'MTR_COMMON_POSITION_SELECT',
    templates: [TemplateType.User],
  },
  phoneNumber: {
    label: 'MTR_COMMON_PHONE_NUMBER',
    type: InputType.Text,
    templates: [TemplateType.User],
    keyMask: MASKS.phoneNumberAndMax(20),
  },
  activePeriodEnd: {
    label: 'MTR_COMMON_LICENSE_EXP_DATE',
    type: InputType.Calendar,
    validators: [VALIDATORS.required],
    templates: [TemplateType.User, TemplateType.UserBO],
    yearRange: CURRENT_YEAR - 2 + ':' + (CURRENT_YEAR + 6),
  },
  profileIds: {
    type: InputType.SwitchMultiple,
    validators: [VALIDATORS.atLeastOne],
    templates: [TemplateType.User],
  },
  roleIds: {
    type: InputType.SwitchMultiple,
    validators: [VALIDATORS.atLeastOne],
    templates: [TemplateType.UserBO],
  },
  categoryId: {
    label: 'MTR_COMMON_CATEGORY',
    type: InputType.Dropdown,
    placeholder: 'MTR_COMMON_CATEGORY_SELECT',
    isGroup: true,
    templates: [TemplateType.User, TemplateType.UserBO],
  },
  passwordExpiration: {
    label: 'MTR_COMMON_PASSWORD_EXP_DATE',
    type: InputType.Dropdown,
    placeholder: 'MTR_COMMON_CATEGORY_SELECT',
    templates: [TemplateType.User],
  },
  twoFactorEnabled: {
    label: 'MTR_COMMON_MFA',
    type: InputType.Switch,
    validators: [VALIDATORS.required],
    templates: [TemplateType.User],
  },
}
