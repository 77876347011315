import { createFeatureSelector, createSelector } from '@ngrx/store'
import { TenantState } from '@core/state/models/tenant.state'
import { StoreToken } from '@core/state/enums/store-token.enum'

export const selectTenants = createFeatureSelector<TenantState>(StoreToken.tenants)

export const getTenants = createSelector(selectTenants, (state) => state.list)
export const getSelectedTenant = createSelector(selectTenants, (state) => state.selected)

export const getTenantsAsDropdownItems = createSelector(getTenants, (tenants) =>
  (tenants || []).map((t) => ({ label: t.name, value: t.id })),
)
