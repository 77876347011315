<p-calendar
  [name]="name || inputId"
  [inputId]="inputId || name"
  [appendTo]="appendTo"
  [formControl]="formControl"
  [placeholder]="
    placeholder || 'MTR_COMMON_CALENDAR_SELECT.' + (selectionMode || defaultSelectionMode)
      | translate
  "
  [minDate]="minDate"
  [maxDate]="maxDate"
  [showIcon]="showIcon"
  [dateFormat]="dateFormat"
  [disabledDays]="disabledDays"
  [disabledDates]="disabledDates"
  [selectionMode]="selectionMode || defaultSelectionMode"
  [readonlyInput]="readonlyInput"
  (onBlur)="onBlur.emit($event)"
  (onSelect)="onSelect.emit($event)"
  #calendar
>
  <p-footer>
    <app-button [text]="'MTR_COMMON_CLOSE'" (click)="calendar.overlayVisible = false"></app-button>
    <app-button
      *ngIf="rangeEnabled"
      [text]="'MTR_COMMON_AROUND_2_DAYS'"
      (click)="selectRangeFromCurrentDate()"
    ></app-button>
  </p-footer>
</p-calendar>
<i
  *ngIf="formControl.value != null && showClear && !formControl.disabled"
  class="p-calendar-clear-icon pi pi-times"
  aria-hidden="true"
  (click)="clearCalendar($event)"
  #clearButton
></i>
