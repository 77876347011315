<mc-overlay-panel styleClass="app-menu__overlay-panel p-menu">
  <ul>
    <ng-template ngFor let-item [ngForOf]="items">
      <li
        *ngIf="!item.isHidden || !(item.isHiddenFn || item.isHiddenFn(data))"
        class="app-menu_item p-menuitem p-component p-corner-all ng-tns-c11-2 ng-star-inserted"
      >
        <a
          class="p-menuitem-link p-corner-all"
          [class.p-disabled]="item.isDisabled || (item.isDisabledFn && item.isDisabledFn(data))"
          (click)="onClick(item)"
        >
          <span class="p-menuitem-icon" *ngIf="item.icon" [ngClass]="item.icon"></span>
          <span class="p-menuitem-text">{{ item.label | translate }}</span>
        </a>
      </li>
    </ng-template>
  </ul>
</mc-overlay-panel>
