<div class="bo-filter__wrapper u-margin-bottom--lg">
  <div class="bo-filter p-col" *ngFor="let filter of filterSettings">
    <ng-container *ngIf="filter.id + uniqueIdPrefix as inputId">
      <label
        *ngIf="
          filter.type !== FilterType.Text &&
            (filter.type !== FilterType.Switch || !filter.isInline);
          else disabledLabel
        "
        class="bo-filter__label"
        [for]="inputId"
        >{{ filter.label | translate }}</label
      >
      <div
        class="bo-filter__item"
        [ngClass]="'bo-filter__item--' + filter.type"
        [ngSwitch]="filter.type"
      >
        <ng-template [ngSwitchCase]="FilterType.Text">
          <div class="p-inputgroup" (keyup.enter)="submitFilters()">
            <input
              pInputText
              [id]="inputId"
              [name]="filter.id"
              [(ngModel)]="filterData[filter.id]"
              [placeholder]="filter.placeholder | translate"
            />
          </div>
        </ng-template>
        <ng-template [ngSwitchCase]="FilterType.Dropdown">
          <ng-template
            [ngIf]="setAsyncOptions(filter.id, filter.options$ | async) || filter.options"
            [ngIfElse]="disabledInput"
            let-options
          >
            <mc-select
              class="bo-filter__select"
              *ngIf="(options || []).length > 0; else disabledInput"
              [options]="options"
              [ngModel]="filterData[filter.id]"
              [clearable]="!filter.defaultValue || filter.showClear"
              [placeholder]="filter.placeholder"
              [filter]="(options || []).length > 7"
              [panelStyleClass]="styleClass"
              filterPlaceholder="MTR_COMMON_SEARCH"
              (ngModelChange)="filterData[filter.id] = $event; onChange(filter.id)"
              (emitFirst)="onChange(filter.id)"
            ></mc-select>
          </ng-template>
        </ng-template>
        <ng-template [ngSwitchCase]="FilterType.MultiSelect">
          <ng-template
            [ngIf]="setAsyncOptions(filter.id, filter.options$ | async) || filter.options"
            [ngIfElse]="disabledInput"
            let-options
          >
            <p-multiSelect
              [inputId]="inputId"
              [name]="filter.id"
              *ngIf="(options || []).length > 0; else disabledInput"
              [options]="options"
              [maxSelectedLabels]="filter.maxLabelsAllowed || 2"
              [showToggleAll]="true"
              [(ngModel)]="filterData[filter.id]"
              (onChange)="onChange(filter.id)"
              [placeholder]="filter.placeholder"
              [filter]="shouldFilter(options)"
              [resetFilterOnHide]="shouldFilter(options)"
              [filterPlaceHolder]="'MTR_COMMON_SEARCH' | translate"
              [emptyFilterMessage]="'MTR_COMMON_NO_RESULTS' | translate"
              #multiselect
            >
              <p-header (click)="multiselect.onToggleAll($event)">
                <span [translate]="'MTR_COMMON_ALL'"></span>
              </p-header>
              <ng-template let-items pTemplate="selectedItems">
                <ng-template [ngIf]="(items?.length || 0) === 0">
                  {{ filter.placeholder | translate }}
                </ng-template>
                <ng-template
                  [ngIf]="
                    items?.length > 0 &&
                    items?.length <= multiselect.maxSelectedLabels &&
                    multiselect.options
                  "
                >
                  <span *ngFor="let item of items; let i = index">
                    {{ multiselect.getLabelByValue(item) | translate
                    }}{{ i < items?.length - 1 ? ',&nbsp;' : '' }}
                  </span>
                </ng-template>
                <ng-template [ngIf]="items?.length > multiselect.maxSelectedLabels">
                  {{ 'MTR_COMMON_N_SELECTED_ITEMS' | translate: { items: items?.length } }}
                </ng-template>
                <i
                  *ngIf="multiselect.value?.length > 0 && filter.showClear && !multiselect.disabled"
                  class="p-multiselect-clear-icon u-icon u-icon--times"
                  (click)="uncheckAll($event, multiselect)"
                  aria-hidden="true"
                ></i>
              </ng-template>
              <ng-template let-item pTemplate="item">
                <span>{{ item.label | translate }}</span>
              </ng-template>
            </p-multiSelect>
          </ng-template>
        </ng-template>
        <ng-template [ngSwitchCase]="FilterType.Date">
          <app-calendar
            [name]="filter.id"
            [inputId]="inputId"
            [placeholder]="filter.placeholder"
            [(ngModel)]="filterData[filter.id]"
            [showClear]="!filter.defaultValue || filter.showClear"
            [language]="language$ | async"
            [selectionMode]="filter.selectionMode"
            (onSelect)="onChange(filter.id)"
          ></app-calendar>
        </ng-template>
        <ng-template [ngSwitchCase]="FilterType.Switch">
          <mc-input-switch
            [name]="filter.id"
            [inputId]="inputId"
            [label]="filter.isInline ? filter.label : null"
            [(ngModel)]="filterData[filter.id]"
            (onChange)="onChange(filter.id)"
          ></mc-input-switch>
        </ng-template>
        <ng-template [ngSwitchCase]="FilterType.Autocomplete">
          <app-autocomplete
            [inputId]="inputId"
            [placeholder]="filter.placeholder | translate"
            [optionLabel]="filter.shownField"
            [group]="filter.isGroup"
            [lastQueryGroup]="filter.lastQueryGroup"
            [(ngModel)]="filterData[filter.id]"
            [suggestions]="setAsyncOptions(filter.id, filter.options$ | async) || filter.options"
            (completeMethod)="filter.getOptionsOnInput($event.query)"
            (onSelect)="onChange(filter.id)"
          >
          </app-autocomplete>
        </ng-template>
      </div>
    </ng-container>
  </div>

  <div class="bo-filter p-col-fixed" *ngIf="showClear || showSearch">
    <ng-container *ngTemplateOutlet="disabledLabel"></ng-container>
    <div class="bo-button-group bo-button-group--separated">
      <app-button
        *ngIf="showClear"
        icon="trash"
        (click)="resetFilters(true)"
        extraClasses="bo-button--secondary"
        tooltipText="MTR_COMMON_CLEAN_FILTERS"
        [qaId]="cleanButtonQaId"
      >
      </app-button>
      <app-button
        *ngIf="showSearch"
        text="MTR_COMMON_SEARCH"
        (click)="submitFilters()"
        [qaId]="searchButtonQaId"
      >
      </app-button>
    </div>
  </div>
  <div class="bo-filter bo-filter__submit"></div>
</div>
<ng-template #disabledInput>
  <mc-select [disable]="true"></mc-select>
</ng-template>

<ng-template #disabledLabel>
  <label class="bo-filter__label">&nbsp;</label>
</ng-template>
