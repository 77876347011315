import { SortingDirection } from '@core/services/sorting/sorting.models'
import { DEFAULT_QUERY_PAGING, getDateFromCurrent, getISODate } from '@core/utils/main'

import { SeasonGroup } from './marketable.enums'
import { FilterName } from './components/marketable-packages/marketable-packages.constants'
import { FilterName as MatchFilterName } from '../data-publishing/data-publishing.enums'

const SortItemsBySeasonShortName = (prev, current) =>
  prev.seasonShortName < current.seasonShortName ? 1 : -1

const SortGroupByLabel = (prev, current) => (prev.label > current.label ? 1 : -1)

export const ToArray = (obj) =>
  Object.values(obj)
    .map((mapObj: any) => ({ ...mapObj, items: mapObj.items.sort(SortItemsBySeasonShortName) }))
    .sort(SortGroupByLabel)

export const GetGroupName = (grouping) =>
  grouping === SeasonGroup.Current ? 'MTR_COMMON_CURRENT_SEASON' : 'MTR_COMMON_PREVIOUS_SEASONS'

export const GroupingOptions = (prev, current) => ({
  ...prev,
  [GetGroupName(current.grouping)]: {
    label: GetGroupName(current.grouping),
    items: [...((prev[GetGroupName(current.grouping)] || {}).items || []), current],
  },
})

export const MATCH_DEFAULT_AUTOCOMPLETE_FILTERS = {
  [MatchFilterName.MatchPeriod]: {
    start: getISODate(getDateFromCurrent(-1), false, true),
  },
}

export const MARKETABLE_DEFAULT_AUTOCOMPLETE_LAZY_PARAMS = {
  ...DEFAULT_QUERY_PAGING(),
  sorting: {
    column: FilterName.InsertDateTime,
    direction: SortingDirection.Desc,
  },
}

export const MATCH_DEFAULT_AUTOCOMPLETE_LAZY_PARAMS = {
  ...DEFAULT_QUERY_PAGING(20),
  sorting: {
    column: 'date',
    direction: SortingDirection.Asc,
  },
}
