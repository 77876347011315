<div class="bo-quick-view">
  <div class="bo-quick-view__wrapper" (click)="userMenu.toggle($event)">
    <div class="bo-quick-view__text">
      <div class="bo-quick-view__full-name">{{ userProfile?.fullName }}</div>
      <div class="bo-quick-view__role">{{ userProfile?.roles | roleTranslate | async }}</div>
    </div>
    <div class="bo-quick-view__avatar">
      <img
        [src]="userProfile?.avatarPath || defaultAvatar"
        alt="{{ userProfile?.name }}'s avatar"
      />
    </div>
  </div>
  <p-menu class="bo-quick-view__menu" [popup]="true" [model]="userMenuItems" #userMenu></p-menu>

  <app-overlay
    [type]="OverlayType.Modal"
    *ngIf="userProfile"
    [title]="userProfile?.fullName"
    [subtitle]="userProfile?.humanizedRoles"
    [confirmMessage]="formChangesObj ? 'MTR_COMMON_CONFIRM_MESSAGE' : ''"
    (onClose)="onOverlayClose()"
    (onConfirmClose)="onConfirmClose($event)"
    #overlay
  >
    <div class="bo-profile" bo-template="body">
      <app-general-form
        [userData]="userProfile"
        [isEdit]="true"
        (onChange)="onUserProfileChange($event)"
        #profile
      ></app-general-form>
      <div class="bo-quick-view__build-version">
        <p>
          Version {{ appData?.BUILD_VERSION }} | Build {{ appData?.BUILD_NUM }} | Release
          {{ appData?.BUILD_DATE }}
        </p>
      </div>
    </div>
    <div bo-template="footer">
      <div class="bo-button-group bo-button-group--separated">
        <app-button
          [text]="'MTR_COMMON_CLOSE' | translate"
          class="bo-button--tertiary"
          extraClasses="bo-button--tertiary"
          (onClick)="onCloseCancelClick()"
        ></app-button>
        <app-button
          [text]="'MTR_COMMON_SAVE' | translate"
          [disabled]="!formChangesObj"
          (onClick)="onSaveEditClick()"
        ></app-button>
      </div>
    </div>
  </app-overlay>
</div>
