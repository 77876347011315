import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { ProfileState } from '@core/state/models/profile.state'
import { signInRedirectCallback } from '@core/state/actions/oidc.actions'

@Component({
  selector: 'bo-callback',
  template: '<app-heading titleText="MTR_COMMON_LOADING"></app-heading>',
})
export class CallbackComponent implements OnInit {
  constructor(private readonly _store: Store<ProfileState>) {}

  ngOnInit() {
    this._store.dispatch(signInRedirectCallback())
  }
}
