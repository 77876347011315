import { createFeatureSelector, createSelector } from '@ngrx/store'
import { ProfileState } from '@core/state/models/profile.state'
import { MCUser, UserProfile } from '@core/authentication/auth/models/auth.models'
import { User } from 'oidc-client-ts'
import { StoreToken } from '@core/state/enums/store-token.enum'
import { AuthFeature, AuthPage, RoleType } from '@core/authentication/auth/enums/auth.enums'
import { mapRoleFeatures, mapRolePermissions } from '@core/authentication/auth/utils/auth.utils'
import { ApplicationMenuModels } from '@mediacoach-ui-library/global'
import { ApplicationUserProfileType } from '@mediacoach/ui'

export const selectUser = createFeatureSelector<ProfileState>(StoreToken.auth)

export const getOidcUser = createSelector(selectUser, (state: ProfileState): User => state.oidc)
export const isLoggedIn = createSelector(selectUser, (state: ProfileState): boolean => !!state.oidc)

export const getOidcFormattedToken = createSelector(
  getOidcUser,
  (user: User): string => user && `${user.token_type} ${user.access_token}`,
)
export const getOidcTokenId = createSelector(
  getOidcUser,
  (user: User): string => user && user?.id_token,
)

export const getProfile = createSelector(
  selectUser,
  (state: ProfileState): UserProfile => state?.profile,
)
export const getFormattedProfile = createSelector(
  getProfile,
  (profile: UserProfile): UserProfile => ({
    ...profile,
    fullName: `${profile?.name} ${profile?.surname}`,
  }),
)
export const getProfileLang = createSelector(
  getProfile,
  (user: UserProfile): string => user?.language,
)
export const getRoles = createSelector(
  getProfile,
  (profile: UserProfile): RoleType[] => profile?.roles,
)
export const getPermissions = createSelector(getRoles, (roles: RoleType[]): AuthPage[] =>
  mapRolePermissions(roles || []),
)
export const isAdmin = createSelector(getRoles, (roles) => (roles || []).includes(RoleType.Admin))

export const getUser = createSelector(selectUser, (state: ProfileState): MCUser => state?.user)
export const getUserId = createSelector(getUser, (user: MCUser): string => user?.id)
export const getUserProfiles = createSelector(
  getUser,
  (user: MCUser): ApplicationUserProfileType[] => user?.profiles,
)

export const isFeatureAllowed = (feature: AuthFeature) =>
  createSelector(getRoles, (roles) =>
    roles ? mapRoleFeatures(roles).includes(feature) : undefined,
  )
export const isPageAllowed = (page: string) =>
  createSelector(getRoles, (roles) =>
    roles ? mapRolePermissions(roles).includes(page.replace(/^\//, '') as AuthPage) : undefined,
  )
