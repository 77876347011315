<p-fileUpload
  class="bo-file-upload__wrapper bo-file-upload--{{ type }}"
  name="fileSelector[]"
  customUpload="true"
  auto="true"
  [disabled]="disabled"
  [class.bo-file-upload--dragging]="isDragging | async"
  [class.bo-file-upload--disabled]="disabled"
  [class.bo-file-upload--loading]="loading"
  [accept]="accept"
  [maxFileSize]="maxFileSize"
  [invalidFileSizeMessageDetail]="invalidFileSizeMessageDetail"
  [invalidFileTypeMessageDetail]="invalidFileTypeMessageDetail"
  (onSelect)="onPreselect()"
  (uploadHandler)="onSelectFn($event)"
  #fileUpload
>
  <ng-template pTemplate="content">
    <ng-container [ngSwitch]="type">
      <div
        *ngSwitchCase="fileUploadType.Avatar"
        class="bo-file-upload__image pi pi-camera"
        (click)="fileUpload.choose()"
        [style.background-image]="
          'url(' + (base64Value || '../../../../../assets/images/user-default-avatar.svg') + ')'
            | safe: 'style'
        "
      ></div>
      <div *ngSwitchCase="fileUploadType.Common">
        <app-button
          [loading]="loading"
          [text]="'MTR_COMMON_CHOOSE' | translate"
          (onClick)="fileUpload.choose()"
          extraClasses="bo-file-upload__cta bo-button--secondary"
        >
        </app-button>
      </div>
    </ng-container>
  </ng-template>
</p-fileUpload>
