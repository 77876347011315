<p-toast>
  <ng-template let-message pTemplate="message">
    <span class="p-toast-icon pi" [ngClass]="'pi-' + toastIconMapping[message.severity]"></span>
    <div class="p-toast-message-text-content">
      <div class="p-toast-summary">{{ message.summary | translate }}</div>
      <div class="p-toast-detail">
        {{ message.detail || 'MTR_COMMON_ERROR_UNKNOWN' | translate }}
      </div>
    </div>
  </ng-template>
</p-toast>
