import { OverlayType } from './overlay.enums'
import { OverlaySettings } from './overlay.models'

const OVERFLOW_CLASS = 'overflow-hidden'

export const OVERLAY_SETTINGS: OverlaySettings = {
  [OverlayType.Modal]: {
    backdrop: true,
    bodyClass: OVERFLOW_CLASS,
  },
  [OverlayType.SidePanel]: {
    backdrop: false,
    bodyClass: OVERFLOW_CLASS + '--xs',
  },
}
