import { fetchMCUSer, fetchUserProfile } from "../actions/profile.actions";
import { Injectable } from '@angular/core'
import { delay, switchMap, tap } from 'rxjs/operators'
import { setOidcUser } from '@core/state/actions/oidc.actions'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { startupApplication } from "../actions/startup.actions";
import { fetchTenants } from '@core/state/actions/tenant.actions'

@Injectable()
export class StartupEffects {

  startup$ = createEffect(
    () => this._actions$.pipe(
      ofType(startupApplication),
      tap(({oidc}) => this._store.dispatch(setOidcUser({oidc}))),
      delay(0),
      switchMap(() => [fetchMCUSer(),fetchUserProfile(), fetchTenants()]
      )
    ),
  )

  constructor(private readonly _actions$: Actions, private readonly _store: Store<unknown>) {
  }

}
