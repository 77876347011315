import { isNewFormMode, isNotNewFormMode } from '@core/utils/main'

import { ColumnSetting } from '../table/table.models'
import { VALIDATORS } from '../general-form/general-form.constants'

import { FieldName } from './sales-form.enums'
import { InputType } from '../general-form/general-form.enums'
import { FormInputSetting } from '../general-form/general-form.models'
import { Switch } from '@mediacoach-ui-library/global'

export const FORM_INPUTS: FormInputSetting = {
  [FieldName.Id]: {
    label: 'MTR_COMMON_SALE_ID',
    type: InputType.Text,
    hideFn: isNewFormMode,
    disabledFn: () => true,
  },
  [FieldName.SaleDate]: {
    label: 'MTR_COMMON_SALE_DATE',
    type: InputType.Calendar,
    hideFn: isNewFormMode,
    disabledFn: () => true,
  },
  [FieldName.CustomerId]: {
    label: 'MTR_COMMON_CUSTOMER',
    type: InputType.Dropdown,
    placeholder: 'MTR_COMMON_CUSTOMER_SELECT',
    validators: [VALIDATORS.required],
    disabledFn: isNotNewFormMode,
  },
  [FieldName.IsActive]: {
    label: 'MTR_COMMON_STATUS',
    type: InputType.Switch,
    switchLabels: [
      { id: Switch.Off, text: 'MTR_COMMON_DEACTIVATED' },
      { id: Switch.On, text: 'MTR_COMMON_ACTIVE' },
    ],
    validators: [VALIDATORS.required],
    value: true,
  },
  [FieldName.MarketablePackageId]: {
    label: 'MTR_COMMON_MARKETABLE_PACKAGE',
    type: InputType.Autocomplete,
    validators: [VALIDATORS.required],
    disabledFn: isNotNewFormMode,
    shownField: 'name',
    selectedField: 'id',
    valueId: 'package',
    placeholder: 'MTR_SEARCH_MARKETABLE_PACKAGE',
    isGroup: true,
  },
  [FieldName.ExpirationTime]: {
    label: 'MTR_COMMON_EXPIRATION_DATE',
    type: InputType.Calendar,
    disabledFn: isNotNewFormMode,
    validators: [VALIDATORS.required],
  },
  [FieldName.Comment]: {
    label: 'MTR_COMMON_COMMENT',
    type: InputType.Textarea,
    disabledFn: isNotNewFormMode,
  },
}

export const COLUMN_SETTINGS: ColumnSetting[] = [
  { field: 'id', translateHeader: 'MTR_COMMON_ID' },
  { field: 'name', translateHeader: 'MTR_COMMON_NAME' },
  { field: 'description', header: 'MTR_COMMON_DESCRIPTION' },
]
