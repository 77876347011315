import { Injectable } from '@angular/core'
import { MessageService } from 'primeng/api'

import { environment } from '@env'

import { ToastType } from './toast.models'

const IS_PROD = environment.production

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private messageService: MessageService) {}

  showToast(toast: { type: ToastType; message: string; devInfo?: string }) {
    const { type, message, devInfo } = toast
    this.messageService.add({
      severity: type,
      summary: IS_PROD ? '' : devInfo,
      detail: message,
    })
  }
}
