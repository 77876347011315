import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { fetchTranslations, setPrimengTranslations } from '@core/i18n/state/actions/i18n.actions'
import { switchMap, tap } from 'rxjs/operators'
import { I18nApi } from '@core/i18n/api/i18n.api'
import { TranslateService } from '@ngx-translate/core'
import { parseLangFromISO } from '@core/i18n/utils/i18n.utils'
import { TranslatorService } from '@mediacoach-ui-library/global'
import { PrimeNGConfig } from 'primeng/api'

@Injectable()
export class I18nEffects {
  fetchTranslations$ = createEffect(
    () => this._actions$.pipe(
      ofType(fetchTranslations),
      tap(({lang}) => this._translator.use(parseLangFromISO(lang), true)),
      switchMap(({lang}) => this._api.fetchTranslations(lang).pipe(
        tap(({translations}) => {
          this._translate.setTranslation(parseLangFromISO(lang), translations, true)
          this._store.dispatch(setPrimengTranslations())
        }),
      )),
    ), {dispatch: false}
  )

  setPrimengTranslations$ = createEffect(
    () => this._actions$.pipe(
      ofType(setPrimengTranslations),
      switchMap(() => this._translate.get('MTR_PRIMENG')),
      tap((translations) => this._config.setTranslation(translations))
    ), {dispatch: false}
  )

  constructor(
    private readonly _actions$: Actions,
    private readonly _api: I18nApi,
    private readonly _translate: TranslateService,
    private readonly _translator: TranslatorService,
    private readonly _config: PrimeNGConfig,
    private readonly _store: Store<unknown>) {
  }
}
