export enum SortingDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export interface Sorting {
  column: string
  direction: SortingDirection
  columns?: string[]
}
