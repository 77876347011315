<app-modal
  class="c-dialog-application"
  [ngClass]="'c-dialog-application--' + type"
  [backgroundImage]="backgroundImage"
  [modalHeader]="headerTemplate"
  [modalBody]="bodyTemplate"
  size="profile"
  #modal
>
  <ng-template #headerTemplate>
    <div class="c-dialog-application__header">
      <div *ngIf="type === dialogType.Contact" class="c-dialog-application__title">
        {{ 'DIALOG_APP_REQUEST_TITLE' | translate }}
      </div>
      <div
        class="c-dialog-application__image-wrapper"
        [class.contact]="type === dialogType.Contact"
      >
        <img
          *ngFor="let item of headerImages"
          appDefaultImage
          [alt]="application.name"
          [class]="item.class"
          [srcUrl]="item.image"
          [title]="application.name"
        />
      </div>
    </div>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="c-dialog-application__body">
      <div
        class="c-dialog-application__download"
        *ngIf="type === dialogType.Download; else contact"
      >
        <img
          *ngIf="SHOW_OS_ICON_APPLICATIONS.includes(application.name)"
          class="c-dialog-application__download-os-icon"
          appDefaultImage
          [alt]="deviceOs"
          [srcUrl]="IMAGE_BASE_PATH + 'logo--' + deviceOs + '.svg'"
          [title]="deviceOs"
        />
        <ng-container *ngIf="!notInstallationAllowed; else notInstallationText">
          <div class="c-dialog-application__actions">
            <div class="c-dialog-application__button">
              <div class="c-dialog-application__button-text">
                {{
                  'MODAL_MCPRODUCTS_LABEL_OPEN' | translate: { applicationName: application.name }
                }}
              </div>
              <div>
                <a
                  class="button-primary"
                  [href]="application.link | safe: 'resourceUrl'"
                  (click)="closeModal()"
                >
                  {{ 'MODAL_MCPRODUCTS_BTN_OPEN' | translate }}
                </a>
              </div>
            </div>
            <div class="c-dialog-application__button">
              <div class="c-dialog-application__button-text">
                {{
                  'MODAL_MCPRODUCTS_LABEL_DOWNLOAD'
                    | translate: { applicationName: application.name }
                }}
              </div>
              <ng-container *ngIf="downloadList">
                <div>
                  <ng-container *ngFor="let item of downloadList">
                    <a
                      *ngIf="item.os === deviceOs"
                      class="button-{{ item.type }}"
                      [href]="item.url | safe: 'url'"
                    >
                      {{ 'MODAL_MCPRODUCTS_BTN_DOWNLOAD' | translate }}
                    </a>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
</app-modal>

<ng-template #notInstallationText>
  <p class="c-dialog-application__not-installation-text">
    {{ 'MTR_NOT_INSTALLATION_MESSAGE' | translate }}
  </p>
</ng-template>

<ng-template #contact>
  <p class="c-dialog-application__message">{{ 'DIALOG_APP_REQUEST_MESSAGE' | translate }}</p>
</ng-template>
