import { Injectable } from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Observable } from 'rxjs'
import { filter, switchMap } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import { ProfileState } from '@core/state/models/profile.state'
import { getOidcFormattedToken } from '@core/state/selectors/profile.selectors'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private _token$ = this._store.select(getOidcFormattedToken)

  constructor(private readonly _store: Store<ProfileState>) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this._token$.pipe(
      filter((token) => !!token),
      switchMap((token) =>
        next.handle(
          request.clone({
            headers: request.headers
              .set('Content-Type', 'application/json-patch+json')
              .set('Authorization', token),
          }),
        ),
      ),
    )
  }
}
