import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  @Input() public placeholder = 'MTR_SEARCH_INSERT_TERM'
  @Input() public isSecondary = false

  constructor() {
    // This is intentional
  }
}
