import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'

import { Observable } from 'rxjs'
import { filter, map, take } from 'rxjs/operators'

import { environment } from '@env'
import { Store } from '@ngrx/store'
import { navigate } from '@core/ngrx-router/state/actions/ngrx-router.actions'
import { isPageAllowed } from '@core/state/selectors/profile.selectors'

@Injectable({
  providedIn: 'root',
})
export class RoleGuard {
  constructor(private readonly _store: Store<any>) {}

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._store.select(isPageAllowed(state.url)).pipe(
      filter((allowed) => typeof allowed === 'boolean'),
      take(1),
      map((allowed) => {
        if (state.url !== environment.NOT_PERMITTED_URL && !allowed) {
          this._store.dispatch(navigate({ path: environment.NOT_PERMITTED_URL }))
        }
        return true
      }),
    )
  }
}
