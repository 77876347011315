import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { ProfileState } from '@core/state/models/profile.state'
import { signInRedirect } from '@core/state/actions/oidc.actions'

@Component({
  selector: 'bo-login',
  template: '<app-heading titleText="MTR_TITLE_LOGIN"></app-heading>',
})
export class LoginComponent implements OnInit {
  constructor(private readonly _store: Store<ProfileState>) {}

  ngOnInit() {
    this._store.dispatch(signInRedirect())
  }
}
