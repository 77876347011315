export enum TemplateType {
  UserProfile = 'UserProfile',
  User = 'User',
  UserBO = 'UserBO',
}

export enum InputType {
  Text = 'Text',
  Textarea = 'Textarea',
  Switch = 'Switch',
  Dropdown = 'Dropdown',
  Calendar = 'Calendar',
  SwitchMultiple = 'SwitchMultiple',
  Autocomplete = 'Autocomplete',
  FileUpload = 'FileUpload',
  Number = 'Number',
}
