import { Pipe, PipeTransform } from '@angular/core'
import { TranslatorService } from '@mediacoach-ui-library/global'
import { RoleTypeKey } from '@core/authentication/auth/constants/auth.constants'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'

@Pipe({
  name: 'roleTranslate',
})
export class RoleTranslatePipe implements PipeTransform {
  constructor(private readonly _translator: TranslatorService) {}

  transform(value: any): Observable<string> {
    if (value) {
      return this._translator
        .stream(value.map((v) => RoleTypeKey[v]))
        .pipe(map((roles) => Object.values(roles).join(', ')))
    }

    return of('')
  }
}
