import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'

import { Checkbox } from 'primeng/checkbox'

@Component({
  selector: 'app-checkbox',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
  templateUrl: 'checkbox.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent extends Checkbox {
  indeterminateIcon = 'pi pi-minus'

  @Input() enableIndeterminate = false
  @Input() qaId: string
  @Input() override checkboxIcon = 'pi pi-check'
}
