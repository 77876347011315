import { Component, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms'

import { Subject } from 'rxjs'
import { debounceTime, tap } from 'rxjs/operators'
import { Dropdown } from 'primeng/dropdown'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

import { environment } from '@env'

export const FILTER_SEARCH_MINIMUM = 7

@UntilDestroy()
@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true,
    },
  ],
})
export class DropdownComponent extends Dropdown implements OnInit, OnDestroy, ControlValueAccessor {
  private _onChangeDebounce$ = new Subject()
  formControl = new UntypedFormControl()

  @Input() override group = false
  @Input() override showClear = true
  @Input() override resetFilterOnHide = true
  @Input() override filterPlaceholder = 'MTR_COMMON_SEARCH'
  @Input() override emptyFilterMessage = 'MTR_COMMON_NO_RESULTS'

  @Input() set isDisabled(_disabled: boolean) {
    this.setDisabledState(_disabled)
  }

  @Output() override onChange

  override ngOnInit() {
    this._onChangeDebounce$
      .pipe(
        debounceTime(environment.DEBOUNCE_TIME.FOR_CRASHES),
        tap((event) => this.onChange.emit(event)),
        untilDestroyed(this),
      )
      .subscribe()

    this.onBlur
      .pipe(
        tap(() => this.onModelTouched()),
        untilDestroyed(this),
      )
      .subscribe()

    this.formControl.valueChanges
      .pipe(
        tap((event) => this.onModelChange(event)),
        untilDestroyed(this),
      )
      .subscribe()
  }

  ngOnDestroy() {
    // This is intentional
  }

  override writeValue(value) {
    this.formControl.setValue(value, { emitEvent: false })
    this._onChangeDebounce$.next({ value })
  }

  override setDisabledState(val: boolean) {
    const emitEvent = false
    this.disabled = val
    if (val) {
      this.formControl.disable({ emitEvent })
    } else {
      this.formControl.enable({ emitEvent })
    }
  }

  shouldFilter(options) {
    return (options || []).length >= FILTER_SEARCH_MINIMUM
  }
}
