import { clearStorageByPrefix } from '@core/utils/main'
import {
  authFailed,
  generateNewIDSUserUrl,
  logOut,
  removeUser,
  signInRedirect,
  signInRedirectCallback,
  signInSilent,
  signInSilentCallback
} from '@core/state/actions/oidc.actions'
import { OidcService } from '@core/authentication/oidc/services/oidc.service'
import { Action, select, Store } from '@ngrx/store'
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators'
import { iif, Observable, of } from 'rxjs'
import { purgeUserProfile } from '@core/state/actions/profile.actions'
import { navigate, navigateExternal, navigateToNewTab } from '@core/ngrx-router/state/actions/ngrx-router.actions'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Injectable } from '@angular/core'
import { ProfileState } from '@core/state/models/profile.state'
import { environment } from '@env'
import { User } from 'oidc-client-ts'
import { OAuthErrorCode } from '@core/authentication/auth/enums/auth.enums'
import { getOidcTokenId } from '../selectors/profile.selectors'
import { getSelectedTenant } from '@core/state/selectors/tenant.selector'

@Injectable()
export class OidcEffects {
  signInRedirect$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(signInRedirect),
        switchMap(({state}) =>
          this._manager
            .signInRedirect({state})
            .pipe(catchError((e) => this._handleSignInError(e)))
        )
      ),
    {dispatch: false}
  )

  signInRedirectCallback$ = createEffect(() =>
    this._actions$.pipe(
      ofType(signInRedirectCallback),
      switchMap(() =>
        this._manager.signInRedirectCallback().pipe(
          catchError((err) =>
            iif(
              () => err === OAuthErrorCode.AccessDenied,
              of(
                navigateExternal({
                  path: environment.IDENTITY_SERVER.POST_LOGOUT_REDIRECT_URI,
                })
              ),
              this._handleSignInError(err)
            )
          )
        )
      ),
      map((oidc: User) =>
        navigate({path: oidc.state || environment.REDIRECT_LOGGED_URL})
      )
    )
  )

  singInSilent$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(signInSilent),
        switchMap(() => this._manager.signInSilent())
      ),
    {dispatch: false}
  )

  signInSilentCallback$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(signInSilentCallback),
        switchMap(({uri}) => this._manager.signInRedirectCallback(uri))
      ),
    {dispatch: false}
  )

  logout$ = createEffect(() =>
    this._actions$.pipe(
      ofType(logOut),
      withLatestFrom(this._store.pipe(select(getOidcTokenId))),
      switchMap(([{uri}, idToken]) => {
        clearStorageByPrefix(localStorage, 'oidc.')
        return this._manager.removeUser().pipe(map( () => ({uri, idToken})))
      }),
      map(({uri, idToken}) => {
        window.location.href =
          environment.IDENTITY_SERVER.API_LOGOUT +
          '?id_token_hint=' +
          idToken +
          '&' +
          environment.IDENTITY_SERVER.TAG_POST_LOGOUT_REDIRECT_URI +
          '=' +
          uri

        return purgeUserProfile()
      })
    )
  )

  removeUser$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(removeUser),
        switchMap(() => this._manager.removeUser())
      ),
    {dispatch: false}
  )

  generateNewIDSUserUrl$ = createEffect(() =>
    this._actions$.pipe(
      ofType(generateNewIDSUserUrl),
      withLatestFrom(this._store.pipe(select(getSelectedTenant))),
      map(([{ extraParams}, tenantId]) =>
        `${environment.IDENTITY_SERVER.REGISTER_ACCOUNT_URL}?tenantId=${tenantId}${(extraParams || []).length > 0 ? `&${extraParams.join('&')}` : ''}`
      ),
      map((url) => navigateToNewTab({ path: url }))
    )
  )

  constructor(
    private readonly _actions$: Actions,
    private readonly _manager: OidcService,
    private readonly _store: Store<ProfileState>
  ) {
  }

  private _handleSignInError(err: any): Observable<Action> {
    console.warn('[OIDC] Redirecting', err.message)
    clearStorageByPrefix(localStorage, 'oidc.')
    return this._manager?.removeUser().pipe(
      tap(() => window.location.href = window.location.origin),
      switchMap(() => of(authFailed()))
    )
  }
}
