import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { Subject } from 'rxjs'
import { map, share, switchMap } from 'rxjs/operators'

import { environment } from '@env'
import { FormMode } from '@core/enums/form-mode.enums'
import { LazyParams } from '@shared/components/table/table.models'
import { SortingService } from '@core/services/sorting/sorting.service'
import { MatchesService } from '@core/services/matches/matches.service'

import { GroupingOptions, ToArray } from './marketable.constants'
import { MarketablePackageSearchResult } from './marketable.models'
import { UserCustomerViewService } from '../user-customers/user-customer-view.service'
import { logError } from '@shared/opretators/log-error.operator'

@Injectable()
export class MarketableService {
  newSale$ = new Subject<void>()
  newMarketablePackage$ = new Subject<void>()

  constructor(
    private http: HttpClient,
    private sortingService: SortingService,
    private matchesService: MatchesService,
    private userCustomerViewService: UserCustomerViewService,
  ) {}

  getSales$(params = {}, lazyParams: LazyParams = <LazyParams>{}) {
    const paramsBackEnd = {
      filtering: {
        freeText: '',
        filterQuality: 'List',
        ...params,
      },
      ...lazyParams,
    }
    return this.http.post<any>(environment.API.SEARCH_SALES, paramsBackEnd).pipe(
      map((data) => ({
        ...data,
        paging: {
          totalRecords: data.sales.length,
          ...data.paging,
        },
        sorting: {
          ...data.sorting,
          ...lazyParams.sorting,
        },
        sales: this.sortingService.sort(
          data.sales.map((sale) => ({
            ...sale,
            customerName: (sale.customer || {}).name,
            packageDescription: (sale.package || {}).description,
            packageName: (sale.package || {}).name,
          })),
        ),
      })),
      share(),
      logError(),
    )
  }

  newUpdateSale$(sale, type = FormMode.Edit) {
    const isEdit = type === FormMode.Edit
    sale = isEdit ? [sale] : sale
    return this.http[isEdit ? 'put' : 'post'](environment.API.CRUD_SALE, sale)
  }

  newUpdateMarketablePackage(marketablePackage, type = FormMode.Edit) {
    const isEdit = type === FormMode.Edit
    marketablePackage = isEdit ? [marketablePackage] : marketablePackage
    return this.http[isEdit ? 'put' : 'post'](
      environment.API.CRUD_MARKETABLE_PACKAGE,
      marketablePackage,
    )
  }

  getMarketablePackages$(params = {}, lazyParams: LazyParams = <LazyParams>{}) {
    const paramsBackEnd = {
      filtering: {
        freeText: '',
        filterQuality: 'ListWithItems',
        ...params,
      },
      ...lazyParams,
    }
    return this.http
      .post<{ marketablePackages: MarketablePackageSearchResult[]; [key: string]: any }>(
        environment.API.SEARCH_MARKETABLE_PACKAGES,
        paramsBackEnd,
      )
      .pipe(
        map((data) => ({
          ...data,
          paging: {
            totalRecords: data.marketablePackages.length,
            ...data['paging'],
          },
          sorting: {
            ...data['sorting'],
            ...lazyParams.sorting,
          },
          marketablePackages: this.sortingService.sort(data.marketablePackages),
        })),
        share(),
        logError(),
      )
  }

  getMarketablePackagesGroupOptions$(params = {}, lazyParams: LazyParams = <LazyParams>{}) {
    return this.getMarketablePackages$(params, lazyParams).pipe(
      map(({ marketablePackages }) => {
        return marketablePackages.reduce(GroupingOptions, {})
      }),
      map(ToArray),
    )
  }

  getMatchGroupOptions$(params = {}, lazyParams: LazyParams = <LazyParams>{}) {
    return this.matchesService.getMatchesPublishing$(params, lazyParams).pipe(
      map(({ matches }) => {
        return matches.reduce(GroupingOptions, {})
      }),
      map(ToArray),
    )
  }

  getMarketablePackageById(id: string) {
    return this.http
      .get<any>(`${environment.API.CRUD_MARKETABLE_PACKAGE}s/${id}`)
      .pipe(share(), logError())
  }

  getMarketablePackageWithSalesById$(id: string) {
    return this.getMarketablePackageById(id).pipe(
      switchMap((marketablePackage) =>
        this.getSales$({ marketablePackageIds: [id] }).pipe(
          map(({ sales }) => ({ ...marketablePackage, associatedSales: sales })),
        ),
      ),
    )
  }

  getCustomerOptions$(forceFetch?: boolean) {
    return this.userCustomerViewService.getCustomerOptions$(forceFetch)
  }

  getSeasonOptions$(forceFetch?: boolean) {
    return this.matchesService.getSeasonOptions$(forceFetch)
  }

  getCompetitionsBySeasonOptions$(seasonId: string, forceFetch?: boolean) {
    return this.matchesService.getCompetitionsBySeasonOptions$(
      seasonId,
      false,
      undefined,
      forceFetch,
    )
  }
}
