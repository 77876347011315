<button
  *ngIf="loading; else noLoadingButton"
  pButton
  [disabled]="true"
  type="button"
  class="bo-button"
  [ngClass]="extraClasses"
  [attr.data-qa-id]="qaId"
  [icon]="'pi pi-spin pi-spinner'"
></button>

<ng-template #noLoadingButton>
  <button
    *ngIf="tooltipText; else noTooltipButton"
    pButton
    [disabled]="disabled"
    (click)="onHandleClick()"
    [pTooltip]="tooltipText | translate"
    [tooltipPosition]="tooltipPosition"
    [appendTo]="appendTo"
    [tooltipStyleClass]="tooltipStyleClass"
    type="button"
    class="bo-button"
    [escape]="false"
    [ngClass]="extraClasses"
    [icon]="icon ? 'pi pi-' + icon : null"
    [label]="text | translate"
    [attr.data-qa-id]="qaId"
  ></button>
</ng-template>

<ng-template #noTooltipButton>
  <button
    pButton
    [disabled]="disabled"
    (click)="onHandleClick()"
    type="button"
    class="bo-button"
    [ngClass]="extraClasses"
    [icon]="icon ? 'pi pi-' + icon : null"
    [label]="text | translate"
    [attr.data-qa-id]="qaId"
  ></button>
</ng-template>
