export enum FilterName {
  FreeText = 'freeText',
}

export enum TabType {
  Details = 'details',
  Users = 'users',
}

export enum ApplicationType {
  Portal,
  Desktop,
  Live,
  BackOffice,
  TV,
  Mobile,
  Vision,
}
