import { createAction, props } from '@ngrx/store'

export const fetchTranslations = createAction(
  '[i18n] fetch translations',
  props<{ lang: string }>(),
)
export const setTranslations = createAction(
  '[i18n] set translations',
  props<{ translations: any; lang: string }>(),
)
export const setPrimengTranslations = createAction('[i18n] set primeng translations')
