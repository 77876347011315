export enum IncidenceType {
  NotifyOk = 'NotifyOk',
  NotifyTrackingUnavailable = 'NotifyTrackingUnavailable',
  NotifyNoTrackingTech = 'NotifyNoTrackingTech',
  NotifyNoTrackingNoPanaTech = 'NotifyNoTrackingNoPanaTech',
  NotifyNoTrackingWeather = 'NotifyNoTrackingWeather',
  NotifyNoTrackingNoPanaWeather = 'NotifyNoTrackingNoPanaWeather',
  NotifyNoTv = 'NotifyNoTv',
  NotifyNoTac = 'NotifyNoTac',
  NotifyNoTacTracking = 'NotifyNoTacTracking',
  NotifyNoPan = 'NotifyNoPan',
  NotifyNoWarmup = 'NotifyNoWarmup',
  NotifyCustomMinutes = 'NotifyCustomMinutes',
  NotifyCustomText = 'NotifyCustomText',
  NotifyCalibration = 'NotifyCalibration',
}

export enum IncidenceResolutionType {
  NotifyPanaRecovery = 'NotifyPanaRecovery',
  NotifyTvRecovery = 'NotifyTvRecovery',
  NotifyTrackingRecovery = 'NotifyTrackingRecovery',
  NotifyAllRecovery = 'NotifyAllRecovery',
}

export enum IncidenceCameraTypology {
  Tracking = 'Tracking',
  TV = 'TV',
  All = 'All',
  Pana = 'Pana',
  Tac = 'TAC',
  WarmUp = 'WarmUp',
}
