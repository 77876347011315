<i
  *ngIf="icon && tooltipText; else noTooltipIcon"
  class="bo-icon {{ isNativeIcon ? 'pi pi-' : 'u-icon--' }}{{ icon }}"
  [class.is-disabled]="isDisabled"
  [ngClass]="extraClasses"
  [pTooltip]="tooltipText | translate"
  tooltipPosition="top"
  aria-hidden="true"
></i>

<ng-template #noTooltipIcon>
  <i
    *ngIf="icon"
    class="bo-icon {{ isNativeIcon ? 'pi pi-' : 'u-icon--' }}{{ icon }}"
    [ngClass]="extraClasses"
    aria-hidden="true"
  ></i>
</ng-template>
