import { Component, Input, ViewChild } from '@angular/core'

import { MenuItem } from './menu.models'
import { OverlayPanelComponent } from '@mediacoach-ui-library/global'

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  @Input() data
  @Input() items: MenuItem[] | HTMLOptionsCollection
  @Input() extraClasses: string | { [key: string]: boolean } | string[]

  @ViewChild(OverlayPanelComponent, { static: true }) overlay: OverlayPanelComponent

  constructor() {
    // This is intentional
  }

  get isVisible(): boolean {
    return this.overlay.overlayVisible
  }

  toggle(event) {
    this.overlay.toggle(event)
  }

  onClick(item) {
    if (!(item.isDisabled || (item.isDisabledFn && item.isDisabledFn(this.data)))) {
      item.onClick(this.data)
      this.overlay.hide()
    }
  }
}
