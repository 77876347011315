import { ColumnType } from '@shared/components/table/table.enums'
import { FilterType } from '@shared/components/filters/filters.enums'
import { ColumnSetting } from '@shared/components/table/table.models'
import { FilterSetting } from '@shared/components/filters/filters.models'

export const COLUMN_SETTINGS: ColumnSetting[] = [
  { field: 'id', translateHeader: 'MTR_COMMON_ID' },
  { field: 'insertDateTime', translateHeader: 'MTR_COMMON_CREATION_DATE', type: ColumnType.Date },
  { field: 'name', translateHeader: 'MTR_COMMON_NAME' },
  { field: 'description', translateHeader: 'MTR_COMMON_DESCRIPTION' },
]

export enum FilterName {
  MarketablePackageIds = 'marketablePackageIds',
  InsertDateTime = 'insertDateTime',
  Season = 'seasonId',
  Competition = 'competitionId',
}

export const FILTER_SETTINGS: FilterSetting[] = [
  {
    id: FilterName.MarketablePackageIds,
    type: FilterType.Autocomplete,
    label: 'MTR_COMMON_SEARCH',
    shownField: 'name',
    isArray: true,
    selectedField: 'id',
    placeholder: 'MTR_SEARCH_MARKETABLE_PACKAGE',
    isGroup: true,
  },
  {
    id: FilterName.InsertDateTime,
    type: FilterType.Date,
    label: 'MTR_COMMON_CREATION_DATE',
  },
  {
    id: FilterName.Season,
    type: FilterType.Dropdown,
    label: 'MTR_COMMON_SEASON',
    placeholder: 'MTR_COMMON_SEASON_SELECT',
    showClear: true,
  },
  {
    id: FilterName.Competition,
    type: FilterType.Dropdown,
    label: 'MTR_COMMON_COMPETITION',
    placeholder: 'MTR_COMMON_COMPETITION_SELECT',
  },
]
