export enum RoleType {
  Admin = 'Admin',
  Support = 'Support',
  Production = 'Production',
  Technician = 'Technician',
  Sales = 'Sales',
}

export enum AuthPage {
  Administration = 'administration',
  Alerts = 'alerts',
  UserCustomerView = 'users-customers',
  Marketable = 'marketable',
  Dashboard = 'dashboard',
  DataIncoming = 'data-incoming',
  DataPublishing = 'data-publishing',
  Monitoring = 'monitoring',
  Stadium = 'stadium',
  License = 'license',
  MediaReports = 'media-reports',
  GrafanaWidgets = 'grafana-widgets',
  PermissionManagement = 'permission-management',
  MetricsManager = 'metrics-manager',
  PlayersManager = 'players-manager',
  NotPermitted = 'not-permitted',
  Playground = 'playground',
}

export enum AuthFeature {
  ReportIssue = 'report-issue',
}

export enum OAuthErrorCode {
  AccessDenied = 'access_denied',
}
