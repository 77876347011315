<div class="bo-sidenav">
  <div
    *ngIf="!isProduction"
    class="bo-sidenav__item"
    routerLink="playground"
    routerLinkActive="active"
    [pTooltip]="'Playground'"
  >
    <i class="u-icon u-icon--star" aria-hidden="true"></i>
  </div>
  <ng-template ngFor let-navItem [ngForOf]="navItems">
    <div
      class="bo-sidenav__item"
      *ngIf="((permissions$ | async) || []).includes(navItem.url) && !navItem.deactivated"
      [pTooltip]="navItem.name | translate"
      [routerLink]="navItem.url"
      routerLinkActive="active"
    >
      <i class="u-icon u-icon--{{ navItem.icon }}" aria-hidden="true"></i>
    </div>
  </ng-template>
</div>
