import { Pipe, PipeTransform } from '@angular/core'

import { isObservable, Observable, of } from 'rxjs'
import { map, startWith, catchError } from 'rxjs/operators'

export interface Loadable<T = any> {
  value: T
  loading: boolean
}

@Pipe({
  name: 'loading',
})
export class LoadingPipe<T> implements PipeTransform {
  transform(val: any): Observable<Loadable<T>> {
    return !isObservable(val)
      ? val
      : val.pipe(
          map((value: any) => ({ loading: false, value })),
          startWith({ loading: true }),
          catchError((error) => of({ loading: false, error })),
        )
  }
}
