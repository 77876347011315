<p-tree
  [value]="value"
  [selectionMode]="selectionMode"
  [selection]="selection"
  [style]="style"
  [styleClass]="styleClass"
  [contextMenu]="contextMenu"
  [layout]="layout"
  [draggableScope]="draggableScope"
  [droppableScope]="droppableScope"
  [draggableNodes]="draggableNodes"
  [droppableNodes]="droppableNodes"
  [metaKeySelection]="metaKeySelection"
  [propagateSelectionUp]="propagateSelectionUp"
  [propagateSelectionDown]="propagateSelectionDown"
  [loading]="loading"
  [loadingIcon]="loadingIcon"
  [emptyMessage]="emptyMessage"
  [ariaLabel]="ariaLabel"
  [ariaLabelledBy]="ariaLabelledBy"
  [validateDrop]="validateDrop"
  [filter]="filter"
  [filterBy]="filterBy"
  [filterMode]="filterMode"
  [filterPlaceholder]="filterPlaceholder"
  (selectionChange)="onSelectionChange($event)"
  (onNodeSelect)="nodeSelect.emit($event.node)"
  (onNodeUnselect)="nodeUnselect.emit($event.node)"
  (onNodeExpand)="nodeExpand.emit($event.node)"
  (onNodeCollapse)="nodeCollapse.emit($event.node)"
  (onNodeContextMenuSelect)="nodeContextMenuSelect.emit($event.node)"
  (onNodeDrop)="nodeDrop.emit($event.node)"
></p-tree>
