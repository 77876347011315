import { Directive, Input, TemplateRef } from '@angular/core'

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[boTemplate]',
})
export class BoTemplateDirective {
  @Input('boTemplate') type!: string

  constructor(public template: TemplateRef<any>) {}

  getType(): string {
    return this.type
  }
}
