<header>
  <app-header></app-header>
</header>

<main>
  <app-toast></app-toast>
  <app-sidenav></app-sidenav>
  <div class="bo-main-container">
    <router-outlet></router-outlet>
  </div>
</main>
