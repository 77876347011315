import { createAction, props } from '@ngrx/store'
import { MCUser, UserProfile } from '@core/authentication/auth/models/auth.models'

export const fetchMCUSer = createAction('[Profile] Fetch MC user')
export const setMCUser = createAction('[Profile] Set MC user', props<{ user: MCUser }>())

export const fetchUserProfile = createAction('[Profile] Fetch user profile')
export const setUserProfile = createAction(
  '[Profile] Set user profile',
  props<{ profile: UserProfile }>(),
)
export const saveUserProfile = createAction(
  '[Profile] Save user profile',
  props<{ profile: UserProfile }>(),
)
export const saveUserProfileOK = createAction('[Profile] Save user profile OK')

export const purgeUserProfile = createAction('[Profile] Purge user profile')
