import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'

import { FormMode } from '@core/enums/form-mode.enums'
import { ColumnSetting } from '@shared/components/table/table.models'
import { TableComponent } from '@shared/components/table/table.component'
import { ColumnType, SelectionMode } from '@shared/components/table/table.enums'
import { PermissionManagementService } from '@features/permission-management/permission-management.service'

import { COLUMN_SETTINGS } from './vision-form.constants'

@Component({
  selector: 'app-vision-form',
  templateUrl: './vision-form.component.html',
  styleUrls: ['./vision-form.component.scss'],
})
export class VisionFormComponent implements OnInit {
  private _tableConfigData: any[] = []
  private _selectedData: any[] = []

  columnSettings: ColumnSetting[] = COLUMN_SETTINGS

  SelectionMode = SelectionMode

  get selectedData() {
    return this._selectedData
  }

  @Input() set selectedData(_selectedData) {
    this._selectedData = Object.keys(_selectedData || {}).map((id) => ({ id }))
  }

  @Input() disabled!: boolean
  @Input() formMode!: FormMode

  get tableConfigData() {
    return this._tableConfigData
  }

  @Input() set tableConfigData(_tableConfigData) {
    this._tableConfigData = _tableConfigData
  }

  @Output() onChange = new EventEmitter()

  @ViewChild(TableComponent) table!: TableComponent

  FormMode = FormMode

  constructor(private permissionManagementService: PermissionManagementService) {
    ;(
      this.columnSettings.find((columnSetting) => columnSetting.type === ColumnType.ContextMenu) ||
      {}
    ).options = [
      {
        label: 'MTR_COMMON_EDIT',
        icon: 'pi pi-pencil u-font-size--l',
        onClick: (item) => this.onEdit(item),
      },
    ]
  }

  private onEdit = (item: any) => {
    this.permissionManagementService.newOrEditVisionEntity$.next({
      mode: FormMode.Edit,
      data: item,
    })
  }

  ngOnInit() {
    // This is intentional
  }

  tableReload() {
    this.table.clearSelection()
    this.table.reload()
  }

  selectionChange(selection: any) {
    this.onChange.emit((selection || []).map(({ id }: { id: string }) => id))
  }

  newEntityVision() {
    this.permissionManagementService.newOrEditVisionEntity$.next({ mode: FormMode.New })
  }
}
