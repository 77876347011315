import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { Tooltip } from 'primeng/tooltip'

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() text: string
  @Input() extraClasses: string | { [key: string]: boolean } | string[]
  @Input() disabled = false
  @Input() loading = false
  @Input() action: Function
  @Input() icon: string
  @Input() tooltipText: string
  @Input() tooltipPosition: 'top' | 'left' | 'bottom' | 'right' = 'top'
  @Input() appendTo: any = 'body'
  @Input() qaId: string
  @Input() tooltipStyleClass: string
  @Output() onClick = new EventEmitter<boolean>()
  @ViewChild(Tooltip) tooltipRef: Tooltip

  constructor() {
    // This is intentional
  }

  onHandleClick() {
    this.onClick.emit(true)
  }

  showTooltip(life?) {
    if (this.tooltipRef) {
      this.tooltipRef.life = life
      this.tooltipRef.activate()
    }
  }

  hideTooltip() {
    this.tooltipRef.hide()
  }
}
