import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'

import {
  ApplicationMenuModels,
  ApplicationName,
  DeviceDetectorService,
  TranslatorService,
} from '@mediacoach-ui-library/global'

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

import { DialogType } from './dialog-application.enums'
import { DownloadItem } from './dialog-application.models'
import { ModalComponent } from '../modal/modal.component'
import { getBackgroundImage, getHeader } from './dialog-application.utils'
import {
  IMAGE_BASE_PATH,
  INSTALLER_CONFIG_BY_APPLICATION,
  SHOW_OS_ICON_APPLICATIONS,
} from './dialog-application.constants'

import { DownloadService } from '@shared/services/download/download.service'

@UntilDestroy()
@Component({
  selector: 'app-dialog-application',
  templateUrl: './dialog-application.component.html',
  styleUrls: ['./dialog-application.component.scss'],
})
export class DialogApplicationComponent implements OnInit, OnDestroy {
  @ViewChild('modal', { static: true }) public modal: ModalComponent
  type: DialogType
  application: ApplicationMenuModels
  userProfile = <any>{}
  downloadList: DownloadItem[]
  backgroundImage: string
  headerImages = []
  dialogType = DialogType
  deviceOs = ''
  currentLang: string
  notInstallationAllowed: boolean

  IMAGE_BASE_PATH = IMAGE_BASE_PATH
  SHOW_OS_ICON_APPLICATIONS = SHOW_OS_ICON_APPLICATIONS

  constructor(
    private downloadService: DownloadService,
    private translatorService: TranslatorService,
    private deviceDetector: DeviceDetectorService,
  ) {}

  private _getDownloadList(assetsDownload, applicationName: ApplicationName) {
    const installer = assetsDownload[`${applicationName}Installer`]
    this.currentLang = this.translatorService.getCurrentLang()
    return installer
      ? [
          ...INSTALLER_CONFIG_BY_APPLICATION[applicationName].map((item) => ({
            ...item,
            url: [
              ApplicationName.LivePro,
              ApplicationName.Mobile,
              ApplicationName.Desktop,
            ].includes(applicationName)
              ? (
                  (installer[this.currentLang] || []).find(
                    (appItem) => appItem.extension === item.extension,
                  ) || {}
                ).path
              : installer[this.currentLang][0].path,
          })),
        ]
      : []
  }

  ngOnInit() {
    this.deviceOs = this.deviceDetector.getDeviceInfo().os
  }

  ngOnDestroy() {
    // This is intentional
  }

  openModal(type: DialogType, application: ApplicationMenuModels, userProfile: any) {
    this.notInstallationAllowed = this._isInstallationForbidden(application.name)

    this.backgroundImage = getBackgroundImage(type, application.name)
    this.headerImages = getHeader(type, application.name)
    this.type = type
    this.application = application
    this.userProfile = userProfile
    if (type === DialogType.Download) {
      this.downloadService
        .getAssetsDownload$()
        .pipe(untilDestroyed(this))
        .subscribe((assetsDownload) => {
          this.downloadList = this._getDownloadList(
            assetsDownload,
            <ApplicationName>application.name,
          )
        })
    }
    this.modal.openModal()
  }

  closeModal() {
    this.modal.closeModal()
  }

  private _isInstallationForbidden(appName: string): boolean {
    switch (appName) {
      case ApplicationName.Mobile:
        return this.deviceDetector.isTablet() || this.deviceDetector.isDesktop()
      case ApplicationName.Desktop:
        return this.deviceDetector.isTablet() || this.deviceDetector.isMobile()
      case ApplicationName.LivePro:
        return this.deviceDetector.isMobile() || this.deviceDetector.isDesktop()
    }
    return false
  }
}
